import React, { useEffect, useState } from "react"
import { request } from "../../utils/http"
import { Typography, Grid, Button, Box } from "@mui/material";
import Pagination from "../../components/Pagination/Pagination"
import expand from "../../assets/expand.png"
import searchicon from "../../assets/search-icon.png"
import plus from "../../assets/plus-blue.png"
import box from "../../assets/box-blue.png"
import trash from "../../assets/trash-blue.png"
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles"
import { ReactComponent as AgendaIcon } from "../../assets/agenda.svg"
import { ReactComponent as ReportIcon } from "../../assets/Reports.svg"
import { ReactComponent as MediaIcon } from "../../assets/Media.svg"
import "./ClientStylesheet.css"

const StyledButton = styled(Button)({
  background: "#0F579626",
  color: "#0F5796",
  borderRadius: "14px",
  // fontWeight:"600",
  padding: "12px 26px"
})


const InternalDocuments = ({selectedClient}) => {

  const [agendas, setAgendas] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  const [clientsCount, setClientsCount] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)

  const navigate = useNavigate();

  useEffect(()=>{
    const fetchData = async () => {
      try {

        const data = await request.get(
          `https://ezpr-32569-staging.botics.co/agendas/api/v1/agenda/client/${selectedClient?.id}/`,
          {
            params: {
              page: currentPage,
              search: searchTerm,
              size: 4
            }
          }
        );
        
        setClientsCount(data.data.count)
        setAgendas(data.data.results);
     
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

  
    fetchData();
  },[currentPage, searchTerm, selectedClient])


  const handleClick = (item) => {
    navigate("/client/meeting-agenda", {
      state: {item, selectedClient}
    })
  }

  const handleOnClick = page => () => {
    navigate(page)
  }



  return (

    <div>
      <Box sx={{ overFlow: "auto", marginBottom:"4rem" }}>
      <Box
        sx={{
          display: "flex",
          gap: "1rem",
          alignItems: "center",
          justifyContent: "space-between"
        }}
      >
        <div>
          <Typography variant="h5" noWrap>
            Internal Documents
          </Typography>
          <div style={{marginTop:15,color:"#A3A3A3"}}>Submenu</div>
        </div>
        <div>
          <Box sx={{ display: "flex", gap: "1rem", alignItems: "center" }}>
            <StyledButton
              startIcon={<AgendaIcon />}
              sx={{ whiteSpace: "nowrap", textTransform: "none" }}
              onClick={handleOnClick("/client/agendas-meeting")}
            >
              Agendas For Meetings
            </StyledButton>
            <StyledButton
              startIcon={<ReportIcon />}
              sx={{ whiteSpace: "nowrap", textTransform: "none" }}
              onClick={handleOnClick("/client/reports/"+selectedClient?.id)}
            >
              Reports
            </StyledButton>
            <StyledButton
              sx={{ whiteSpace: "nowrap", textTransform: "none" }}
              startIcon={<MediaIcon />}
              onClick={handleOnClick("/client/faqs")}
            >
              Media List
            </StyledButton>
    
          </Box>
        </div>
      </Box>
    </Box>

    <div style={{ display: "flex", marginBottom: "3rem", width:"100%", justifyContent:"space-between" }}>
        <div className="internal-searchbox" style={{ position: "relative", width: "50%" }}>
              <input
                type="text"
                placeholder="Search here..."
                className="internal-searchbox-input"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                style={{
                  width: "100%",
                  height: 20,
                  padding: "1rem",
                  border: "none",
                  borderRadius: "10px",
                  backgroundColor: "#0F579626",
                  color: "#0F5796",
                }}

  
              />
              <img
                src={searchicon}
                alt="Search Icon"
                style={{
                  position: "absolute",
                  width: 20,
                  height: 20,
                  top: "50%",
                  right: "10px",
                  transform: "translateY(-50%)",
                  cursor: "pointer",
                }}
              />
            </div>
            <div
          style={{
            display: "flex",
            alignItems: "center",
            marginLeft: 20
          }}
        >
          <div
            style={{
              display:"flex",
              marginLeft: "20px",
              textAlign: "center",
              cursor: "pointer"
            }}
          >
            <img
              src={plus}
              alt="Notification Icon"
              style={{ width: 18, height: 18, marginRight: 10 }}
            />
             <img
              src={trash}
              alt="Notification Icon"
              style={{ height:20, marginRight: 10 }}
            />
             <img
              src={box}
              alt="Notification Icon"
              style={{ width: 18, height: 18, marginRight: 10 }}
            />
          </div>
        </div>
      </div>
  
    <Grid container spacing={2}>
      {agendas.map((item) => (
        <Grid key={item.id} item xs={12} sm={6} md={6} lg={6}>
          <div className="proposal-card" style={{marginRight:"1rem"}}>
            <div className="proposal-content">
              <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
                <Typography
                  sx={{
                    marginTop: "6px",
                    color: "#464255",
                    fontWeight: "700",
                    fontSize: "1.125rem",
                  }}
                >
                  {item.campaign_name}
                </Typography>
                <img
                  style={{
                    marginLeft: "auto",
                  }}
                  src={expand}
                  alt="expand"
                  className="card-image"
                  onClick={() => handleClick(item)}
                />
              </div>
            </div>
            <Typography
              dangerouslySetInnerHTML={{ __html: item.body }}
              sx={{
                height: "40px",
                maxHeight: "4rem",
                overflow: "hidden",
                lineHeight: "normal",
                "& p": {
                  margin: "8px 0",
                  fontSize: "14px",
                  color: "#A3A3A3",
                },
              }}
            />
            <div className="proposal-buttons" >
              {item.primary_notes}
              <button style={{marginLeft:"1rem", maxHeight:"2rem"}} className="template-button" onClick={() => handleClick(item)}>
                Open
              </button>
            </div>
          </div>
        </Grid>
      ))}
    </Grid>
    <Pagination
        currentPage={currentPage}
        totalCount={clientsCount}
        totalPages={Math.ceil(clientsCount / 4 || 0)}
        pageSize={4}
        appendText={"documents"}
        onPageChange={page => setCurrentPage(page)}
      />
  </div>

  )
}

export default InternalDocuments
