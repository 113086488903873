import React, { useState } from "react"
import "./Login.css"
import eye from "../../assets/eye.png"
import { Link, useNavigate, useSearchParams } from "react-router-dom"
import AppModal from "../../components/Modal"
import { useDispatch, useSelector } from 'react-redux';
import { hideVerificationPopup, login, signUp } from "../../redux/auth/actions"
import { checkEmailVerificationStatus } from "../../redux/app/sagas"

const SignUp = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const userType = searchParams.get('userType') || 'agency';
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [showPassword, setShowPassword] = useState(false)
  const [showConfrim, setShowConfirm] = useState(false)
  const [isChecked, setIsChecked] = useState(false)
  const showPopup = useSelector(state => state.Auth.showVerificationPopUp);
  const [passwordMatch, setPasswordMatch] = useState(true);
  const [userData, setUserData] = useState({
    name: '',
    location: '',
    industry: '',
    description: '',
    contact: '',
    confirm_password: "",
    password: ""
  });

  const [showTermsConditionModal,setShowTermsConditionModal] = useState(false);
  const [showPrivacyPolicyModal,setShowPrivacyPolicyModal] = useState(false);

  const [passwordBlur,setPasswordBlur] = useState({
    password:false,
    confirmPassword:false
  })

  const handleChange = (event) => {
    const { name, value } = event.target;
    setUserData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleLogin = e => {
    e.preventDefault()
    if(userData.password !== userData.confirm_password){
      return;
    }
    // Handle the login logic here, e.g., submit the login credentials to the server
    console.log('User data submitted:', userData);
    const signUpData = {
      ...userData,
        user_type : userType === 'agency' ? 1 : userType === 'employee' ? 3 : 2
    }
    dispatch(signUp(signUpData))
    // setShowPopup(true)
  }



  const handleCheckboxChange = e => {
    setIsChecked(e.target.checked)
  }

  const handleClosePopup = () => {
    const payload = {email : userData.email}
    checkEmailVerificationStatus(payload).then((data)=>{
      if(data?.data?.email_status){
        const values = {
          username: userData.email,
          password: userData.password
        }    
        dispatch(login(values,navigate))
        dispatch(hideVerificationPopup())
      }else{
        dispatch(hideVerificationPopup())
        navigate("/login")
      }
    }).catch(err=>{
      dispatch(hideVerificationPopup())
      navigate("/login")
    })
  }

  const handleOnPaswordBlur = ()=> {
    if(passwordBlur.confirmPassword){
      userData.password === userData.confirm_password ? 
      setPasswordMatch(true) : 
      setPasswordMatch(false)  
    }
    setPasswordBlur(p => ({...p,password:true}))
  }

  const handleOnConfirmPasswordBlur = ()=> {
    if(passwordBlur.password){
      userData.password === userData.confirm_password ? 
      setPasswordMatch(true) : 
      setPasswordMatch(false)  
    }
    setPasswordBlur(p => ({...p,confirmPassword:true}))
  }


  return (
    <div className="login-screen">
    
      <header className="header-login">
      <h1 style={{cursor:"pointer"}} onClick={()=> navigate("/")}>Logo</h1>
        <span className="create-account-text">
          Already have an account?{" "}
          <span className="create-text">
            <Link to="/Login">
              Log in
            </Link>
          </span>
        </span>
      </header>
  
      <div className="form-containers">
        <form onSubmit={handleLogin} className="login-form">
          <h2>Sign up</h2>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sagittis,
            ac ac turpis viverra
          </p>
          <div className="form-group">
            <input
              type="text"
              id="agency"
              value={userData.name}
              onChange={e =>  setUserData((prevData) => ({ ...prevData, ["name"]: e.target.value }))}
              placeholder={userType === 'agency'? "Agency / Company name" : 'Full name'}
              required
            />
          </div>
          <div className="form-group">
            <input
              type="email"
              id="email"
              value={userData.email}
              onChange={e => setUserData((prevData) => ({ ...prevData, ["email"]: e.target.value }))}
              placeholder="Enter your email"
              required
            />
          </div>
          <div className="form-group">
            <input
              type={showPassword ? "text" : "password"}
              id="password"
              minLength={8}
              value={userData.password}
              onBlur={handleOnPaswordBlur}
              onChange={e => setUserData((prevData) => ({ ...prevData, ["password"]: e.target.value }))}
              placeholder="Enter your password"
              required
            />
            <img
              src={eye}
              className={showPassword ? 'eye-icon eye-icon-grey' : "eye-icon" }
              alt="eye"
              onClick={() => setShowPassword(!showPassword)}
            />
          </div>
          <div className="form-group">
            <input
              type={showConfrim ? "text" : "password"}
              id="confirm-password"
              minLength={8}
              onBlur={handleOnConfirmPasswordBlur}
              value={userData.confirm_password}
              onChange={e => setUserData((prevData) => ({ ...prevData, ["confirm_password"]: e.target.value }))}
              placeholder="Confirm password"
              required
            />
            <img
              src={eye}
              className={showConfrim ? 'eye-icon eye-icon-grey' : "eye-icon" }
              alt="eye"
              onClick={() => setShowConfirm(!showConfrim)}
            />
            {!passwordMatch && <div style={{color:"red",fontSize:"12px",marginTop:"1px"}}>Password does not match</div>}
          </div>          
          <div className="form-duo">
            <input
              type="number"
              id="contact"
              class="hide-input-number-scroll"
              min={0}
              value={userData.contact}
              onChange={e => setUserData((prevData) => ({ ...prevData, ["contact"]: e.target.value }))}
              placeholder="Contact"
              required
            />
            <input
              type="text"
              id="location"
              value={userData.location}
              onChange={e => setUserData((prevData) => ({ ...prevData, ["location"]: e.target.value }))}
              placeholder="Location"
              required
            />
          </div>
       
          
          <div className="sign-checkbox-container">
          
          <label style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'start', marginRight: 150,  }}>
            <input
                type="checkbox"
                checked={isChecked}
                required
                onChange={handleCheckboxChange}
                style={{ marginRight: '5px' }}
              />
              <span style={{ flex: 'none' }}>
              Agree to <a href="#"  onClick={()=> setShowTermsConditionModal(true)}>Terms & Conditions</a> and{" "}
              <a href="#"  onClick={()=> setShowPrivacyPolicyModal(true)}>Privacy Policy</a>
              </span>
            </label>
          </div>
          <button className="login-button" type="submit">
            Sign Up
          </button>
          
        </form>
       
        
      </div>
      
      

      {showPopup && (
        <AppModal
          modalIsOpen={showPopup}
          signup={true}
          values={userData}
          closeModal={handleClosePopup}
          onSubmit={handleClosePopup}
        />
      )}

      {(showTermsConditionModal || showPrivacyPolicyModal) && (
        <AppModal
          modalIsOpen={showTermsConditionModal || showPrivacyPolicyModal}
          termsAndCondition={showTermsConditionModal}
          privacyPolicy={showPrivacyPolicyModal}
          closeModal={() => {
            setShowTermsConditionModal(false);
            setShowPrivacyPolicyModal(false)
          }}
          onSubmit={() => {
            setShowTermsConditionModal(false);
            setShowPrivacyPolicyModal(false)
          }}
        />
      )}
    </div>
  )
}

export default SignUp
