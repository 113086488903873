import React, { useEffect, useState } from "react"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import Paper from "@mui/material/Paper"
import CheckBox from "@mui/material/Checkbox"
import { request } from "../../utils/http"
import Pagination from "../../components/Pagination/Pagination"
import styled from "@emotion/styled"
import searchicon from "../../assets/search-icon-black.png"
import { ReactComponent as DownloadIcon } from "../../assets/icon-download.svg"
import { Button } from "@mui/material"
import AppModal from "../../components/Modal"

const StyledButton = styled(Button)({
  background: "#0F579626",
  color: "#0F5796",
  borderRadius: "14px",
  textTransform: "none",
  padding: "12px 40px"
})

const ClientDetails = ({ selectedClient, handleRowClick, clientPageSize }) => {
  const [clientsList, setClientsList] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [pageSize, setPageSize] = useState(clientPageSize || 5)
  const [open, setOpen] = useState(false)
  const [search, setSearch] = useState("")
  const [clientsCount, setClientsCount] = useState(0)

  useEffect(() => {
    request
      .get("/clients/api/v1/clients/", {
        params: {
          page: currentPage,
          search: search,
          size: pageSize
        }
      })
      .then(({ data }) => {
        setClientsList(data.results)
        setClientsCount(data.count)
      })
      .catch(() => {
        setCurrentPage(1)
      })
  }, [currentPage, pageSize, search])

  useEffect(() => {
    setPageSize(clientPageSize || 5)
  }, [clientPageSize])

  return (
    <>
      <div className="business-management-header">
        <div className="header-content" style={{ marginBottom: 16 }}>
          <div style={{ fontSize: "20px" }}>
            {selectedClient?.name || "Clients"}
          </div>
          <div
            style={{
              color: "#A3A3A3",
              fontSize: "14px",
              marginTop: 16
            }}
          >
            See details
          </div>
        </div>
        <div style={{ display: "flex", width: "70%", justifyContent: "end" }}>
          <input
            type="text"
            placeholder="Search here..."
            value={search}
            onChange={e => setSearch(e.target.value)}
            style={{
              width: "30%",
              height: 32,
              padding: "8px 30px 8px 12px",
              border: "none",
              borderRadius: "10px",
              backgroundColor: "#F0F0F0"
            }}
          />
          <img
            src={searchicon}
            alt="Search Icon"
            style={{
              width: 20,
              height: 20,
              cursor: "pointer",
              marginTop: "12px",
              marginLeft: "-30px"
            }}
          />
          <StyledButton
            startIcon={<DownloadIcon />}
            sx={{ whiteSpace: "nowrap", marginLeft: "3%" }}
            onClick={() => setOpen(true)}
          >
            Download Media Report
          </StyledButton>
        </div>
      </div>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="center" sx={{ fontWeight: "600" }}></TableCell>
              <TableCell align="center" sx={{ fontWeight: "600" }}>
                Client Name
              </TableCell>
              <TableCell align="center" sx={{ fontWeight: "600" }}>
                Number of Campaigns
              </TableCell>
              <TableCell align="center" sx={{ fontWeight: "600" }}>
                Email
              </TableCell>
              <TableCell align="center" sx={{ fontWeight: "600" }}>
                See details
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {clientsList.map(row => (
              <TableRow
                key={row.id}
                sx={{
                  cursor: "pointer",
                  td: { cursor: "pointer" },
                  "&:last-child td, &:last-child th": { border: 0 }
                }}
                hover
              >
                <TableCell align="center">
                  <CheckBox
                    checked={row.id === selectedClient?.id}
                    onClick={handleRowClick(row)}
                  />
                </TableCell>
                <TableCell align="center" sx={{ width: "25%" }}>
                  {row.name}
                </TableCell>
                <TableCell align="center" sx={{ width: "25%" }}>
                  {row.campaign_count}
                </TableCell>
                <TableCell align="center" sx={{ width: "25%" }}>
                  {row.email}
                </TableCell>
                <TableCell align="center" sx={{ width: "15%" }}>
                  ...
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination
        currentPage={currentPage}
        totalCount={clientsCount || 0}
        totalPages={Math.ceil(clientsCount / pageSize || 0)}
        pageSize={pageSize}
        appendText={"data"}
        onPageChange={page => setCurrentPage(page)}
        onSizeChange={size => setPageSize(size)}
      />
      <AppModal
        modalIsOpen={open}
        downoadMediaReport={open}
        closeModal={() => {
          setOpen(false)
        }}
      />
    </>
  )
}

export default ClientDetails
