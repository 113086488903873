import { Typography, Box, Grid } from "@mui/material"
import Pagination from "../../../components/Pagination/Pagination"
import DropDownMenu from "../../../components/DropDownMenu"
import FormInput from "../../../components/FormInput"
import edit from "../../../assets/edit2.png"
import deleteImg from "../../../assets/delete.png"
import { ReactComponent as QNAIcon } from "../../../assets/icon-qna.svg"
import LabelButton from "../../../components/LabelButton"
import { useEffect, useMemo, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getClients } from "../../../redux/clients/actions"
import { request } from "../../../utils/http"
import { useNavigate } from "react-router-dom"
import AppModal from "../../../components/Modal"
import { toast } from "react-toastify"

const FAQ = ({ selectedClient }) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const clientsData = useSelector(state => state.client.clientsData)
  const [openModal, setOpenModal] = useState(false)
  const [faqs, setFaqs] = useState([])
  const [faqsCount, setFaqsCount] = useState(0)
  const [faqId, setFaqId] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [pageSize, setPageSize] = useState(3)
  const [search, setSearch] = useState("")

  const [client, setClient] = useState({
    value: selectedClient?.id,
    label: selectedClient?.name
  })

  useEffect(() => {
    if (client?.value) {
      request
        .get("/clients/api/v1/faq/", {
          params: {
            client: client?.value,
            page: currentPage,
            search: search,
            size: pageSize
          }
        })
        .then(({ data }) => {
          setFaqs(data.results)
          setFaqsCount(0)
        })
    }
  }, [dispatch, client, currentPage, pageSize, search])

  useEffect(() => {
    setClient({
      value: selectedClient?.id,
      label: selectedClient?.name
    })
  }, [selectedClient])

  useEffect(() => {
    if (!clientsData?.length) {
      dispatch(getClients())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientsData?.length])

  const clientsList = useMemo(() => {
    if (!client?.value) {
      setClient({
        value: clientsData?.[0]?.id,
        label: clientsData?.[0]?.name
      })
    }
    return clientsData?.map(clientData => ({
      label: clientData?.name,
      value: clientData?.id,
      key: `client-${clientData?.id}`
    }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientsData])

  const handleClientChange = (_, item) => {
    setClient(item)
  }

  const handleSearchChange = e => {
    setSearch(e.target.value)
  }

  const handleRemove = id => () => {
    setFaqId(id)
    setOpenModal(true)
  }

  const handleClick = () => {
    navigate(`/client/create-faq/${client?.value}`)
  }

  const handleDelete = () => {
    request.delete(`/clients/api/v1/faq/${faqId}/`).then(() => {
      toast.success("FAQ deleted successfully.")
      setOpenModal(false)
    })
  }

  return (
    <Box>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            width: "60%"
          }}
        >
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography
                variant="h5"
                sx={{ fontWeight: "500", color: "#464255", fontSize: "1.5rem" }}
                paragraph
              >
                FAQs
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  fontWeight: "400",
                  fontSize: "0.875rem",
                  color: "#A3A3A3"
                }}
              >
                See details
              </Typography>
            </Box>
            <Box sx={{ width: "60%", minWidth: "max-content" }}>
              <DropDownMenu
                label="Client Name"
                itemList={clientsList}
                onClick={handleClientChange}
                activeItem={client}
              />
            </Box>
          </Box>
          <FormInput
            placeholder="Search here..."
            sx={{
              width: "70%",
              marginTop: "2rem",
              ".MuiInputBase-input": { backgroundColor: "#ECEAF3" }
            }}
            onChange={handleSearchChange}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            width: "20%",
            flexDirection: "column",
            gap: "0.5rem"
          }}
        >
          <LabelButton
            label="New FAQ Document"
            icon={<QNAIcon />}
            onClick={handleClick}
          />
        </Box>
      </Box>
      <Grid container spacing={4} mt={2}>
        {faqs.map(faq => (
          <Grid item xs={12} md={6} key={faq?.id}>
            <Grid
              container
              style={{
                background: "#fff",
                borderRadius: "16px",
                padding: 20
              }}
            >
              <Grid container justifyContent="space-between">
                <div
                  style={{
                    color: "#464255",
                    fontSize: "22px",
                    fontWeight: "bold"
                  }}
                >
                  {faq?.faq_title}
                </div>
                <div style={{ marginTop: "-20px", marginRight: "-20px" }}>
                  <img
                    src={edit}
                    alt="item"
                    className="card-image"
                    style={{ height: 20, width: 20 }}
                  />
                  <img
                    src={deleteImg}
                    alt="item"
                    className="card-image"
                    style={{ height: 20, width: 20 }}
                    onClick={handleRemove(faq?.id)}
                  />
                </div>
              </Grid>
              <Typography
                dangerouslySetInnerHTML={{
                  __html: `<p>${faq?.faq_document}.replace(/<[^>]*>/g, "")}</p>`
                }}
                sx={{
                  maxHeight: "4rem",
                  width: "100%",
                  margin: "8px 0px",
                  overflow: "hidden",
                  lineHeight: "normal",
                  fontSize: "14px",
                  "& p": {
                    margin: "20px 0",
                    fontSize: "14px",
                    color: "#A3A3A3"
                  }
                }}
              />
              <div
                className="proposal-buttons"
                style={{ justifyContent: "end", width: "100%" }}
              >
                <button className="template-button" style={{ marginRight: 0 }}>
                  See FAQ Details
                </button>
              </div>
            </Grid>
          </Grid>
        ))}
      </Grid>
      <Box>
        <Pagination
          currentPage={currentPage}
          totalCount={faqsCount || 0}
          totalPages={Math.ceil(faqsCount / pageSize || 0)}
          pageSize={pageSize}
          onPageChange={page => setCurrentPage(page)}
          onSizeChange={size => setPageSize(size)}
        />
      </Box>
      <AppModal
        modalIsOpen={openModal}
        deleteRecord
        onClick={handleDelete}
        closeModal={() => {
          setOpenModal(false)
        }}
      />
    </Box>
  )
}

export default FAQ
