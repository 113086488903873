import React, { useState } from 'react';
import Template1 from '../../../components/Template/Template1';
import YearlyCalendar from '../CalendarType/YearlyCalendar';
import { ReactComponent as Calendar } from '../../../assets/icon-calendar.svg';
import { ReactComponent as DownloadIcon } from "../../../assets/icon-download.svg"
import AppModal from '../../../components/Modal';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import { request } from '../../../utils/http';
import { toast } from 'react-toastify';

const YearlyReport = () => {
    const [open, setOpen] = useState(false);
    const [success, setSuccess] = useState(false);

    const [events, setEvents] = useState([]);
    const [formattedData, setFormattedData] = useState("");
    const [selectedDate,setSelectedDate] = useState(null);

    const calendarId = "1";

    const params = useParams();

    const id = params.id;

    const onGetEvents = (startdate, enddate) => {
        setSelectedDate(startdate);
        const date = moment(startdate.toDate()).format("YYYY-MM-DD");
        request
            .get(`/task/api/v1/`, {
                params: {
                    client: id,
                    date: date,
                    filter: "MONTH"
                }
            })
            .then(({ data }) => {
                const calendarData = data.map(task => {
                    return {
                        id: task.id,
                        calendarId: calendarId,
                        title: task.task_title,
                        start: task.start_date,
                        end: task.end_date,
                        body: {
                            description: task.task_description,
                            status: task.status
                        },
                    }
                })
                setEvents(calendarData)
            })
            .catch(() => {
                setEvents([]);
            })
    }

    const onExportToGmailSingleRecord = (taskId) => {
        const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        request
        .get(`/task/api/v1/${taskId}/export-gmail/`,{
            params: {
                client: id,
                timezone
            }
        })
        .then(({ data }) => {
            setOpen({
                show:true,
                data: data?.result || ''
            })
        }).catch(err=>{
            toast.error("Failed to get data for export.")
        });
    }

    const onExportClick = () =>{
        const date = moment(selectedDate.toDate()).format("YYYY-MM-DD");
        const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        request
        .get(`/task/api/v1/export-gmail/`, {
            params: {
                client: id,
                date: date,
                timezone,
                filter: "YEAR"
            }
        })
        .then(({ data }) => {
            setOpen({
                show:true,
                data: data?.result || ''
            })
        }).catch(err=>{
            toast.error("Failed to get data for export.")
        });
    }


    const onExport = () => {
        setOpen({
            show: false,
            data: ''
        });
        setSuccess(true);
    }
    return (
        <Template1 heading='Reports' title='Yearly Reports'>
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <button className="template-button" style={{ padding: '10px 20px', borderRadius: '14px', marginRight:'10px' }}>
                    <Calendar width={14} height={14} style={{ marginRight: '5px' }} />
                    Report for the year
                </button>
                <button className="template-button" style={{ padding: '10px 20px', borderRadius: '14px' }}
                     onClick={onExportClick}>
                    <DownloadIcon width={14} height={14} style={{ marginRight: '5px' }} />
                    Export to Gmail
                </button>
            </div>
            <YearlyCalendar events={events} onGetEvents={onGetEvents} clientId={id}
            onExportToGmailSingleRecord={onExportToGmailSingleRecord} />
            <AppModal
                modalIsOpen={open.show || success}
                exportReport={open.show}
                reportExportedSuccess={success}
                data={{
                    reportType: 'Year',
                    value: open.data,
                    onExport: onExport
                }}
                closeModal={() => {
                    setOpen({
                        show: false,
                        data: ''
                    });
                    setSuccess(false);
                }}
            />
        </Template1>
    )
}

export default YearlyReport;