import { Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { request } from '../../utils/http';
import Paper from "@mui/material/Paper"
import searchicon from "../../assets/search-icon.png"
import { ReactComponent as GreenCheck } from "../../assets/green-check.svg"
import "./ClientStylesheet.css"
import dot from "../../assets/side-dot.png"

import { styled } from "@mui/material/styles"
import { Link, useNavigate } from 'react-router-dom';
import Pagination from '../../components/Pagination/Pagination';

const StyledButton = styled(Button)({
  background: "#D7F5F1",
  color: "#28C66F",
  borderRadius: "10px",
  // fontWeight:"600",
  padding: "12px 35px"
})


const AgendasForMeetings = ({selectedClient}) => {

  const [agendas, setAgendas] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1)
  const [clientsCount, setClientsCount] = useState(0)
  const [pageSize, setPageSize] = useState(4)

  const navigate = useNavigate();

  useEffect(()=>{
    const fetchData = async () => {
      try {

        const data = await request.get(
          `https://ezpr-32569-staging.botics.co/agendas/api/v1/agenda/client/${selectedClient.id}/`,
          {
            params: {
              page: currentPage,
              search: searchTerm,
              size: pageSize
            }
          }
        );
        
        setClientsCount(data.data.count)
        setAgendas(data.data.results);
     
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

  
    fetchData();
  },[currentPage, pageSize, searchTerm, selectedClient])


  const handleRowClick = (item) =>{
    navigate("/client/meeting-agenda", {state: {item: item, selectedClient}})
  }


  return (

    <div >
      {/* row 1 */}
      <div className='cover-div'>

        <div className='agenda-section-one' style={{width:"19vw"}}>Agendas for Meetings</div>

        <div className='agenda-section-two'>

          <div style={{width:"100%"}}>
            <div style={{marginBottom:"1rem"}}>Client Name</div>
            <input placeholder='Client name' value={selectedClient?.name} disabled style={{width:"35vw", padding:"15px 20px"}}/>
          </div>
          <StyledButton
            startIcon={<GreenCheck />}
            sx={{ whiteSpace: "nowrap", textTransform: "none", marginLeft:"2vw", marginTop:"2.1rem", height:"50px" }}
            onClick={()=> navigate("/client/create-new-agenda", {state:{selectedClient}})}
          >
            Create New Agendas
          </StyledButton>

        </div>

        <div style={{width:"5vw"}}></div>

      </div>

      {/* row 2 */}
      <div className='cover-div'>
        <div className='agenda-section-one' style={{width:"19vw"}}
        //  style={{width:"19vw", fontSize:"20px", minWidth:"15rem"}}
         >

          <div >Agendas history</div>
          <div style={{fontSize:"15px", marginTop:15,color:"#A3A3A3"}} >See Details</div>

        </div>

        <div style={{width:"100%"}}>
          
          <div style={{marginBottom:"2rem", display:"flex", justifyContent:"space-between"}}>

            <div className="internal-searchbox" style={{ position: "relative", width:"25vw" }}>
                <input
                  type="text"
                  placeholder="Search here..."
                  className="internal-searchbox-input"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  style={{
                    width: "35vw",
                    height: 26,
                    padding: "10px 20px 10px 20px",
                    border: "none",
                    borderRadius: "10px",
                    backgroundColor: "#0F579626",
                    color: "#0F5796",
                  }}
                />
                <img
                  src={searchicon}
                  alt="Search Icon"
                  style={{
                    position: "absolute",
                    width: 20,
                    height: 20,
                    top: "50%",
                    right: "-10rem",
                    transform: "translateY(-50%)",
                    cursor: "pointer",
                  }}
                />
              </div>


    
          </div>

          <TableContainer component={Paper} style={{width:"100%"}}>
            <Table sx={{ minWidth: 600 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell
                    align="left"
                    sx={{ paddingLeft: "4rem", fontWeight: "600" }}
                  >
                    Campaign Name
                  </TableCell>
                  <TableCell align="center" sx={{ fontWeight: "600" }}>
                    Meeting Title
                  </TableCell>
                  <TableCell align="center" sx={{ fontWeight: "600" }}>
                    Date & Time
                  </TableCell>
                  <TableCell align="center" sx={{ fontWeight: "600" }}>
                    See details
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {agendas?.map(row => (
                  <TableRow
                    key={row.id}
                  >
                    <TableCell
                      align="left"
                      sx={{ width: "40%", paddingLeft: "4rem" }}
                    >
                      {row.campaign_name}
                    </TableCell>
                    <TableCell align="center" sx={{ width: "20%" }}>
                      {row.meeting_title}
                    </TableCell>
                    <TableCell align="center" sx={{ width: "20%" }}>
                      {row.meeting_date_and_time}
                    </TableCell>
                    <TableCell align="center" sx={{ width: "40%" }}  style={{
                      cursor: "pointer",
                      td: { cursor: "pointer" },
                      "&:last-child td, &:last-child th": { border: 0 }
                      
                    }}

                    onClick={() => handleRowClick(row)}
                    >
                      <Link >
                        <img
                          src={dot}
                          alt=""
                          className="titleIconEdit"
                        />
                      </Link>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Pagination
            currentPage={currentPage}
            totalCount={clientsCount || 0}
            totalPages={Math.ceil(clientsCount / pageSize || 0)}
            pageSize={pageSize}
            appendText={"documents"}
            onPageChange={page => setCurrentPage(page)}
            onSizeChange={size => setPageSize(size)}
          />
          
        </div>
        <div style={{width:"5vw"}}></div>
      </div>
    </div>
  )
}

export default AgendasForMeetings