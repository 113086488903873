import React, { useEffect, useRef, useState } from 'react';
import search from "../assets/search-icon.png"
import bell from "../assets/bell.png"
import profile from "../assets/profile-icon.png"
import vector from "../assets/vector.png"
import logouticon from "../assets/logout.png"
import terms from "../assets/terms.png"
import privacy from "../assets/privacy.png"
import feedback from "../assets/drop-send.png"
import { useDispatch } from 'react-redux';
import { logout } from '../redux/auth/actions';
import { useNavigate } from 'react-router-dom';
import AppModal from './Modal';

const Header = ({title, value, onChange  }) => {
  const headerStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: '#fff',
    color: '#fff',
    float: 'right',
    //paddingLeft: 80,
    padding: '10px 20px',
  };

  const leftStyle = {
    flex: 1,
  };

  const centerStyle = {
    flex: 2,
    left: 100,
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
  };

  const inputStyle = {
    width: '100%',
    height: 26,
    padding: '8px 30px 8px 8px',
    border: 'none',
    borderRadius: '10px',
    backgroundColor: "#F0F0F0"
  };

  const searchIconStyle = {
    position: 'absolute',
    width: 20,
    height: 20,
    top: '50%',
    right: '10px',
    transform: 'translateY(-50%)',
    cursor: 'pointer',
  };

  const rightStyle = {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  };

  const iconContainerStyle = {
    marginLeft: '20px',
    textAlign: 'center',
    cursor: 'pointer',
  };

  const iconImageStyle = {
    width: '30px',
    height: '30px',
  };

  const headerTitleStyle = {
    fontSize: 25,
    color: "#0F5796"
  };

  const itemsStyle = {
    position: 'absolute',
    top: '30px',
    right: '0',
    backgroundColor: "#0F5796",
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    borderRadius: '4px',
    padding: '20px',
    display: 'none',
    width: 190,
    zIndex:999,
    height: 160
  };
  
  const itemStyle = {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '20px',
    marginTop: '10px',
    cursor: 'pointer',
  };

  const dropdownStyle = {
    position: 'relative',
    display: 'inline-block',
    marginRight: '20px',
    backgroundColor: "#0F5796"
  };
  
  const toggleStyle = {
    cursor: 'pointer',
  };
  
  const iconStyle = {
    width: '20px',
    height: '20px',
    marginRight: 15,
    transition: 'transform 0.2s',
  };
  

  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch()
  const navigate = useNavigate();
  const dropdownRef = useRef(null);
  const profileRef = useRef(null);

  const [showTermsConditionModal,setShowTermsConditionModal] = useState(false);
  const [showPrivacyPolicyModal,setShowPrivacyPolicyModal] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleLogout = () => {
    dispatch(logout())
    navigate('/')
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current &&
      !dropdownRef.current.contains(event.target) &&
      !profileRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);


  return (
    <div style={{...headerStyle, paddingLeft: 90}}>
      <div style={leftStyle}>
        <p style={headerTitleStyle}>{title}</p>
      </div>
      <div style={rightStyle}>
        <div style={iconContainerStyle}>
          <img
            src={bell}
            alt="Notification Icon"
            style={iconImageStyle}
          />
          <p style={{fontSize: 12, color: "#0F5796", marginTop: 5}}>Notifications</p>
        </div>
        <div style={{...iconContainerStyle, textAlign:'left'}} onClick={toggleDropdown} ref={profileRef}>
          <img
            src={profile}
            alt="Profile Icon"
            style={iconImageStyle}
          />
          <p style={{fontSize: 12, color: "#0F5796", marginTop: 5}}>
            Profile 
            <img
            style={{marginLeft:'10px'}}
            src={vector}
            alt="Vector Icon"
          />
            </p>
        </div>
      </div>


      <div style={dropdownStyle}>
      
      <div style={{ ...itemsStyle, display: isOpen ? 'block' : 'none' }} ref={dropdownRef}>
        <div style={itemStyle}>
          <img src={feedback} alt="Send Feedback" style={iconStyle} />
          <span>Send Feedback</span>
        </div>
          <div style={itemStyle} onClick={() => {
            setIsOpen(false);
            setShowPrivacyPolicyModal(true);
          }}>
            <img src={privacy} alt="Privacy Policy" style={iconStyle} />
            <span>Privacy Policy</span>
          </div>
          <div style={itemStyle} onClick={() => {
            setShowTermsConditionModal(true);
            setIsOpen(false);
          }
          }>
          <img src={terms} alt="Terms and Conditions" style={iconStyle} />
          <span>Terms and Conditions</span>
        </div>
        <div style={itemStyle} onClick={handleLogout} >
          <img src={logouticon} alt="Log Out" style={iconStyle} />
          <span>Log Out</span>
        </div>
      </div>
    </div>


    {(showTermsConditionModal || showPrivacyPolicyModal) && (
          <AppModal
          modalIsOpen={showTermsConditionModal || showPrivacyPolicyModal}
          termsAndCondition={showTermsConditionModal}
          privacyPolicy={showPrivacyPolicyModal}
          closeModal={()=>{
            setShowTermsConditionModal(false);
            setShowPrivacyPolicyModal(false)
          }}
          onSubmit={()=>{
            setShowTermsConditionModal(false);
            setShowPrivacyPolicyModal(false)
          }}
        />        
      )}
    </div>
  );
};

export default Header;
