import { combineReducers } from "redux"

/**
 * you can import more reducers here
 */
import { AuthReducer } from "./auth/reducers"
import { AppReducer } from "./app/reducers"
import { ClientReducer } from "./clients/reducers"

export const combinedReducers = combineReducers({
  Auth: AuthReducer,
  App: AppReducer,
  client: ClientReducer
})
