import { Paper, Stack, Typography } from "@mui/material"
import React from "react"
import expand from "../../assets/expand.png"
import { ReactComponent as AvatarIcon } from "../../assets/icon-avatar.svg"
import LabelButton from "../LabelButton"
import LinkButton from "../LinkButton"

const TemplateCard = ({
  title,
  image,
  subTitle,
  body,
  actionLabel,
  onClick,
  label,
  labelIcon,
  sideView
}) => {
  return (
    <Paper sx={{ padding: "2rem", height: "100%", boxSizing: "border-box" }}>
      {sideView ? (
        <>
          <Stack direction="column" spacing={2}>
            <Stack direction="row" spacing={2}>
              <div style={{ width: "60px" }}>
                {image ? (
                  <img
                    src={image}
                    alt="template-icon"
                    style={{
                      width: "60px",
                      objectFit: "contain",
                      borderRadius: "6px"
                    }}
                  />
                ) : (
                  <div className="proposal-image-container" style={{ height: 50 }}>
                    {title && (
                      <div className="thumbnail">
                        {title.charAt(0).toUpperCase()}
                      </div>
                    )}
                  </div>
                )}
              </div>
              <Typography
                sx={{
                  color: "#464255",
                  fontWeight: "500",
                  fontSize: "1.125rem"
                }}
              >
                {title}
              </Typography>
              <img
                style={{
                  marginLeft: "auto"
                }}
                src={expand}
                alt="expand"
                className="card-image"
                onClick={onClick}
              />
            </Stack>
            <div className="detail-subtext">
            <Typography
              dangerouslySetInnerHTML={{ __html: `<p>${body.replace(/<[^>]*>/g, '')}</p>` }}
              sx={{
                maxHeight: "4rem",
                overflow: "hidden",
                lineHeight: 'normal',
                fontSize:'14px',
                "& p": { margin: "4px 0", fontSize: "14px", color: "#A3A3A3" }
              }}
            />
            </div>
          </Stack>
          <Stack direction="row" justifyContent="space-between" mt={3}>
            <LinkButton label={label} color="#FFBB54" />
            <LinkButton label={actionLabel} onClick={onClick} />
          </Stack>
        </>
      ) : (
        <>
          <Stack direction="row" spacing={2}>
            <div style={{ width: "60px" }}>
              {image ? (
                <img
                  src={image}
                  alt="template-icon"
                  style={{
                    width: "60px",
                    objectFit: "contain",
                    borderRadius: "6px"
                  }}
                />
              ) : (
                <AvatarIcon />
              )}
            </div>
            <Stack direction="column" spacing={2}>
              <Typography
                sx={{
                  color: "#464255",
                  fontWeight: "500",
                  fontSize: "1.125rem"
                }}
                component="div"
              >
                {title}
                <Typography
                  sx={{
                    color: "#464255",
                    fontWeight: "500",
                    fontSize: "0.875rem"
                  }}
                >
                  {subTitle}
                </Typography>
              </Typography>
              <div className="detail-subtext">
              <Typography
                dangerouslySetInnerHTML={{ __html: `<p>${body.replace(/<[^>]*>/g, '')}</p>` }}
                sx={{
                  maxHeight: "4rem",
                  overflow: "hidden",
                  lineHeight: 'normal',
                  fontSize:'14px',
                  "& p": { margin: "4px 0", fontSize: "14px", color: "#A3A3A3" }
                }}
              />
              </div>
            </Stack>
          </Stack>
          <Stack
            direction="row"
            justifyContent="space-between"
            mt={3}
            gap={3}
            flexWrap="wrap"
          >
            <LabelButton
              label={label}
              icon={labelIcon}
              bgColor="rgba(255, 187, 84, 0.15)"
              color="#FFBB54"
            />
            <LabelButton label={actionLabel} onClick={onClick} />
          </Stack>
        </>
      )}
    </Paper>
  )
}

export default TemplateCard
