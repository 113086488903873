import React, { useEffect, useState } from 'react';
import searchicon from "../../assets/search-icon-black.png"
import { ReactComponent as Calendar } from '../../assets/icon-calendar.svg';

import './Reports.css';
import ReportTable from './ReportTable';
import Pagination from '../../components/Pagination/Pagination';
import Template1 from '../../components/Template/Template1';
import { useNavigate, useParams } from "react-router-dom"
import { request } from '../../utils/http';

const ReportList = (props) => {

    const navigate = useNavigate();

    const params = useParams();

    const id = params.id;

    const [reportList, setReportList] = useState([]);
    const [reportCount, setReportCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(1)

    const [search, setSearch] = useState('');
    const [searchValue, setSearchValue] = useState('');
    const pageSize = 5;
    const totalPages = Math.ceil(reportCount / pageSize || 0);

    const onPageChange = (page) => {
        setCurrentPage(page)
    }

    const handleOnSearchClick = (e) => {
        if (e.key === 'Enter') {
            setSearch(e.target.value)
        }
        setSearchValue(e.target.value);
    }

    const handleOnSearchIconClick = () => {
        setSearch(searchValue);
    }

    const onSeeDetails = (date) => {
        navigate(`/client/reports/${id}/weekly-report`, {
            state: {
                date: date
            }
        })
    }

    useEffect(() => {
        request
            .get("/reports/api/v1/", {
                params: {
                    page: currentPage,
                    search: search,
                    size: pageSize
                }
            })
            .then(({ data }) => {
                const reports = data?.results || [];
                const modifiedReports = reports.map(data => {
                    const date = new Date(data.created_at);
                    return {
                        id: data.id,
                        name: data.report_title,
                        week: "Week " + Math.ceil(date.getDate() / 7),
                        month: date.toLocaleString('default', { month: 'long' }),
                        year: date.getFullYear(),
                        created_at: data.created_at
                    }
                })
                setReportList(modifiedReports)
                setReportCount(data.count)
            })
            .catch(() => {
                setCurrentPage(1)
            })
    }, [currentPage, pageSize, search])

    return (
        <div className="reports">
            <Template1 heading='Reports' title='Reports'>
                <div className='report-table-action-items'>
                    <div style={{ display: "flex" }}>
                        <div
                            style={{
                                flex: 2,
                                display: "flex",
                                alignItems: "center",
                                position: "relative"
                            }}
                        >
                            <input
                                type="text"
                                placeholder="Search here..."
                                onKeyUp={handleOnSearchClick}
                                className='search-placeholder'
                                style={{
                                    width: "100%",
                                    height: 26,
                                    padding: "8px 50px 8px 18px",
                                    border: "none",
                                    borderRadius: "10px",
                                    backgroundColor: "rgba(15, 87, 150, 0.1)"
                                }}
                            />
                            <img
                                src={searchicon}
                                alt="Search Icon"
                                onClick={handleOnSearchIconClick}
                                style={{
                                    position: "absolute",
                                    width: 20,
                                    height: 20,
                                    top: "50%",
                                    right: "20px",
                                    transform: "translateY(-50%)",
                                    cursor: "pointer"
                                }}
                            />
                        </div>
                    </div>
                    <button className="template-button" onClick={() => navigate(`/client/reports/${id}/weekly-report`)} style={{ padding: '10px 20px', borderRadius: '14px' }}>
                        <Calendar width={14} height={14} style={{ marginRight: '5px' }} />
                        Weekly reports
                    </button>
                    <button className="template-button" onClick={() => navigate(`/client/reports/${id}/monthly-report`)} style={{ padding: '10px 20px', borderRadius: '14px' }}>
                        <Calendar width={14} height={14} style={{ marginRight: '5px' }} />
                        Monthly reports
                    </button>
                    <button className="template-button" onClick={() => navigate(`/client/reports/${id}/yearly-report`)} style={{ padding: '10px 20px', borderRadius: '14px' }}>
                        <Calendar width={14} height={14} style={{ marginRight: '5px' }} />
                        Annual reports
                    </button>
                </div>
                <ReportTable reportList={reportList} onSeeDetails={onSeeDetails} />
                {totalPages ? <div >
                    <Pagination
                        hideTotalCount={false}
                        totalPages={totalPages}
                        currentPage={currentPage}
                        pageSize={pageSize}
                        totalCount={reportCount || 0}
                        onPageChange={onPageChange} />
                </div> : null}

            </Template1>
        </div>
    )
}

export default ReportList;