import React, { useEffect, useMemo, useState } from "react"
import edit from "../../../assets/edit2.png"
import deleteImg from "../../../assets/delete.png"
import searchicon from "../../../assets/search-icon.png"
import ReactQuill from "react-quill"
import "react-quill/dist/quill.snow.css"
import Pagination from "../../../components/Pagination/Pagination"
import { useLocation, useNavigate } from "react-router-dom"
import { request } from "../../../utils/http"
import { toast } from "react-toastify"
import { Typography } from "@mui/material"
import AppModal from "../../../components/Modal"

const NewFAQ = () => {
    const navigate = useNavigate();
    const { pathname, state } = useLocation();
    const [currentPage, setCurrentPage] = useState(1)
    const [pageSize, setPageSize] = useState(3);
    const [title, setTitle] = useState("");
    const [content, setContent] = useState('');
    const [faqs, setFaqs] = useState([]);
    const [faqCount, setFaqCount] = useState(0);
    const [search, setSearch] = useState('')
    const client = pathname.split("/")[3];
    const [isLoading, setIsLoading] = useState(false);
    const [openModal, setOpenModal] = useState(false)
    const [faqId, setFaqId] = useState(false)

    const hasRequiredFields = useMemo(() => {
        return title && content
    }, [title, content])

    useEffect(() => {
        request.get("/clients/api/v1/faq/", {
            params: {
                client: client,
                page: currentPage,
                search: search,
                size: pageSize
            }
        })
            .then(({ data }) => {
                setFaqs(data.results);
                setFaqCount(data.count);
            })
            .catch(() => {
                setCurrentPage(1);
            })
    }, [client, currentPage, pageSize, search]);

    useEffect(() => {
        if (state) {
            setTitle(state.title);
            setContent(state.content);
        }
        else {
            setTitle("");
            setContent("");
        }
    }, [state]);

    const handleFinish = () => {
        setIsLoading(true);

        if (state.id) {
            request.patch(`/clients/api/v1/faq/${state.id}/`, {
                faq_title: title,
                faq_document: content
            })
                .then(() => {
                    setIsLoading(false);
                    toast.success("FAQ saved successfully.")
                });
        }
        else {
            request.post("/clients/api/v1/faq/", {
                faq_title: title,
                faq_document: content
            })
                .then(() => {
                    setIsLoading(false);
                    toast.success("FAQ saved successfully.")
                });
        }
    }

    const handleTitleChange = e => {
        setTitle(e.target.value)
    }

    const handleChange = (value) => {
        setContent(value);
    };

    const handleClick = (item) => () => {
        navigate(`/client/create-faq/${client}`, {
            state: {
                id: item.id,
                title: item.faq_title,
                content: item.faq_document,
            }
        });
    }

    const handleRemove = id => () => {
        setFaqId(id)
        setOpenModal(true)
    }

    const handleDelete = () => {
        request.delete(`/clients/api/v1/faq/${faqId}/`).then(() => {
            toast.success("FAQ deleted successfully.")
            setOpenModal(false)
        })
    }

    return (
        <div className="create-content" style={{ padding: "0px 0px 0px 20px" }}>
            <div className="create-left-proposal" style={{ padding: 0 }}>
                <div className="proposal-content" >
                    <div style={{ display: "flex" }}>
                        <div className="text-container">
                            <p className="proposal-card-title" style={{ marginBottom: 15, fontSize: 24 }}>
                                Create new FAQ
                            </p>
                            <p className="card-description" style={{ marginBottom: 25 }}>
                                Document
                            </p>
                        </div>
                    </div>
                    <div>
                        <button
                            className="template-button"
                            style={{
                                backgroundColor: hasRequiredFields && !isLoading ? "#0F5796" : "#CDCDCD",
                                color: "#fff",
                                padding: "0 30px",
                                height: 40,
                                marginBottom: 40
                            }}
                            disabled={!hasRequiredFields || isLoading}
                            onClick={handleFinish}>
                            Finish
                        </button>
                    </div>
                </div>
                <div style={{ backgroundColor: "#fff", padding: 40, }}>
                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", }}>
                        <p className="proposal-card-title" style={{ fontSize: 16 }}>
                            FAQ document name:
                        </p>
                        <div className="search-dropdown">
                            <div className="input-container">
                                <input
                                    value={title}
                                    type="text"
                                    placeholder="Text input"
                                    onChange={handleTitleChange}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="quill-editor">
                        <ReactQuill value={content} onChange={handleChange} />
                    </div>
                </div>
            </div>
            <div className="create-right-column">
                <div style={{ display: "flex", marginBottom: 20 }}>
                    <div
                        style={{
                            flex: 2,
                            display: "flex",
                            alignItems: "center",
                            position: "relative"
                        }}
                    >
                        <input
                            type="text"
                            value={search}
                            onChange={e => setSearch(e.target.value)}
                            placeholder="Search..."
                            style={{
                                width: "100%",
                                height: 26,
                                padding: "8px 30px 8px 8px",
                                border: "none",
                                borderRadius: "10px",
                                backgroundColor: "#F0F0F0"
                            }}
                        />
                        <img
                            src={searchicon}
                            alt="Search Icon"
                            style={{
                                position: "absolute",
                                width: 20,
                                height: 20,
                                top: "50%",
                                right: "10px",
                                transform: "translateY(-50%)",
                                cursor: "pointer"
                            }}
                        />
                    </div>
                </div>
                {faqs?.length
                    ? faqs.map(item => (
                        <div key={item.id} className="proposal-card" style={{ height: 150 }}>
                            <div className="proposal-content" style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                marginBottom: "12px",
                            }}>
                                <Typography
                                    sx={{
                                        marginTop: "6px",
                                        color: "#464255",
                                        fontWeight: "500",
                                        fontSize: "1.125rem"
                                    }}
                                >
                                    {item.faq_title}
                                </Typography>
                                <div style={{ marginTop: "-16px" }}>
                                    <img
                                        src={edit}
                                        alt="item"
                                        className="card-image"
                                        style={{ height: 20, width: 20 }}
                                        onClick={handleClick(item)}
                                    />
                                    <img
                                        src={deleteImg}
                                        alt="item"
                                        className="card-image"
                                        style={{ height: 20, width: 20 }}
                                        onClick={handleRemove(item.id)}
                                    />
                                </div>
                            </div>
                            <div className="detail-subtext">
                                <Typography
                                    dangerouslySetInnerHTML={{ __html: `<p>${item?.faq_document.replace(/<[^>]*>/g, '')}</p>` }}
                                    sx={{
                                        height: "40px",
                                        maxHeight: "4rem",
                                        fontSize: '14px',
                                        lineHeight: 'normal',
                                        overflow: 'hidden',
                                        "& p": { margin: "8px 0", fontSize: "14px", color: "#A3A3A3" }
                                    }}
                                />
                            </div>
                            <div className="proposal-buttons">
                                <button className="template-button" style={{ backgroundColor: "#fff", paddingLeft: "0px" }}>
                                    See FAQ details
                                </button>
                            </div>
                        </div>
                    ))
                    : null}
                <Pagination
                    currentPage={currentPage}
                    totalCount={faqCount || 0}
                    totalPages={Math.ceil(faqCount / pageSize || 0)}
                    pageSize={pageSize}
                    onPageChange={page => setCurrentPage(page)}
                    onSizeChange={size => setPageSize(size)}
                    hideTotalCount
                />
            </div>
            <AppModal
                modalIsOpen={openModal}
                deleteRecord
                onClick={handleDelete}
                closeModal={() => {
                    setOpenModal(false)
                }}
            />
        </div>
    )
}

export default NewFAQ
