import * as actions from "./constants"

const initialState = {
  clientsData: [],
  isLoading: false,
  pressReleaseTemplates: [],
  errors: {},
  isSuccess: {}
}

export const ClientReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.GET_CLIENTS_SUCCESS:
      return { ...state, clientsData: action.payload, errors: null }
    case actions.GET_CLIENTS_ERROR:
      return { ...state, errors: { clientsData: action.error } }
    case actions.GET_PRESS_RELEASE_TEMPLATES_SUCCESS:
      return { ...state, pressReleaseTemplates: action.payload, errors: null }
    case actions.GET_PRESS_RELEASE_TEMPLATES_ERROR:
      return { ...state, errors: { pressReleaseTemplates: action.error } }
    case actions.POST_PRESS_RELEASE_TEMPLATES:
      return { ...state, isLoading: true }
    case actions.POST_PRESS_RELEASE_TEMPLATES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isSuccess: { pressReleaseTemplates: Boolean(action.payload) },
        errors: null
      }
    case actions.POST_PRESS_RELEASE_TEMPLATES_ERROR:
      return {
        ...state,
        isLoading: false,
        errors: { pressReleaseTemplates: action.error }
      }
    default:
      return state
  }
}
