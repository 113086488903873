import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import "./ClientStylesheet.css"
import ReactQuill from 'react-quill'
import expand from "../../assets/verticaldots.png"
import plus from "../../assets/plus-blue.png"
import { Grid, Typography } from '@mui/material'
import Pagination from '../../components/Pagination/Pagination'
import { request } from '../../utils/http'
import { toast } from "react-toastify"

const MeetingAgenda = () => {

    const [content, setContent] = useState('');

    const [agendas, setAgendas] = useState([]);
    const [clientsCount, setClientsCount] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)
    const [currentNote, setCurrentNote] = useState('')
    const [addNewNoteToggle, setAddNewNoteToggle] = useState(false)
    // const navigate = useNavigate();

    const location = useLocation()
    const item = location?.state?.item
    const selectedClient = location?.state?.selectedClient

    const[allNotesLocal, setAllNotesLocal] = useState(item?.notes_detail || [])


    useEffect(()=>{
        

        setContent(item?.primary_notes)
    },[item])

    useEffect(()=>{
        // request.get(`https://ezpr-32569-staging.botics.co/agendas/api/v1/agenda/${item.id}/`)
        // .then((res)=> setNotesLocal(res.data.notes_detail) )
    }, [allNotesLocal])


    useEffect(()=>{
        const fetchData = async () => {
          try {
    
            const data = await request.get(
              `https://ezpr-32569-staging.botics.co/agendas/api/v1/agenda/client/${item.client}/`,
              {
                params: {
                  page: currentPage,
                  size: 2
                }
              }
            );
            setClientsCount(data.data.count)
            setAgendas(data.data.results);
            setAllNotesLocal(data.data.results.filter(mem => mem.id == item.id)[0].notes_detail)
         
          } catch (error) {
            console.error("Error fetching data:", error);
          }
        };
    
      
        fetchData();
      },[currentPage, item, allNotesLocal])


    const handleChange = (value) => {
        setContent(value);
    };

    const handleClick = (newItem)=>{
        setAddNewNoteToggle(!addNewNoteToggle)
    }


    const handleNotes = value => {
        setCurrentNote(value)
    }

    const handleNoteSave = () => {
        const noteWithoutPTag = currentNote.replace(/<\/?p[^>]*>/g, '');

        request.post(`https://ezpr-32569-staging.botics.co/agendas/api/v1/agenda/${item.id}/add-note/`,{
            body: noteWithoutPTag
        })
        .then(() => {
            toast.success("Note is now saved.");
            setAllNotesLocal((current) => [noteWithoutPTag,...current])
            setCurrentNote('')
        })
        .catch((err)=>toast.error("The proposal is not saved/finished yet"))
    }

    
    const handleNoteRemove = (note) =>{
        
        request.delete(`https://ezpr-32569-staging.botics.co/agendas/api/v1/agenda/${item.id}/delete-note/?note_id=${note.id}`)
        .then((res) => {
            if(res.data.message == "note removed"){
                setAllNotesLocal(allNotesLocal.filter((notes)=>notes.id!==note.id))
                toast.success("Note removed.");
            }
        }
        )
        .catch((err)=>toast.error("Could not remove"))
    }

    // console.log("allNotesLocal - ", allNotesLocal)
    
  return (
    <div className='cover-div'>

        <div className='agenda-section-one'>Meeting Agenda
        <div style={{fontSize:"15px", marginTop:15,color:"#A3A3A3"}} >See Details</div>
        </div>
        

        <div className='agenda-section-two white-bg' style={{ maxHeight:"60vh", marginTop:"3.5rem", marginRight:"2vw", display:"flex", flexDirection:"column", marginBottom:"7.5vh"}}>
            {/* <div style={{width:"100%", height:"100%"}}> */}
                <div className='mb' style={{width:"100%", display:"flex", justifyContent:"space-between"}}>
                    <div style={{fontSize:"25px", color:"#0F5796", fontWeight:"600"}}>
                        {selectedClient?.name}
                    </div>
                    <div style={{color:"#0F5796"}}>
                        {item?.campaign_name}
                    </div>
                </div>

                <div style={{width:"100%", display:"flex", justifyContent:"flex-start"}}>
                    <div style={{fontSize:"20px"}}>
                        {item?.meeting_title}
                        <div style={{marginBottom:"1rem", marginTop:"0.5rem", fontSize:"12px",color: "#A3A3A3"}} >{item?.meeting_date_and_time}</div>
                    </div>
                
                </div>
                <div className="quill-editor-1 quill-editor-notes-1" style={{ maxHeight: "30vh"}}>
                    <ReactQuill value={content} onChange={handleChange} />
                </div>
                

                <div className='create-agenda-button' style={{ width:"100%", display: 'flex', justifyContent: 'flex-end' }}>
                    <button className="template-button" onClick={() => handleClick(item)}>
                        View
                    </button>
                </div>

            {/* </div> */}

            
        </div>

        <div className='notes-section-three' style={{fontSize:"20px", marginLeft:"2vw"}}>
            <div style={{marginBottom:"2rem"}}>
                Notes
            </div>
            
            <div style={{ backgroundColor: "#fff", borderRadius: 10 }}>
                <div style={{ padding:"1.5rem", display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                    <h3 style={{ color: "#0F5796", fontSize: 24, marginTop: 5, marginBottom: 5 }}>Notes</h3>
                    <img
                        src={plus}
                        alt="Notification Icon"
                        style={{ width: 18, height: 18, marginRight: 10, cursor:"pointer" }}
                        onClick={handleClick}
                    />
                </div>
                <p style={{ margin:0, fontSize: 14, color: "#A3A3A3", padding:"0rem 1.5rem" }}>Maiores dicta atque dolore</p>
                
                <div style={{padding:"0 1.5rem"}}>
                {addNewNoteToggle ? (
                    <>
                        <div className="quill-editor quill-editor-notes" style={{ marginBottom: 32, maxHeight: "20vh" }}>
                            <ReactQuill value={currentNote} onChange={handleNotes} style={{ maxHeight: "20vh" }} />
                        </div>
                        <div style={{ marginBottom: 20, marginTop: 20, display: "flex", justifyContent: "end" }}>
                            <button className="template-button" style={{ fontWeight: "bold" }} onClick={handleNoteSave}>Save</button>
                        </div>
                    </>
                ) : null}

                {allNotesLocal.map((note, index) =>(
                    <div key={index}>
                        <div className="quill-editor quill-editor-notes" style={{ marginBottom: 32, maxHeight: "20vh" }}>
                            <ReactQuill readOnly={true} value={note.body} style={{ maxHeight: "20vh" }} />
                        </div>
                        <div style={{ marginBottom: 20, display: "flex", justifyContent: "end" }}>
                            <button className="template-button" onClick={()=>handleNoteRemove(note)}>Remove</button>
                        </div>
                    </div>
                 ))}

                </div>

                <Grid spacing={2} >
                    {agendas.map((item, index) => (
                        <Grid key={item.id} item xs={12} sm={6} md={6} lg={6}>
                        <div className={`proposal-card ${index % 2 === 0 ? 'odd-border' : ''}`}>
                            <div className="proposal-content">
                            <div style={{ display: "flex", flexDirection:"space-between", alignItems: "center", width: "100%" }}>
                                <Typography
                                sx={{
                                    fontSize: "20px",
                                }}
                                >
                                {item.campaign_name}
                                </Typography>
                                <img
                                style={{
                                    marginLeft: "auto",
                                    cursor:"pointer"
                                }}
                                src={expand}
                                alt="expand"
                        
                                onClick={() => handleClick(item)}
                                />
                            </div>
                            </div>
                            <div style={{fontSize:"12px",color: "#A3A3A3"}}>
                                {item.meeting_date_and_time}
                            </div>
                
                            <div style={{marginTop:"1.2rem", fontSize:"20px"}}>
                                {item.meeting_title}
                            </div>
                            <div className='primaryNotes' style={{fontSize:"15px", marginTop:"5px", width: "140px",
                                wordBreak: "break-word",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis"}}>
                            {item.primary_notes}
                            </div>
                            <button className="template-button" style={{width:"30%", marginTop:"1rem", marginRight:0}} onClick={() => handleClick(item)}>
                                View
                            </button>
                        </div>
                        </Grid>
                    ))}
                </Grid>

            </div>    

            <Pagination
                currentPage={currentPage}
                totalCount={clientsCount}
                totalPages={Math.ceil(clientsCount / 2 || 0)}
                pageSize={2}
                hideTotalCount={true}
                onPageChange={page => setCurrentPage(page)}
            />

        </div>
        <div style={{width:"10vw"}}></div>
    
    </div>
  )
}

export default MeetingAgenda