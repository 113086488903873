import React, {  useEffect, useState } from "react"
import Header from "../../components/Header"
import "./Dashboard.css"
import { useDispatch, useSelector } from "react-redux"
import { updateBusiness } from "../../redux/app/actions"
import { useLocation, useNavigate, useParams } from "react-router-dom"

import Select from "react-select"
import countryList from "react-select-country-list"
import { request } from "../../utils/http"
import { toast } from "react-toastify"

const EditBusiness = props => {
  const { id } = useParams()
  const location = useLocation()
  const item = location.state.item

  const token = useSelector(state => state.Auth.accessToken)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [search, setSearch] = useState("")

  const [options, setOptions] = useState(countryList().getData())

  const [selectedCountry, setSelectedCountry] = useState({})

  const [businessData, setBusinessData] = useState({
    name: item.name || "",
    email: item.email || "",
    phone: item.phone || "",
    city: item.city || "",
    street: item.street || "",
    state: item.state || "",
    zip_code: item.zip_code || "",
    country: item.country || "",
    status: 1
  })

  useEffect(() => {
    setOptions(countryList().getData())

    if (item.country) {
      const selectedOption = options.find(
        option => option.label === item.country
      )
      setSelectedCountry(selectedOption)
    }
  }, [item.country, options])

  const handleChange = event => {
    const { name, value } = event.target
    setBusinessData(prevData => ({ ...prevData, [name]: value }))
  }

  const handleSubmit = event => {
    event.preventDefault()

    // dispatch(updateBusiness({ id, ...businessData }, navigate))

    request
      .patch(
        `/businesses/api/v1/business/${id}/`,
        { id, ...businessData },
        {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        }
      )
      .then(() => {
        toast.success("Saved")
        navigate("/")
      })
  }

  return (
    <div className="dashboard-container">
      {/* <SideMenu /> */}
      <div className="main-content">
        <Header
          title="Edit New Business"
          value={search}
          onChange={e => setSearch(e.target.value)}
        />
        <div className="horizontal-rule"></div>
        <div className="content">
          {/* Left Column */}
          <div
            className="add-left-side"
            style={{ marginTop: 40, marginRight: 40 }}
          >
            <div className="title">Edit Business</div>
            <div className="subtitle">Edit details</div>
          </div>

          {/* Right Column */}
          <div className="form-container">
            <form onSubmit={handleSubmit}>

              <div className="form-group">
                <label>Business Name:</label>
                <input
                  type="text"
                  name="name"
                  placeholder="Name"
                  value={businessData.name}
                  onChange={handleChange}
                  required
                />
              </div>

              <div className="form-group">
                <label>Business Email:</label>
                <input
                  type="text"
                  name="email"
                  placeholder="Email"
                  value={businessData.email}
                  onChange={handleChange}
                  required
                />
              </div>

              <div className="form-group">
                <label>Business Phone:</label>
                <input
                  type="text"
                  name="phone"
                  placeholder="Phone"
                  value={businessData.phone}
                  onChange={handleChange}
                  required
                />
              </div>

              <div className="form-group">
                <label>Business Address:</label>
                <input
                  type="text"
                  name="street"
                  placeholder="Address"
                  value={businessData.street}
                  onChange={handleChange}
                  required
                />
              </div>

              <div className="form-group">
              <label>City:</label>
              <input
                type="text"
                name="city"
                placeholder="City"
                value={businessData.city}
                onChange={handleChange}
                required
                className="city-input" 
              />
              <input
                type="text"
                name="zip_code"
                placeholder="Zip"
                value={businessData.zip_code}
                onChange={handleChange}
                required
                className="city-input" 
              />
              <input
                type="text"
                name="state"
                placeholder="State"
                value={businessData.state}
                onChange={handleChange}
                required
              />
            </div>

              <div className="form-group">
                <label>Country:</label>
                <div className="select-container"> {/* Add a container for the Select component */}
                  <Select
                    isSearchable={true}
                    options={options}
                    value={selectedCountry}
                    onChange={selectedOption => {
                      setSelectedCountry(selectedOption)
                      setBusinessData(prevData => ({
                        ...prevData,
                        country: selectedOption.label
                      }))
                    }}
                  />
                </div>
              </div>

              <div className="form-group">
                <label></label>
                <button type="submit" className="add-save-button">
                  Save
                </button>
              </div>

            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default EditBusiness
