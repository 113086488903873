/* eslint-disable no-undefined */
import axios from "axios"
// import CookieManager from '@react-native-community/cookies';

const APP_PLATFORM = "Mobile"
export const BASE_URL = "https://ezpr-32569-staging.botics.co/"
// export const BASE_URL = 'http://127.0.0.1:8000';

export const request = axios.create({
  headers: {
    // app_platform: APP_PLATFORM,
    // app_version: 1,
    // cookie: undefined,
    // Cookie: undefined,
  }
})

export async function setupHttpConfig() {
  //const response = await axios.get(BASE_URL);
  //console.log(response)
  //const allCookies = document.cookie;
  //console.log(allCookies);
  request.defaults.baseURL = BASE_URL
  request.defaults.timeout = 25000
  request.defaults.withCredentials = false
  axios.defaults.headers["Content-Type"] = "application/json"
  // axios.defaults.headers['X-CSRF-Token'] = response.headers["X-CSRFTOKEN"]
  // you can add more default values for http requests here
}

export function addTokenToHttp(token) {
  return new Promise(resolve => {
    // do something asynchronous which eventually calls either:
    if (token) {
      request.defaults.headers.Authorization = `Token ${token}`
      resolve()
    } else {
      request.defaults.headers.Authorization = ""
      resolve()
    }
  })
}
