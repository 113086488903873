import React, { useEffect, useState } from "react"
import ClickAwayListener from "@mui/material/ClickAwayListener"
import Grow from "@mui/material/Grow"
import Paper from "@mui/material/Paper"
import Popper from "@mui/material/Popper"
import { Box, FormControlLabel, Radio, RadioGroup, Typography } from "@mui/material"
import { makeStyles } from "@mui/styles"
import { ReactComponent as CheckedIcon } from "../../assets/check-box.svg"
import { ReactComponent as UncheckedIcon } from "../../assets/uncheck-box.svg"
const useStyles = makeStyles({
  activeMenu: {
    backgroundColor: "#F0F0F0 !important",
    color: "#0F5796 !important"
  }
})

const ListMenu = React.forwardRef(({
  itemList,
  onClick,
  heading,
  subheading,
  activeItem,
  targetItem,
}, ref) => {
  const [anchorEl, setAnchorEl] = useState(false)
  const open = Boolean(anchorEl)
  const [options, setOptions] = useState(itemList)
  const cls = useStyles()


  const onOpen = e => {
    setAnchorEl(prev => (prev ? null : e?.currentTarget))
  }

  const handleClose = event => {
    if (anchorEl && anchorEl?.contains(event?.target)) {
      return
    }

    setAnchorEl(null)
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open)
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      // anchorEl?.focus()
    }

    prevOpen.current = open
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])

  useEffect(() => {
    setOptions(itemList)
  }, [itemList])

  useEffect(() => {
    targetItem && onOpen(targetItem)
  }, [targetItem])

  const handleItemClick = item => e => {
    onClick(e, item)
    handleClose(e)
  }

  return (
    <Box>
      <Popper
        open={open}
        anchorEl={anchorEl}
        role={undefined}
        placement="bottom-end"
        sx={{ zIndex: 10, marginTop: "0.5rem !important" }}
        transition
      >
        {({ TransitionProps }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: "bottom-end"
            }}
          >
            <Paper sx={{ background: "transparent", boxShadow: "none" }}>
              <ClickAwayListener onClickAway={handleClose}>
                <div>
                  <Paper sx={{ padding: '10px 0px', minWidth: '300px' }}>
                    <Typography
                      sx={{ color: "#0F5796", marginLeft: "1rem", fontSize: "16px", fontWeight: '600' }}
                      paragraph
                    >
                      {heading}
                      <Typography sx={{ fontSize: '12px' }}>
                        {subheading}
                      </Typography>
                    </Typography>
                    <RadioGroup name="use-radio-group" defaultValue="first" sx={{ margin: '0px 20px' }}>
                      {itemList.map((item, index) => {
                        const isActive = item?.value === activeItem?.value
                        return (<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', background: "rgba(243, 243, 243, 1)", marginBottom: '5px', borderRadius: '10px' }}>
                          <div>
                            <span style={{ color: "#0F5796", background: 'rgba(15, 87, 150, 0.15)', padding: '10px 20px', borderRadius: '10px' }}>
                              {item.label}
                            </span>
                          </div>
                          <FormControlLabel
                            key={index}
                            value={item.value}
                            label={""}
                            onClick={handleItemClick(item)}
                            control={<Radio checked={isActive} checkedIcon={<CheckedIcon />} icon={<UncheckedIcon />} />} />
                        </div>)
                      })}
                    </RadioGroup>
                  </Paper>
                </div>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Box>
  )
});

export default ListMenu
