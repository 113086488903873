import React, { useState } from "react"
import AppModal from "../../components/Modal"
import "./Login.css"
import { useDispatch } from 'react-redux';
import { resetPassword } from "../../redux/auth/actions";
import { useNavigate } from "react-router-dom";

const Reset = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate();
  const [email, setEmail] = useState("")
  const [showPopup, setShowPopup] = useState(false)

  const handleReset = e => {
    e.preventDefault()

    // Handle the login logic here, e.g., submit the login credentials to the server
    const values = {
      email: email
    }
    dispatch(resetPassword(values))
    setShowPopup(true)
  }

  const handleClosePopup = () => {
    setShowPopup(false) // Close the pop-up when the user clicks the close button
    navigate("/login");
  }

  return (
    <div className="login-screen">
      <header className="header-login">
      <h1 style={{cursor:"pointer"}} onClick={()=> navigate("/")}>Logo</h1>
      </header>

      <div className="reset-container">
        <form
          onSubmit={handleReset}
          className="login-form"
          style={{ width: 500 }}
        >
          <h2>Password Reset</h2>
          <input
            type="email"
            id="email"
            value={email}
            onChange={e => setEmail(e.target.value)}
            placeholder="Enter your email"
            required
            style={{ width: 470, height: 40, marginTop: 20 }}
          />
          <button className="login-button" type="submit">
            Reset Password
          </button>
        </form>
      </div>

      {showPopup && (
        <AppModal
          modalIsOpen={showPopup}
          reset={true}
          values={email}
          closeModal={handleClosePopup}
          onSubmit={handleClosePopup}
        />
      )}
    </div>
  )
}

export default Reset
