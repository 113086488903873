import React, { useEffect } from "react"
import "./App.css"
import "react-toastify/dist/ReactToastify.css"

import { Provider } from "react-redux"
import { store } from "./redux/store"
import { setupHttpConfig } from "./utils/http"
import Navigation from "./Navigation"
import { ToastContainer } from "react-toastify"

function App() {
  useEffect(() => {
    setupHttpConfig()
  }, [])

  return (
    <Provider store={store}>
      <Navigation />
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        pauseOnHover
      />
    </Provider>
  )
}

export default App
