import React, { useState } from "react"
import Header from "../../components/Header"
import "./Contract.css"
import expand from "../../assets/expand.png"
import close from "../../assets/close.png"
import trash from "../../assets/trash.png"
import ReactQuill from "react-quill"
import "react-quill/dist/quill.snow.css"
import mail from "../../assets/mail-black.png"
import phonecall from "../../assets/phone-gray.png"
import address from "../../assets/address-gray.png"
import { useLocation, useNavigate } from 'react-router-dom';
import AppModal from "../../components/Modal"
import { Stack, Typography } from "@mui/material"
import { request } from "../../utils/http"
import { toast } from "react-toastify"

const Details = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [search, setSearch] = useState('')
  const [openModal, setOpenModal] = useState(false);
  const { state, pathname } = useLocation();
  const navigate = useNavigate();
  const business = pathname.split("/")[2];

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  const handleClick = () => {
    navigate(`/create-contract/${business}`, {
      state: {
        id: state?.contract?.id,
        title: state?.contract?.title,
        content: state?.contract?.body,
        image: state?.contract?.image,
        members: state?.contract?.members
      }
    });
  }

  const handleDelete = () => {
    if (state?.contract?.id)
      request.delete(`/contracts/api/v1/contract/${state?.contract?.id}/`)
        .then(() => {
          toast.success("Contract deleted successfully.");
          navigate(`/contracts/${business}`);
        })
  }



  return (
    <div className="dashboard-container">
      <div className="main-content">
        <Header title="Contract Details"
          value={search}
          onChange={e => setSearch(e.target.value)} />

        <div className="horizontal-rule"></div>
        <div className="content" style={{ justifyContent: "space-between" }}>
          {/* Right Column */}
          <div className="right-columnsl" style={{ maxWidth: "540px" }}>

            <div className="proposal-card" style={{ height: 'auto', minHeight: '160px' }}>
              <div className="proposal-content">
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div className="proposal-image-container" style={{ height: 50 }}>
                    {state?.business?.name && (
                      <div className="thumbnail">
                        {state?.business?.name.charAt(0).toUpperCase()}
                      </div>
                    )}
                  </div>
                  {/* <div className="proposal-image-container" style={{ height: 50, width: 50 }}>
                    {state?.}
                  </div> */}
                  <div className="text-container">
                    <p className="proposal-card-title" style={{ color: "#6A707E" }}>
                      {state?.business?.name}
                    </p>
                  </div>
                </div>
              </div>
              <div className="proposal-buttonss" style={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr", columnGap: '10px' }}>
                <div>
                  <div style={{ display: "flex", alignItems: "center", columnGap: '10px' }}>
                    <img src={mail} alt={"button"} className="proposal-button-icon" style={{ width: 16, height: 16 }} />
                    <p style={{ color: "#6A707E", fontSize: 12 }}>Business Email</p>
                  </div>
                  <h3
                    style={{
                      color: "#6A707E",
                      fontSize: 16,
                      marginTop: 5,
                      width: "140px",
                      wordBreak: 'break-word',
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis"
                    }}>
                    {state?.business?.email}
                  </h3>
                </div>
                <div>
                  <div style={{ display: "flex", alignItems: "center", columnGap: '10px' }}>
                    <img src={phonecall} alt={"button"} className="proposal-button-icon" style={{ width: 14, height: 16 }} />
                    <p style={{ color: "#6A707E", fontSize: 12 }}>Business Phone</p>
                  </div>
                  <h3
                    style={{
                      color: "#6A707E",
                      fontSize: 16,
                      marginTop: 5,
                      width: "140px",
                      wordBreak: 'break-word',
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis"
                    }}>
                    {state?.business?.phone}
                  </h3>
                </div>
                <div>
                  <div style={{ display: "flex", alignItems: "center", columnGap: '10px' }}>
                    <img src={address} alt={"button"} className="proposal-button-icon" style={{ width: 14, height: 16 }} />
                    <p style={{ color: "#6A707E", fontSize: 12 }}>Business Address</p>
                  </div>
                  <h3
                    style={{
                      color: "#6A707E",
                      fontSize: 16,
                      marginTop: 5,
                      width: "140px",
                      wordBreak: 'break-word',
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis"
                    }}>
                    {state?.business?.address}
                  </h3>
                </div>
              </div>
            </div>

            <div className="proposal-card">
              <div className="proposal-content">
                <Stack direction="row" spacing={2}>
                  <div style={{ display: "flex", width: "100%" }}>
                    {state?.contract?.image ? (
                      <img
                        src={state?.contract?.image}
                        alt="template-icon"
                        style={{
                          width: "60px",
                          height: "60px",
                          objectFit: "contain",
                          borderRadius: "6px"
                        }}
                      />
                    ) : (
                      <div className="proposal-image-container" style={{ height: 50 }}>
                        {state?.contract?.title && (
                          <div className="thumbnail">
                            {state?.contract?.title.charAt(0).toUpperCase()}
                          </div>
                        )}
                      </div>
                    )}
                    <Typography
                      sx={{
                        marginLeft: "20px",
                        marginTop: "6px",
                        color: "#464255",
                        fontWeight: "500",
                        fontSize: "1.125rem"
                      }}
                    >
                      {state?.contract?.title}
                    </Typography>
                    <img
                      style={{
                        marginLeft: "auto"
                      }}
                      src={expand}
                      alt="expand"
                      className="card-image"
                      onClick={handleClick}
                    />
                  </div>
                </Stack>
              </div>
              <div className="detail-subtext">
                <Typography
                  dangerouslySetInnerHTML={{ __html: `<p>${state?.contract?.body?.replace(/<[^>]*>/g, '')}</p>` }}
                  sx={{
                    height: "40px",
                    maxHeight: "4rem",
                    overflow: 'hidden',
                    lineHeight: 'normal',
                    fontSize: '14px',
                    "& p": { margin: "8px 0", fontSize: "14px", color: "#A3A3A3" }
                  }}
                />
              </div>
              <div className="proposal-buttons" style={{ justifyContent: "end" }}>
                <button onClick={() => setShowPopup(!showPopup)} className="template-button">Send Contract</button>
              </div>
            </div>

            {showPopup && (
              <AppModal
                modalIsOpen={showPopup}
                proposal
                contract
                values={{
                  id: state?.contract?.id,
                  title: state?.contract?.title,
                  body: state?.contract?.body,
                  image: state?.contract?.image,
                  email: state?.business?.email,
                  handleClick,
                }}
                closeModal={handleClosePopup}
                onSubmit={handleClosePopup}
              />

            )}
          </div>

          <div className="left-proposall" style={{ flex: 1, marginLeft: 40, backgroundColor: "#fff", padding: 20 }}>
            <div className="proposal-content">
              <div style={{ display: "flex" }}>

                <div className="text-container">
                  <p className="proposal-card-title" style={{ marginBottom: 15 }}>
                    Preview
                  </p>
                  <p className="card-description" style={{ marginBottom: 25 }}>
                    Contract
                  </p>
                </div>
              </div>
              {state?.contract && <div className="expand-icon">
                <img
                  style={{ height: 26, width: 26 }}
                  onClick={() => {
                    setOpenModal(true);
                  }}
                  src={trash}
                  alt={"item"}
                  className="card-image"
                />
                <img
                  onClick={handleClick}
                  src={expand}
                  alt={"item"}
                  className="card-image"
                />
                <img
                  style={{ height: 22, width: 22 }}
                  onClick={() => {
                    navigate(`/contracts/${business}`);
                  }}
                  src={close}
                  alt={"item"}
                  className="card-image"
                />
              </div>}
            </div>
            <p className="proposal-card-title" style={{ fontSize: 24 }}>
              {state?.contract?.title}
            </p>
            <div className="quill-editor">
              <ReactQuill value={state?.contract?.body} readOnly />
              <div style={{ display: "flex", justifyContent: "flex-end", marginTop: 30 }}>
                {state?.contract && <button className="template-button" style={{ marginRight: 0 }} onClick={handleClick}>View Contract</button>}
              </div>
            </div>
          </div>
        </div>
      </div>
      <AppModal
        modalIsOpen={openModal}
        deleteRecord
        onClick={handleDelete}
        closeModal={() => {
          setOpenModal(false);
        }}
      />
    </div>
  )
}

export default Details
