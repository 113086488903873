import styled from "@emotion/styled";
import plus from "../../../assets/plus-blue.png"
import tick from "../../../assets/done-all.png"
import { Button, Grid } from "@mui/material";
import { ReactComponent as FormIcon } from "../../../assets/icon-form.svg"
import { useEffect, useState } from "react";
import { request } from "../../../utils/http";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import AppModal from "../../../components/Modal";

const StyledButton = styled(Button)({
    background: "#0F579626",
    color: "#0F5796",
    borderRadius: "14px",
    textTransform: "none",
    marginRight: "100px",
    paddingRight: "100px",
    paddingLeft: "20px",
    height: "46px"
})

const OnboardingForm = () => {
    const [details, setDetails] = useState(null);
    const [questions, setQuestions] = useState([]);
    const location = useLocation();
    const [sendFormOpen, setSendFormOpen] = useState(false);
    const [showSendForm, setShowSendForm] = useState(false);

    useEffect(() => {
        request.get(`/clients/api/v1/clients/${location.pathname.split("/")[3]}/`)
            .then(({ data }) => {
                setDetails(data);
            });
        request.get(`/clients/api/v1/clients/on-boarding/questioner/${location.pathname.split("/")[3]}/`)
            .then(({ data }) => {
                if (data.length)
                {
                    setQuestions(data.map(x => x.questions));
                    setShowSendForm(true);
                }
                else
                    setQuestions([""]);
            })
            .catch(() => {
                setQuestions([""]);
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleAdd = () => {
        questions[questions.length] = "";
        setQuestions([...questions]);
    }

    const handleQuestion = (id) => ({ target: { value } }) => {
        questions[id] = value;
        setQuestions([...questions]);
    }

    const handleSave = () => {
        request.post("/clients/api/v1/clients/on-boarding/questioner/", {
            client: location.pathname.split("/")[3],
            questions
        })
            .then(() => {
                setShowSendForm(true);
                toast.success("Saved Successfully");
            });
    }

    return <div>
        <div
            style={{
                display: "flex",
                justifyContent: "space-between"
            }}>
            <div>
                <div
                    style={{
                        color: "#464255",
                        fontSize: "24px",
                        fontWeight: 500
                    }}>
                    Create Onboarding Screen
                </div>
                <div
                    style={{
                        color: "#A3A3A3",
                        fontSize: "14px",
                        marginTop: 20
                    }}>
                    Client information
                </div>
            </div>
            {showSendForm && <StyledButton
                startIcon={<FormIcon />}
                onClick={()=> setSendFormOpen(true)}
                sx={{ whiteSpace: "nowrap" }}
            >
                Send Form
            </StyledButton>}
        </div>
        <div style={{
            borderRadius: "8px",
            background: "#FFF",
            marginTop: "20px",
            marginLeft: "280px",
            marginRight: "100px",
            padding: "80px 60px"
        }}>
            <Grid
                container
                style={{
                    alignItems: "center",
                    marginBottom: "24px",
                    width: "85%"
                }}>
                <Grid item xs={4}>
                    <label>
                        Client Name:
                    </label>
                </Grid>
                <Grid item xs={8}>
                    <input
                        style={{ width: "120%" }}
                        value={details?.name || ""}
                        placeholder="Enter name"
                        readOnly
                    />
                </Grid>
            </Grid>
            <Grid
                container
                style={{
                    alignItems: "center",
                    marginBottom: "24px",
                    width: "85%"
                }}>
                <Grid item xs={4}>
                    <label>
                        Client email:
                    </label>
                </Grid>
                <Grid item xs={8}>
                    <input
                        style={{ width: "120%" }}
                        value={details?.email || ""}
                        placeholder="Enter email"
                        readOnly
                    />
                </Grid>
            </Grid>
            <Grid
                container
                style={{
                    alignItems: "center",
                    marginBottom: "24px",
                    width: "85%"
                }}>
                <Grid item xs={4}>
                    <label>
                        Client Contact Phone:
                    </label>
                </Grid>
                <Grid item xs={8}>
                    <input
                        style={{ width: "120%" }}
                        value={details?.phone || ""}
                        placeholder="Enter Contact Phone"
                        readOnly
                    />
                </Grid>
            </Grid>
            <Grid
                container
                style={{
                    alignItems: "center",
                    marginBottom: "24px",
                    width: "85%"
                }}>
                <Grid item xs={4}>
                    <label>
                        Number of Spokesperson:
                    </label>
                </Grid>
                <Grid item xs={8}>
                    <input
                        style={{ width: "120%" }}
                        value={details?.rundown_txt || ""}
                        placeholder="Enter Spokesperson"
                        readOnly
                    />
                </Grid>
            </Grid>
            <Grid
                container
                style={{
                    alignItems: "center",
                    width: "85%"
                }}>
                <Grid item xs={4}>
                    <label>
                        City/Zip/State:
                    </label>
                </Grid>
                <Grid container item xs={8} justifyContent="space-between">
                    <Grid item xs={4}>
                        <input
                            value={details?.city || ""}
                            placeholder="City"
                            readOnly
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <input
                            style={{
                                marginLeft: "30%"
                            }}
                            value={details?.zip || ""}
                            placeholder="Zip"
                            readOnly
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <input
                            style={{
                                marginLeft: "60%"
                            }}
                            value={details?.state || ""}
                            placeholder="State"
                            readOnly
                        />
                    </Grid>
                </Grid>
            </Grid>
        </div>
        <hr
            style={{
                margin: "60px 100px 40px 280px",
                border: "0.5px solid #AAAAAA80"
            }}
        />
        <div
            style={{
                color: "#464255",
                fontSize: "24px",
                fontWeight: 500
            }}>
            Onboarding Questionary
        </div>
        <div
            style={{
                color: "#A3A3A3",
                fontSize: "14px",
                marginTop: 20
            }}>
            Enter all questions
        </div>
        <div style={{
            borderRadius: "8px",
            background: "#FFF",
            marginTop: "20px",
            marginLeft: "280px",
            marginRight: "100px",
            padding: "80px 60px"
        }}>
            <img
                style={{
                    cursor: "pointer",
                    marginLeft: "99%",
                    marginBottom: "24px"
                }}
                src={plus}
                alt="Add Question"
                onClick={handleAdd}
            />
            {questions.map((item, i) =>
                <input
                    key={i}
                    style={{
                        marginLeft: "28%",
                        marginBottom: i === questions.length - 1 ? "0px" : "30px",
                        width: "68.2%"
                    }}
                    value={item}
                    onChange={handleQuestion(i)}
                    placeholder="Enter your question"
                />
            )}
        </div>
        <button
            style={{
                backgroundColor: "#0F579626",
                borderRadius: "8px",
                padding: "10px 16px",
                color: "#0F5796",
                marginTop: "20px",
                marginLeft: "85.6%",
                fontSize: "14px"
            }}
            onClick={handleSave}
            className="template-button"
        >
            <img
                alt="Save"
                src={tick}
                style={{ width: 16, height: 16, marginRight: 8 }}
            />
            Save
        </button>
        <AppModal
            modalIsOpen={sendFormOpen}
            sendOnboardingForm
            values={{
                email: details?.email,
                id: details?.id
            }}
            closeModal={() => {
                setSendFormOpen(false);
            }}
        />
    </div>
}

export default OnboardingForm;