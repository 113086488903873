import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import React from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import calendar2 from "../../../../assets/bi_calendar2-week.png"

const CalendarSelection = ({ setOpenDatepicker, onDateSelect, value, selectedDateInfo, openDatepicker }) => {
    return (
        <div style={{ marginBottom: '10px' }}>
            <span style={{ fontSize: '24px', fontWeight: 'bold' }}>
                {selectedDateInfo}
            </span>
            <span style={{ marginLeft: "20px", cursor: 'pointer' }}>
                <span style={
                    {
                        padding: '10px 12px',
                        backgroundColor: 'white',
                        borderRadius: '10px',
                        border: '1px solid #F3F3F3',
                        display: 'inline-block'
                    }
                }>
                    <img src={calendar2} alt='Calendar Icon' onClick={() => setOpenDatepicker(true)} />
                </span>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                        open={openDatepicker}
                        onOpen={() => setOpenDatepicker(true)}
                        onClose={() => setOpenDatepicker(false)}
                        label="Controlled picker"
                        value={value}
                        onChange={onDateSelect}
                        sx={{
                            '&': {
                                visibility: 'hidden'
                            }
                        }}
                    />
                </LocalizationProvider>
            </span>
        </div>
    )
}

export default CalendarSelection;