import React from "react"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import Paper from "@mui/material/Paper"

const ReportTable = ({ reportList, onSeeDetails }) => {
  return (
    <>
      <div className="business-management-header">
        <div className="header-content"></div>
      </div>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell
                align="left"
                sx={{ paddingLeft: "4rem", fontWeight: "600" }}
              >
                Report title
              </TableCell>
              <TableCell align="center" sx={{ fontWeight: "600" }}>
                Week
              </TableCell>
              <TableCell align="center" sx={{ fontWeight: "600" }}>
                Month
              </TableCell>
              <TableCell align="center" sx={{ fontWeight: "600" }}>
                Year
              </TableCell>
              <TableCell align="center" sx={{ fontWeight: "600" }}>
                See details
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {reportList?.map((row) => (
              <TableRow
                key={row.id}
                sx={{
                  cursor: "pointer",
                  td: { cursor: "pointer" },
                  "&:last-child td, &:last-child th": { border: 0 }
                }}
                hover
              >
                <TableCell
                  align="left"
                  sx={{ width: "30%", paddingLeft: "4rem" }}
                >
                  {row.name}
                </TableCell>
                <TableCell align="center" sx={{ width: "20%" }}>
                  {row.week}
                </TableCell>
                <TableCell align="center" sx={{ width: "20%" }}>
                  {row.month}
                </TableCell>
                <TableCell align="center" sx={{ width: "15%" }}>
                  {row.year}
                </TableCell>
                <TableCell align="center" sx={{ width: "15%" }} onClick={() => onSeeDetails(row.created_at)}>
                  ...
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}

export default ReportTable
