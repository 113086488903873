import React from "react"
//import { usePagination } from './usePagination';
import prevIcon from "../../assets/pageleft.png"
import nextIcon from "../../assets/pageright.png"
import "./pagination.css"
import usePagination from "@mui/material/usePagination/usePagination"
const Pagination = ({
  onPageChange,
  totalCount,
  siblingCount,
  currentPage,
  hideTotalCount,
  totalPages,
  removeTopMargin,
  appendText,
  centeredPagination,
  pageSize
}) => {
  const handlePageChange = (e, page) => {
    onPageChange(page)
  }

  const { items } = usePagination({
    page: currentPage,
    count: totalPages,
    defaultPage: 1,
    onChange: handlePageChange
  })

  return (
    <div style={removeTopMargin ? {width:'100%'} : { marginTop: "20px" }}>
      <div className={ !centeredPagination ? "pagination-container" : ''}>
        {!hideTotalCount ? (
          <p style={{ color: "#0F5796", fontSize: 16 }}>
            Showing <span style={{fontWeight:'bold'}}>{currentPage > parseInt(totalCount / pageSize) ? totalCount % pageSize : pageSize}</span> out of <span style={{fontWeight:'bold'}}>{totalCount} {" "+appendText ? appendText : ''}</span>
          </p>
        ) : null}
        <div className={centeredPagination ? 'centered-pagination' :"pagination"}>
          {items.map(({ page, type, selected, ...item }, index) => {
            let content = null

            if (type === "start-ellipsis" || type === "end-ellipsis") {
              content = (
                <span key={type} className="dots">
                  ...
                </span>
              )
            } else if (type === "page") {
              content = (
                <span
                  key={page}
                  className={`page-number ${selected ? "current-page" : ""}`}
                  {...item}
                >
                  {page}
                </span>
              )
            } else {
              content = (
                !item.disabled ? <img
                  src={type === "next" ? nextIcon : prevIcon}
                  {...item}
                  className="icon"
                  alt={type}
                  key={type}
                /> :
                <img
                  src={type === "next" ? nextIcon : prevIcon}
                  className="icon"
                  alt={type}
                  key={type}
                />
              )
            }

            return content
          })}
        </div>
      </div>
    </div>
  )
}

export default Pagination
