export const GET_CLIENTS = "CLIENTS/GET_CLIENTS"
export const GET_CLIENTS_SUCCESS = "CLIENTS/GET_CLIENTS_SUCCESS"
export const GET_CLIENTS_ERROR = "CLIENTS/GET_CLIENTS_ERROR"

export const GET_PRESS_RELEASE_TEMPLATES = "CLIENTS/PRESS_RELEASE_TEMPLATES"
export const GET_PRESS_RELEASE_TEMPLATES_SUCCESS =
  "CLIENTS/PRESS_RELEASE_TEMPLATES_SUCCESS"
export const GET_PRESS_RELEASE_TEMPLATES_ERROR =
  "CLIENTS/PRESS_RELEASE_TEMPLATES_ERROR"

export const POST_PRESS_RELEASE_TEMPLATES =
  "CLIENTS/POST_PRESS_RELEASE_TEMPLATES"
export const POST_PRESS_RELEASE_TEMPLATES_SUCCESS =
  "CLIENTS/POST_PRESS_RELEASE_TEMPLATES_SUCCESS"
export const POST_PRESS_RELEASE_TEMPLATES_ERROR =
  "CLIENTS/POST_PRESS_RELEASE_TEMPLATES_ERROR"
