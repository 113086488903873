import React, { useEffect, useState } from "react"
import Modal from "react-modal"
import { colors } from "../utils/colors"
import close from "../assets/close-white.png"
import send from "../assets/send-white.png"
import later from "../assets/draft.png"
import tag from "../assets/tag-yellow.png"
import expand from "../assets/expand.png"
import upload from "../assets/upload-file.png"
import fileDownload from '../assets/file-download-icon.png';
import { ReactComponent as DownloadIcon } from "../assets/icon-download.svg"
import { ReactComponent as CalendarIcon } from "../assets/icon-calendar.svg"
import { ReactComponent as CalendarIcon2 } from "../assets/icon-calendar-clear-outline.svg"
import { ReactComponent as SaveIcon } from "../assets/icon-save.svg"
import { getPrivacyPolicy, getTermsAndCondition } from "../redux/app/sagas"
import { Box, Stack, Typography } from "@mui/material";
import Grid from '@mui/material/Grid';
import { validateEmail } from "../utils/helpers"
import { toast } from "react-toastify"
import { request } from "../utils/http"
import Dropzone from "react-dropzone"
import ReactQuill from "react-quill"
import styled from "@emotion/styled"
import { Button , TextField} from "@mui/material"
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import dayjs from 'dayjs';
import { ReportStatus } from "../screens/Reports/ReportsConst"

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    padding: "0px",
    marginRight: "-50%",
    borderRadius: 10,
    backgroundColor: "#0F5796",
    transform: "translate(-50%, -50%)",
  },
  overlay: {
    background: "rgba(15, 87, 150, 0.2)",
    zIndex: "999"
  }
}

const StyledButton = styled(Button)({
  background: "#0F579626",
  color: "#0F5796",
  borderRadius: "14px",
  textTransform: "none",
  padding: "12px 40px"
})

function AppModal({
  modalIsOpen,
  afterOpenModal,
  closeModal,
  values,
  onLogout,
  onClick,
  proposal,
  contract,
  exportReport,
  reportExportedSuccess,
  editReportEvent,
  downoadMediaReport,
  deleteRecord,
  uploadFile,
  sendOnboardingForm,
  viewOnboardingForm,
  data,
  termsAndCondition,
  privacyPolicy,
  reset,
  signup
}) {
  const [email, setEmail] = useState(values?.email || "");
  const [cc, setCC] = useState("");
  const [file, setFile] = useState(null);
  const [isDragging, setDragging] = useState(false);
  const [privacyPolicyData, setPrivacyPolicyData] = useState("");
  const [termsAndConditionData, setTermsAndConditionData] = useState("");

  const [editReport, setEditReport] = useState({
    task_title: data?.value?.title || '',
    task_description: data?.value?.body?.description || '',
    start_date: dayjs(data?.value?.start?.toDate() || '') ,
    end_date: dayjs(data?.value?.end?.toDate() || '') ,
    status: data?.value?.body?.status || false,
  })

  const onChangeEditReport = (e) =>{
    const value = e.target.value;
    const name = e.target.name
    setEditReport((report)=> ({...report,[name]:value}))
  }

  const onChangeDescription  = (value) =>{
    setEditReport((report)=> ({...report,['task_description']:value}))
  }

  const onDateChange = (field,e) =>{
    setEditReport((report)=> ({...report,[field]:e}))
  }

  const onEditReportSave = () =>{
    const id = data?.value?.id;
    if(!editReport.task_title?.trim()){
      toast.error(`Task title is required`);
      return;
    }
    if(editReport.start_date.isAfter(editReport.end_date)){
      toast.error(`Start Date can not be greater than end date`);
      return;
    }
    const payload = {
      ...editReport,
      start_date: editReport.start_date.toDate().toISOString(),
      end_date:editReport.end_date.toDate().toISOString()
    }
    request.patch(`/task/api/v1/${id}/`,payload, {
      params:{
        client:data.clientId
      }
    })
      .then(() => {
        toast.success(`Task has been updated successfully.`);
        data.updateEvent();
        closeModal();
      }).catch(err=>{
        toast.error(`Failed to update task.`);
      });
  }

  const onReportExportToUser = () =>{
    request
    .post(`/task/api/v1/export-pdf/`,{
      html:data.value
    })
    .then(({ _ }) => {
      data.onExport();
    }).catch(err=>{
        toast.error("Failed to export data to gmail.")
    });
  }
  
  useEffect(() => {
    if (contract)
      request.get(`/contracts/api/v1/contract/email-draft/${values.id}/`)
        .then(({ data }) => {
          setEmail(data?.to || values.email);
          setCC(data?.cc || "");
        });
    else if (proposal)
      request.get(`/proposals/api/v1/proposal/email-draft/${values.id}/`)
        .then(({ data }) => {
          setEmail(data?.to || values.email);
          setCC(data?.cc || "");
        });
  }, [values, proposal, contract]);

  useEffect(() => {
    setEmail(values?.email || '');
    setCC("");
  }, [values?.email])

  useEffect(()=>{
    setEditReport({
      task_title: data?.value?.title || '',
      task_description: data?.value?.body?.description || '',
      start_date: dayjs(data?.value?.start?.toDate() || '') ,
      end_date: dayjs(data?.value?.end?.toDate() || '') ,
      status: data?.value?.body?.status || false,
    })
  },[data?.value])

  const handleEmail = e => {
    setEmail(e.target.value)
  }

  const handleCC = e => {
    setCC(e.target.value)
  }

  if (termsAndCondition || privacyPolicy) {
    customStyles.content = {
      ...customStyles.content,
      maxWidth: "80%",
      maxHeight: "80%",
    }
  }

  if (exportReport | reportExportedSuccess) {
    customStyles.content = {
      ...customStyles.content,
      backgroundColor: "white"
    }
  }

  const handleClick = (flag) => () => {
    if (validateEmail(email) && (!cc || validateEmail(cc))) {
      if (contract)
        request.post("/contracts/api/v1/contract/sent/", {
          contract_id: values.id,
          to: [email],
          cc: cc && [cc],
          draft: flag
        })
          .then(() => {
            toast.success(`Email has been ${flag ? "saved" : "sent"}.`);
            closeModal();
          });
      else
        request.post("/proposals/api/v1/proposal/sent/", {
          proposal_id: values.id,
          to: [email],
          cc: cc && [cc],
          draft: flag
        })
          .then(() => {
            toast.success(`Email has been ${flag ? "saved" : "sent"}.`);
            closeModal();
          });
    }
    else
      toast.error("Email address is invalid.");
  }

  const handleSendForm = () => () => {
    if (validateEmail(email) && (!cc || validateEmail(cc)))
      request.post("/clients/api/v1/clients/on-boarding/sent/", {
        client_id: values.id,
        to: [email],
        cc: cc ? [cc] : [],
      })
        .then(() => {
          toast.success(`Onboarding form has been sent.`);
          closeModal();
        }).catch(err => {
          toast.error("Onboarding form is already send.")
        })
    else
      toast.error("Email address is invalid.");
  }

  const handleUpload = (file) => {
    if (file.length)
      setFile(file[0]);
    else
      toast.error("Please select a valid PDF file.");
    setDragging(false);
  }

  useEffect(() => {
    if (privacyPolicy) {
      getPrivacyPolicy()
        .then(privacyPolicyResp => {
          const privacyData = privacyPolicyResp.data?.results;
          let result;
          privacyData.forEach(data => {
            if (!result) {
              result = data.body;
            } else {
              result += "\n" + data.body
            }
          })
          setPrivacyPolicyData(result)
        }).catch((err) => {
          console.log(err)
        })
    } else if (termsAndCondition) {
      getTermsAndCondition().then(termsAndConditionResp => {
        const termsConditionData = termsAndConditionResp.data?.results;
        let result;
        termsConditionData.forEach(data => {
          if (!result) {
            result = data.body;
          } else {
            result += "\n" + data.body
          }
        })
        setTermsAndConditionData(result)
      }).catch((err) => {
        console.log(err)
      })
    }
  }, [privacyPolicy, termsAndCondition])

  return (
    <div>
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        ariaHideApp={false}
        style={customStyles}
        contentLabel="Modal"
      >


        {reset ? (
          <div style={{
            backgroundColor: "#0F5796",
            padding: 20,
            paddingBottom: "10%",
            paddingTop: "10%"

          }}>
            <div style={{
              maxWidth: 500
            }}>
              <h3 style={{
                textAlign: "center",
                color: colors.white,
                fontSize: 40,
                margin: 5
              }}>Reset password</h3>
              <p style={{
                textAlign: "center",
                color: colors.white,
                fontSize: 16,
                marginBottom: "10%"

              }}>We send you an email with verification link!
                Please check {values}</p>
              <button onClick={closeModal}
                style={{
                  display: "block",
                  width: "100%",
                  height: 80,
                  border: "none",
                  //  padding: 10px;
                  backgroundColor: '#fff',
                  color: "#0F5796",
                  fontSize: 26,
                  cursor: 'pointer'
                  // margin-top: 10%;
                  // border: none;
                  // border-radius: 3px;
                  // cursor: pointer;
                }}
              >Continue</button>
            </div>
          </div>
        ) : null}
        {proposal ? (
          <>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                backgroundColor: "#0F5796",
                padding: 20,
                borderTopRightRadius: 20,
                borderTopLeftRadius: 20
              }}
            >
              <p
                className="proposal-card-title"
                style={{ fontSize: 24, color: "#fff" }}
              >
                {contract ? "Send Contract" : "Send Proposal"}
              </p>
              <img
                src={close}
                onClick={closeModal}
                alt="Button 1"
                style={{ width: 20, height: 20 }}
              />
            </div>
            <div style={{ backgroundColor: "#fff", padding: 20 }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginTop: 20
                }}
              >
                <p
                  className="proposal-card-title"
                  style={{ fontSize: 16, color: "#656565", marginRight: 20 }}
                >
                  Email:
                </p>
                <div className="search-dropdown">
                  <div className="input-container">
                    <input type="email" placeholder="clientemail@email.com" value={email} onChange={handleEmail} />
                  </div>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginTop: 20
                }}
              >
                <p
                  className="proposal-card-title"
                  style={{ fontSize: 16, color: "#656565", marginRight: 20 }}
                >
                  CC:
                </p>
                <div className="search-dropdown">
                  <div className="input-container">
                    <input type="email" placeholder="Additional email" value={cc} onChange={handleCC} />
                  </div>
                </div>
              </div>

              <div style={{}}>
                <div className="proposal-card" style={{ width: 480, padding: 20, marginLeft: 66, marginTop: 40, boxShadow: " 3px 3px 7px rgba(0, 0, 0, 0.5)" }}>
                  <div className="proposal-content">
                    <Stack direction="row" spacing={2}>
                      <div style={{ display: "flex", width: "500px" }}>
                        {values.image ? (
                          <img
                            src={values.image}
                            alt="template-icon"
                            style={{
                              width: "60px",
                              height: "60px",
                              objectFit: "contain",
                              borderRadius: "6px"
                            }}
                          />
                        ) : (
                          <div className="proposal-image-container" style={{ height: 50 }}>
                            {values.title && (
                              <div className="thumbnail">
                                {values.title.charAt(0).toUpperCase()}
                              </div>
                            )}
                          </div>
                        )}
                        <Typography
                          sx={{
                            marginLeft: "20px",
                            marginTop: "6px",
                            color: "#464255",
                            fontWeight: "500",
                            fontSize: "1.125rem"
                          }}
                        >
                          {values.title}
                        </Typography>
                        <img
                          style={{
                            marginLeft: "auto"
                          }}
                          src={expand}
                          alt="expand"
                          className="card-image"
                          onClick={values.handleClick}
                        />
                      </div>
                    </Stack>
                  </div>
                  <div className="detail-subtext">
                    <Typography
                      dangerouslySetInnerHTML={{ __html: `<p>${values.body.replace(/<[^>]*>/g, '')}</p>` }}
                      sx={{
                        height: "40px",
                        maxHeight: "4rem",
                        overflow: 'hidden',
                        lineHeight: 'normal',
                        fontSize: '14px',
                        "& p": { margin: "8px 0", fontSize: "14px", color: "#A3A3A3" }
                      }}
                    />
                  </div>
                  {values.tag && <div className="proposal-buttons">
                    <button className="industry-button">
                      <img
                        src={tag}
                        alt={"name"}
                        style={{ width: 24, height: 18, marginRight: 10 }}
                      />
                      {values.tag}
                    </button>
                  </div>}
                </div>

              </div>
              <div style={{ display: "flex", justifyContent: "space-between", marginTop: 30 }}>
                <button
                  className="template-button"
                  onClick={handleClick(true)}
                  style={{ backgroundColor: "#fff", color: "#0F5796" }}
                >
                  <img
                    src={later}
                    alt="Button 1"
                    style={{ width: 18, height: 18, marginRight: 5, marginTop: 20 }}
                  />
                  Save for later
                </button>
                <button
                  className="template-button"
                  onClick={handleClick(false)}
                  style={{ backgroundColor: "#0F5796", color: "#fff", height: 40, marginTop: 20 }}
                >
                  <img
                    src={send}
                    alt="Button 1"
                    style={{ width: 14, height: 14, marginRight: 5 }}
                  />
                  Send
                </button>
              </div>
            </div>
          </>
        ) : null}
        {signup ? (
          <div style={{
            backgroundColor: "#0F5796",
            padding: 20,
            paddingBottom: "10%",
            paddingTop: "10%"

          }}>
            <div style={{
              maxWidth: 500
            }}>
              <h3 style={{
                textAlign: "center",
                color: colors.white,
                fontSize: 40,
                margin: 5
              }}>Verification Required</h3>
              <p style={{
                textAlign: "center",
                color: colors.white,
                fontSize: 16,
                marginBottom: "10%"

              }}>We send you an email with verification link!
                Please check {values.email}</p>
              <button onClick={closeModal}
                style={{
                  display: "block",
                  width: "100%",
                  height: 80,
                  cursor: "pointer",
                  //  padding: 10px;
                  backgroundColor: "#fff",
                  color: "#0F5796",
                  fontSize: 26,
                  // margin-top: 10%;
                  // border: none;
                  // border-radius: 3px;
                  // cursor: pointer;
                }}
              >Continue</button>
            </div>
          </div>
        ) : null}
        {onLogout ? (
          <>
            <h3
              style={{
                textAlign: "center",
                color: colors.main
              }}
              onClick={closeModal}
            >
              Sign Out
            </h3>

            <div style={{ marginBottom: 20 }}>
              <p>Are you sure you want to Sign Out?</p>
            </div>

            <div style={{ marginTop: 20, alignSelf: "center" }}>

              <h4
                style={{
                  textAlign: "center",
                  color: colors.main
                }}
                onClick={closeModal}
              >
                Cancel
              </h4>
            </div>
          </>
        ) : null}
        {termsAndCondition ? (
          <>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                backgroundColor: "#0F5796",
                padding: 20,
                width: "700px",
                borderTopRightRadius: 20,
                borderTopLeftRadius: 20
              }}
            >
              <p
                className="proposal-card-title"
                style={{ fontSize: 24, color: "#fff" }}
              >
                {"Terms and Conditions"}
              </p>
              <img
                src={close}
                onClick={closeModal}
                alt="Button 1"
                style={{ width: 20, height: 20, cursor: "pointer" }}
              />
            </div>
            <div style={{ backgroundColor: "#fff", padding: 20 }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginTop: 20
                }}
              >
                <p
                  className="proposal-card-title"
                  style={{ fontSize: 16, color: "#0F5796", marginRight: 20 }}
                >
                  {termsAndConditionData}
                </p>
              </div>
              <div style={{ display: "flex", justifyContent: "end", marginTop: 30 }}>
                <button
                  className="template-button"
                  onClick={closeModal}
                  style={{ backgroundColor: "#0F5796", color: "#fff", height: 40, marginTop: 20 }}
                >
                  {/* <img
                    src={send}
                    alt="Button 1"
                    style={{ width: 14, height: 14, marginRight: 5 }}
                  /> */}
                  Cancel
                </button>
              </div>
            </div>
          </>
        ) : null}
        {privacyPolicy ? (
          <>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                backgroundColor: "#0F5796",
                padding: 20,
                width: "700px",
                borderTopRightRadius: 20,
                borderTopLeftRadius: 20
              }}
            >
              <p
                className="proposal-card-title"
                style={{ fontSize: 24, color: "#fff" }}
              >
                {"Privacy Policy"}
              </p>
              <img
                src={close}
                onClick={closeModal}
                alt="Button 1"
                style={{ width: 20, height: 20, cursor: "pointer" }}
              />
            </div>
            <div style={{ backgroundColor: "#fff", padding: 20 }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginTop: 20
                }}
              >
                <p
                  className="proposal-card-title"
                  style={{ fontSize: 16, color: "#0F5796", marginRight: 20 }}
                >
                  {privacyPolicyData}
                </p>
              </div>
              <div style={{ display: "flex", justifyContent: "end", marginTop: 30 }}>
                <button
                  className="template-button"
                  onClick={closeModal}
                  style={{ backgroundColor: "#0F5796", color: "#fff", height: 40, marginTop: 20 }}
                >
                  {/* <img
                    src={send}
                    alt="Button 1"
                    style={{ width: 14, height: 14, marginRight: 5 }}
                  /> */}
                  Cancel
                </button>
              </div>
            </div>
          </>
        ) : null}
        {downoadMediaReport
          ? <>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                backgroundColor: "#0F5796",
                padding: "13px 20px 10px 20px",
                width: "550px",
                borderTopRightRadius: 20,
                borderTopLeftRadius: 20
              }}
            >
              <p
                className="proposal-card-title"
                style={{ fontSize: 24, color: "#fff" }}
              >
                {"Download"}
              </p>
              <img
                src={close}
                onClick={closeModal}
                alt="Button 1"
                style={{ width: 20, height: 20, cursor: "pointer" }}
              />
            </div>
            <div style={{ backgroundColor: "#fff", padding: 20, fontSize: '14px', overflowY: 'auto', height: '200px' }}>
              <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} style={{ marginBottom: '30px' }}>
                <img src={fileDownload} alt="File Icon" width="28px" height="30px" style={{ marginLeft: "10px" }} />
                <div>Media Report 1</div>
                <div>
                  <span>100 MB</span>
                  <DownloadIcon style={{ cursor: 'pointer', marginLeft: '50px', marginBottom: '-2px', width: '18px', height: '18px' }} />
                </div>
              </Box>
              <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} style={{ marginBottom: '30px' }}>
                <img src={fileDownload} alt="File Icon" width="28px" height="30px" style={{ marginLeft: "10px" }} />
                <div>Media Report 1</div>
                <div>
                  <span>100 MB</span>
                  <DownloadIcon style={{ cursor: 'pointer', marginLeft: '50px', marginBottom: '-2px', width: '18px', height: '18px' }} />
                </div>
              </Box>
              <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} style={{ marginBottom: '30px' }}>
                <img src={fileDownload} alt="File Icon" width="28px" height="30px" style={{ marginLeft: "10px" }} />
                <div>Media Report 1</div>
                <div>
                  <span>100 MB</span>
                  <DownloadIcon style={{ cursor: 'pointer', marginLeft: '50px', marginBottom: '-2px', width: '18px', height: '18px' }} />
                </div>
              </Box>
            </div>
          </>
          : null}
        {deleteRecord
          ? <>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                backgroundColor: "#0F5796",
                padding: 20,
                width: "550px",
                borderTopRightRadius: 20,
                borderTopLeftRadius: 20
              }}
            >
              <p
                className="proposal-card-title"
                style={{ fontSize: 24, color: "#fff" }}
              >
                Delete
              </p>
              <img
                src={close}
                onClick={closeModal}
                alt="Button 1"
                style={{ width: 20, height: 20, cursor: "pointer" }}
              />
            </div>
            <div style={{ backgroundColor: "#fff", padding: "20px", fontSize: '18px' }}>
              <label>
                Are you sure?
              </label>
              <div style={{ display: "flex", justifyContent: "end" }}>
                <button
                  className="template-button"
                  style={{
                    backgroundColor: "#0F5796",
                    color: "#fff",
                    padding: "10px 30px",
                    marginTop: 20,
                  }}
                  onClick={onClick}
                >
                  Yes
                </button>
                <button
                  className="template-button"
                  style={{
                    backgroundColor: "#0F5796",
                    color: "#fff",
                    padding: "10px 30px",
                    marginTop: 20,
                    marginRight: "0px"
                  }}
                  onClick={closeModal}
                >
                  No
                </button>
              </div>
            </div>
          </>
          : null}
        {sendOnboardingForm
          ? <>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                backgroundColor: "#0F5796",
                padding: "13px 20px 10px 20px",
                width: "620px",
                borderTopRightRadius: 20,
                borderTopLeftRadius: 20
              }}
            >
              <p
                className="proposal-card-title"
                style={{ fontSize: 24, color: "#fff" }}
              >
                {"Send Form"}
              </p>
              <img
                src={close}
                onClick={closeModal}
                alt="Button 1"
                style={{ width: 20, height: 20, cursor: "pointer" }}
              />
            </div>
            <div style={{ backgroundColor: "#fff", padding: 20, fontSize: '14px', overflowY: 'auto', height: '250px', width: '620px' }}>
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={2}>
                  <div style={{ fontSize: '16px', color: '#656565' }}>Email:</div>
                </Grid>
                <Grid item xs={9}>
                  <input type="email" placeholder="clientemail@email.com" disabled value={email || ''} onChange={handleEmail} />
                </Grid>
                <Grid item xs={2}>
                  <div style={{ fontSize: '16px', color: '#656565' }}>CC:</div>
                </Grid>
                <Grid item xs={9}>
                  <input type="email" placeholder="Additional email" value={cc} onChange={handleCC} />
                </Grid>
                <Grid item xs={2}>
                </Grid>
                <Grid item xs={9}>
                  <div style={{ display: 'flex', alignItems: 'center', columnGap: '20px' }}>
                    <img src={fileDownload} alt="Download Icon" width={28} height={30} />
                    <span>Questionary Form (google doc)</span>
                  </div>
                </Grid>
              </Grid>
              <div style={{ display: "flex", justifyContent: "flex-end", marginTop: 30 }}>
                <button
                  className="template-button"
                  onClick={handleSendForm()}
                  style={{ backgroundColor: "#0F5796", fontSize: "14px", padding: '10px 60px', borderRadius: '15px', color: "#fff", height: 40, marginTop: 20 }}
                >
                  <img
                    src={send}
                    alt="Button 1"
                    style={{ width: 14, height: 14, marginRight: 5 }}
                  />
                  Send
                </button>
              </div>
            </div>
          </>
          : null}
        {editReportEvent
          ? <>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                backgroundColor: "#0F5796",
                padding: "13px 20px 10px 20px",
                width: "700px",
                borderTopRightRadius: 10,
                borderTopLeftRadius: 10
              }}
            >
              <p
                className="proposal-card-title"
                style={{ fontSize: 24, color: "#fff" }}
              >
                {"Edit task"}
              </p>
              <img
                src={close}
                onClick={closeModal}
                alt="Button 1"
                style={{ width: 20, height: 20, cursor: "pointer" }}
              />
            </div>
            <div className="edit-report-container" style={{ backgroundColor: "#fff", padding: 20, fontSize: '14px', overflowY: 'auto', height: '370px', width: '700px' }}>
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={3}>
                  <div style={{ fontSize: '16px', color: '#656565' }}>Title :</div>
                </Grid>
                <Grid item xs={8}>
                  <input type="text" placeholder="Title" value={editReport.task_title} name="task_title" onChange={onChangeEditReport}/>
                </Grid>
                <Grid item xs={3} style={{alignSelf:'flex-start'}}>
                  <div style={{ fontSize: '16px', color: '#656565' }}>Description :</div>
                </Grid>
                <Grid item xs={8}>
                    <ReactQuill value={editReport.task_description} onChange={onChangeDescription}/>
                  {/* <input type="text" placeholder="Description" value={editReport.task_description} name="task_description" onChange={onChangeEditReport}/> */}
                </Grid>
                <Grid item xs={3}>
                  <div style={{ fontSize: '16px', color: '#656565' }}>Time & Date :</div>
                </Grid>
                <Grid item xs={8}>
                  <div style={{ display: 'flex', columnGap: '30px' }}>
                    <div style={{ flex: 1, display:'flex' }}>
                    <input type="text" disabled style={{width:'40px', borderTopRightRadius:'0px', 
                      borderBottomRightRadius:"0px"}}
                      value={editReport.start_date.format("HH:mm")} />
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DateTimePicker
                            label=""
                            slots={{
                              openPickerIcon:CalendarIcon2
                            }}
                            onChange={onDateChange.bind(this,'start_date')}
                            value={editReport.start_date}
                            format="DD/MM/YYYY"
                            slotProps={{ textField: { 
                              placeholder: '00:00 | DD/MM/YYYY', 
                              disabled:true },popper:{placement:'top-start'}}}
                            sx={{
                              '& .MuiButtonBase-root': {
                                backgroundColor: 'rgb(15, 87, 150)', // Change text color
                                borderRadius:'10px'
                              },
                              '& .MuiInputBase-input': {
                                padding:'9px',
                                paddingTop:'10px',
                                fontSize:'13.3333px',
                                borderRadius:'0px'
                              },
                              '& .MuiInputBase-root':{
                                paddingRight:'8px',
                                borderRadius:'0px',
                                borderTopRightRadius:'10px',
                                borderBottomRightRadius:'10px',
                              },
                            }}
                          />
                        </LocalizationProvider>
                    </div>
                    <div style={{ flex: 1, display:"flex" }}>
                      <input type="text" disabled style={{width:'40px', borderTopRightRadius:'0px', 
                      borderBottomRightRadius:"0px"}}
                      value={editReport.end_date.format("HH:mm")} />
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DateTimePicker
                            label=""
                            onChange={onDateChange.bind(this,'end_date')}
                            value={editReport.end_date}
                            slots={{
                              openPickerIcon:CalendarIcon2,
                            }}
                            format="DD/MM/YYYY"
                            slotProps={{ textField: { placeholder: '00:00 | DD/MM/YYYY', disabled:true }, popper:{placement:'top-start'} }}
                            sx={{
                              '& .MuiButtonBase-root': {
                                backgroundColor: 'rgb(15, 87, 150)', // Change text color
                                borderRadius:'10px'
                              },
                              '& .MuiInputBase-input': {
                                padding:'9px',
                                paddingTop:'10px',
                                fontSize:'13.3333px',
                                borderRadius:'0px',
                              },
                              '& .MuiInputBase-root':{
                                paddingRight:'8px',
                                borderRadius:'0px',
                                borderTopRightRadius:'10px',
                                borderBottomRightRadius:'10px',
                              },
                            }}                            
                          />
                        </LocalizationProvider>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={3} style={{marginTop:'20px'}}>
                  <div style={{ fontSize: '16px', color: '#656565' }}>Status :</div>
                </Grid>
                <Grid item xs={3} style={{marginTop:'20px'}}>
                    <div style={{border:'1px solid #C5C5C5', borderRadius:'10px', padding:'10px 20px',cursor:'pointer', 
                    background: editReport.status === ReportStatus.Covered ? "rgba(15, 87, 150, 0.15)" : 'white'}}
                    onClick={ ()=>{
                      setEditReport({...editReport,status:ReportStatus.Covered});
                    }}>
                        <span style={{width:'12px',height:'12px',backgroundColor:'#28C66F', borderRadius:'50%',display:'inline-block'}}></span>
                        <span style={{marginLeft:'10px', fontSize:'14px'}}>Covered</span>
                    </div>
                </Grid>
                <Grid item xs={3} style={{marginTop:'20px'}}>
                    <div style={{border:'1px solid #C5C5C5', borderRadius:'10px', padding:'10px 20px',cursor:'pointer', 
                    background: editReport.status == ReportStatus.Uncovered ? "rgba(15, 87, 150, 0.15)" : 'white'}}
                    onClick={ ()=>{
                      setEditReport({...editReport,status:ReportStatus.Uncovered});
                    }}>
                        <span style={{width:'12px',height:'12px',backgroundColor:'#EC5453', borderRadius:'50%',display:'inline-block'}}></span>
                        <span style={{marginLeft:'10px', fontSize:'14px'}}>Uncovered</span>
                    </div>
                </Grid>
                <Grid item xs={3} style={{marginTop:'20px', cursor:'pointer'}} onClick={()=> data.exportToGmail(data.value.id)}>
                  <DownloadIcon />
                  <span style={{marginLeft:'10px', fontSize:'16px', color:'#0F5796'}}>Export to Gmail</span>
                </Grid>
              </Grid>
              <div style={{ display: "flex", justifyContent: "flex-end", marginTop: 30 }}>
                <button
                  onClick={onEditReportSave}
                  className="template-button"
                  style={{ background: "rgba(15, 87, 150, 0.15)", fontSize: "14px", padding: '10px 26px', borderRadius: '15px', color: "#fff", height: 40, marginTop: 20 }}
                >
                  <SaveIcon width={14} height={14} style={{marginRight:'10px'}} />
                  <span style={{color:"#0F5796"}}>Save</span>
                </button>
              </div>
            </div>
          </>
          : null}
        {viewOnboardingForm
          ? <>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                backgroundColor: "#0F5796",
                padding: "13px 20px 10px 20px",
                width: "620px",
                borderTopRightRadius: 20,
                borderTopLeftRadius: 20
              }}
            >
              <p
                className="proposal-card-title"
                style={{ fontSize: 24, color: "#fff" }}
              >
                {data.onboardingFormComplete ? "View Completed Form" : 'View Form'}
              </p>
              <img
                src={close}
                onClick={closeModal}
                alt="Button 1"
                style={{ width: 20, height: 20, cursor: "pointer" }}
              />
            </div>
            <div className="onboarding-completed-form"
              style={{ backgroundColor: "#fff", padding: 20, fontSize: '14px', overflowY: 'auto', height: '450px', width: '620px', }}>
              <div style={{ marginLeft: '10%' }} className="popup">
                <div className="header-content" style={{ marginBottom: 16 }}>
                  <div style={{ fontWeight: '500', fontSize: '20px' }}>Preview</div>
                  <div
                    style={{
                      color: "#A3A3A3",
                      fontSize: "14px",
                      marginTop: 16
                    }}
                  >
                    See details
                  </div>
                </div>
                <div className="header-content" style={{ marginBottom: 16, marginTop: '40px' }}>
                  <div style={{ fontWeight: '500', fontSize: '20px' }}>{data.onboardingFormComplete ? 'Completed' : 'View'} form</div>
                </div>
                <div className="quill-editor">
                  <ReactQuill readOnly value={data.onBoardingFormDetails} />
                  <div style={{ display: "flex", justifyContent: "flex-end", marginTop: 30 }}>
                    <button className="template-button" style={{ marginRight: '0px' }} onClick={data.onViewForm} >
                      {data.onboardingFormComplete ? "View Completed Form" : 'View Form'}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </>
          : null}
        {
          exportReport ? <>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: 'center',
                backgroundColor: "white",
                padding: "0px 20px 0px 20px",
                width: "820px",
                marginTop: '2rem',
                borderTopRightRadius: 20,
                borderTopLeftRadius: 20
              }}
            >
              <p
                className="proposal-card-title"
                style={{ fontSize: 24 }}
              >
                {data.reportType} report
              </p>
              <StyledButton
                onClick={onReportExportToUser}
                startIcon={<DownloadIcon />}
                sx={{ whiteSpace: "nowrap" }}
              >
                Export to Gmail
              </StyledButton>
              </div>
              <div className="export-report"
              style={{ backgroundColor: "#fff", padding: 20, paddingTop: 0, fontSize: '14px', overflowY: 'hidden', height: '450px', width: '820px', marginBottom:'20px' }}>
                  <div className="quill-editor">
                    <ReactQuill value={data.value} readOnly/>
                    <div style={{ display: "flex", justifyContent: "flex-end", marginTop: 30 }}>
                    </div>
                </div>
              </div>
          </>
            : null
        }
        {
          reportExportedSuccess ? <>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: 'center',
                backgroundColor: "white",
                padding: "0px 20px 0px 20px",
                width: "420px",
                marginTop: '2rem',
                borderTopRightRadius: 20,
                borderTopLeftRadius: 20
              }}
            >
            </div>
            <div className="export-report"
              style={{ backgroundColor: "#fff", padding: 20, paddingTop: 0, fontSize: '14px', overflowY: 'hidden', height: '130px', width: '420px', marginBottom: '20px' }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <CalendarIcon width="100" height="70" />
                <div>
                  <div style={{ color: '#0F5796', fontWeight: '600', fontSize: '18px' }}>{data.reportType} Report</div>
                  <div style={{ fontSize: '14px', marginTop: '5px' }}>{data.reportType} Report exported to your gmail address</div>
                </div>
              </div>
              <div style={{ display: "flex", justifyContent: "flex-end", marginTop: 30 }}>
                <button className="template-button" style={{ marginRight: '0px', padding: '16px 26px' }} onClick={closeModal} >
                  Close
                </button>
              </div>
            </div>
          </>
            : null
        }
        {uploadFile
          ? <>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                backgroundColor: "#0F5796",
                padding: 20,
                width: "550px",
                borderTopRightRadius: 20,
                borderTopLeftRadius: 20
              }}
            >
              <p
                className="proposal-card-title"
                style={{ fontSize: 24, color: "#fff" }}
              >
                Upload Signed Contract
              </p>
              <img
                src={close}
                onClick={closeModal}
                alt="Button 1"
                style={{ width: 20, height: 20, cursor: "pointer" }}
              />
            </div>
            <div style={{
              backgroundColor: "#fff",
              fontSize: '18px',
              padding: "30px 30px"
            }}>
              <Dropzone
                accept={{ "application/pdf": [".pdf"] }}
                multiple={false}
                onDrop={handleUpload}
                onDragEnter={() => { setDragging(true) }}
                onDragLeave={() => { setDragging(false) }}
              >
                {({ getRootProps }) => (
                  <div
                    {...getRootProps()}
                    style={{
                      backgroundColor: isDragging ? "#0F579652" : "#0F579626",
                      borderRadius: "10px",
                      color: "#0F5796",
                      cursor: "pointer",
                      display: "flex",
                      fontSize: "18px",
                      justifyContent: "center",
                      padding: "24px 0px",
                      width: "100%"
                    }}>
                    <img alt="File Upload" src={upload} />
                    <label style={{ cursor: "pointer", marginTop: 2, marginLeft: 10 }}>
                      Drag & drop or <strong>Browse</strong> file to upload!
                    </label>
                  </div>
                )}
              </Dropzone>
              <div style={{
                display: "flex",
                justifyContent: "end",
              }}>
                <button
                  className="template-button"
                  style={{
                    backgroundColor: "#0F5796",
                    color: "#fff",
                    padding: "10px 30px",
                    marginTop: 24,
                    marginRight: 0,
                  }}
                  onClick={onClick(file)}
                >
                  Upload
                </button>
              </div>
            </div>
          </>
          : null
        }
      </Modal>
    </div>
  )
}
export default AppModal
