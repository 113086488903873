
const Constants = {
  ACCESS_TOKEN: 'access_token',
  USER: 'user',
};


const StorageUtils = {
  getAccessToken: async () => {
    const token = await localStorage.getItem(Constants.ACCESS_TOKEN);
    return token;
  },

  setAccessToken: async (token) => {
    await localStorage.setItem(Constants.ACCESS_TOKEN, token);
  },

  removeAccessToken: async () => {
    await localStorage.removeItem(Constants.ACCESS_TOKEN);
  },

  getUser: async () => {
    const user = await localStorage.getItem(Constants.USER);
    return user ? JSON.parse(user) : {};
  },

  setUser: async (user) => {
    const userObject = user ? JSON.stringify(user) : null;
    await localStorage.setItem(Constants.USER, userObject);
  },

  removeUser: async () => {
    await localStorage.removeItem(Constants.USER);
  },

  getUserType: () =>{
    const user = localStorage.getItem(Constants.USER);
    const parseUser = user ? JSON.parse(user): {};
    const userType = parseUser?.user?.user_type;
    return userType;
  },
  getUserEmail: () =>{
    const user = localStorage.getItem(Constants.USER);
    const parseUser = user ? JSON.parse(user): {};
    const email = parseUser?.user?.email;
    return email;
  }

};

export default StorageUtils;