import {
  all, takeLatest, put, call,
} from 'redux-saga/effects';
import { toast } from "react-toastify"

import {
  AUTH_LOGIN_REQUEST,
  AUTH_LOGIN_ERROR,
  AUTH_SIGNUP_REQUEST,
  AUTH_PASSWORD_RECOVER_REQUEST,
  AUTH_LOGIN_SUCCESS,
  AUTH_SIGNUP_ERROR,
  AUTH_SIGNUP_SUCCESS,
  AUTH_PASSWORD_RECOVER_SUCCESS,
  AUTH_PASSWORD_RECOVER_ERROR,
  AUTH_LOGOUT,

} from './constants';
import { request, addTokenToHttp } from '../../utils/http';
import StorageUtils from '../../utils/storage';
import { getBusinessList, getProfile, getUserList } from '../app/actions';
import { APP_CLEAR_STATE } from '../app/constants';
import { getServerError } from '../../utils/helpers';
import { Redirect, useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import { landOnOnboardingForm } from '../app/sagas';


function sendLogin ({ values }) {
  return request.post('/users/auth/login/', values);
}

function sendSignUp ({ values }) {
  return request.post('/users/auth/signup/', values);
}

function sendPasswordRecovery (values) {
  // there is no reset password endpoint in backend, it's just a fake url
  return request.post('/users/auth/reset-password/', values);
}
export const  confirmNewPassword = (values) => {
  // there is no reset password endpoint in backend, it's just a fake url
  return request.post('/users/auth/reset-password/confirm/', values);
}

export const  addOnboardingDetails = (values) => {
  return request.post('/users/auth/onboarding-details/', values);
}

export const  uploadLogo = (values) => {
  return request.post('/users/auth/logo-upload/', values);
}


function *handleLogin ({ values, navigate }) {
  try {
    let navigateTo;
    let showOnBoradForm;
    const { status, data } = yield call(sendLogin, { values });
    const payload = {
      email: values.username
    };
    try {
      const { data } = yield call(landOnOnboardingForm,  payload );
      showOnBoradForm = !data?.onb_status
    } catch (err) {
      console.log(err);
    }
    navigateTo = showOnBoradForm ?  "/onboard" : "/";
    if (status === 200) {
      console.log(data)
      yield put({
        type: AUTH_LOGIN_SUCCESS,
        accessToken: data.key,
        user: data,
      });

      StorageUtils.setAccessToken(data.key);
      StorageUtils.setUser(data);
      addTokenToHttp(data.key);
      yield put(getProfile())
      yield put(getBusinessList())
      navigate(navigateTo);
    } else {
      yield put({
        type: AUTH_LOGIN_ERROR,
        error: 'Unknown Error',
      });
    }
  } catch (error) {
    const e = getServerError(error.response.data, 'Can\'t sign in with provided credentials');
    yield put({
      type: AUTH_LOGIN_ERROR,
      error: e,
    });
    const response = error?.response?.data?.error
    ?.replace('[','{')
    ?.replace(']','}')
    ?.replace('ErrorDetail(','')
    ?.replace(')','')
    ?.replaceAll('=',':')
    ?.replace("string","\'string\'")
    ?.replace("code","\'code\'")
    ?.replaceAll("'",'"');

    const parsedResponse = response && JSON.parse(response)?.['non_field_errors']?.['string'];
    toast.error(parsedResponse || e || 'Unable to log in with provided credentials');
  }
}


function *handleSignUp (values) {
  try {
    const { status } = yield call(sendSignUp, values);

    if (status === 201 || status === 200) {
      yield put({
        type: AUTH_SIGNUP_SUCCESS,
      });
      //yield put(getUserList())
      
    } else {
      yield put({
        type: AUTH_SIGNUP_ERROR,
        error: 'Unknown Error',
      });
    }
  } catch (error) {
    const e = getServerError(error.response.data, 'Can\'t sign up with provided credentials')
    yield put({
      type: AUTH_SIGNUP_ERROR,
      error: error.response?.data?.detail || JSON.stringify(error.response?.data) || 'Can\'t sign up with provided credentials',
    });
    alert(e)
  }
}


function *handlePasswordRecovery ({ values, navigation }) {
  try {
    const { status, data } = yield call(sendPasswordRecovery, values);

    if (status === 200) {
      yield put({
        type: AUTH_PASSWORD_RECOVER_SUCCESS,
        values,
      });
      // you can change the navigate for navigateAndResetStack to go to a protected route
      //alert(data.detail);
      //navigation.push("/token")
      
    } else {
      yield put({
        type: AUTH_PASSWORD_RECOVER_ERROR,
        error: 'Unknown Error',
      });
    }
  } catch (error) { 
    console.log('error.response :>> ', error.response);
    const e = error?.response?.data?.email?.length > 0 ? error.response.data.email[0] : 'Something went wrong '
    alert(e)
    yield put({
      type: AUTH_PASSWORD_RECOVER_ERROR,
      error: 'Can\'t recover password with provided email',
    });
  }
}


function *handleLogout () {
  try {
    StorageUtils.removeAccessToken();
    StorageUtils.removeUser();
    addTokenToHttp('');
    yield put({
      type: APP_CLEAR_STATE
    })
    // yield call(logout);
  } catch (error) {
    console.log('error :>> ', error);
  }
}

export default all([
  takeLatest(AUTH_LOGIN_REQUEST, handleLogin),
  takeLatest(AUTH_SIGNUP_REQUEST, handleSignUp),
  takeLatest(AUTH_PASSWORD_RECOVER_REQUEST, handlePasswordRecovery),
  takeLatest(AUTH_LOGOUT, handleLogout),
]);