import React, { useEffect, useRef, useState } from "react"
import "./Login.css"
import home from "../../assets/home.png"
import upload from "../../assets/upload-red.png"
import client from "../../assets/clients.png"
import media from "../../assets/media.png"
import staff from "../../assets/staff.png"
import chevron from "../../assets/chevron-right.png"
import { Link, useNavigate } from "react-router-dom"
import { getOnBoardingQuestionOnLadingPage } from "../../redux/app/sagas"
import StorageUtils from "../../utils/storage"
import Pagination from "../../components/Pagination/Pagination"
import { addOnboardingDetails, uploadLogo } from "../../redux/auth/sagas"
import { FormControl, FormHelperText } from "@mui/material"
import { styled } from "@mui/material/styles"

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1
})

const Onboarding = () => {
  const navigate = useNavigate();
  const fileInputRef = useRef();

  const pageSize = 5;

  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(0);

  const [displayQuestions, setDisplatQuestions] = useState([]);
  const [onBoardingQuestion, setOnBoadringQuestion] = useState([]);
  const [answers, setAnswers] = useState([]);

  const [selectedImage, setSelectedImage] = useState(null)
  const [errors, setErrors] = useState({});

  const userType = StorageUtils.getUserType();

  const isAgency = userType === 'Agency';

  const handleSelectImage = async e => {
    setErrors(prev => ({ ...prev, image: "" }));
    const file = e.target.files[0];
    if (!file?.size) {
      return
    }
    if (file.size > 1024 * 1024 * 5) {
      setErrors(prev => ({
        ...prev,
        image: "Image size should be less than 5MB."
      }))
      setSelectedImage("")
      return
    }
    const formData = new FormData();
    const email = StorageUtils.getUserEmail();
    formData.append('email', email);
    formData.append('logo', file);
    try {
      await uploadLogo(formData);
      setSelectedImage(e.target.files[0])
    } catch {
      setErrors(prev => ({
        ...prev,
        image: "Failed to upload image"
      }))
    }
  }

  const handleNext = async (skip) => {
    const payload = {
      email: StorageUtils.getUserEmail(),
      skip: !!skip,
      body: skip ? [] : onBoardingQuestion.map((data, index) => {
        return {
          question: data.id,
          answer: answers[index] || ''
        }
      })
    }
    try {
      await addOnboardingDetails(payload);
      navigate("/")
    } catch (err) {
      console.log(err);
    }
  }

  const handleAnswerChange = (index, answer) => {
    const newAnswers = [...answers];
    newAnswers[index] = answer;
    setAnswers(newAnswers);
  };

  const onPageChange = (page) => {
    const startIndex = (page - 1) * pageSize;
    const lastIndex = page * pageSize;
    const questionToDisplay = onBoardingQuestion.slice(startIndex, lastIndex);
    setCurrentPage(page);
    setDisplatQuestions(questionToDisplay)
  }

  useEffect(() => {
    getOnBoardingQuestionOnLadingPage()
      .then(resp => {
        const data = resp?.data;
        const onBoardingQuestions = isAgency ? data?.agency || [] : data?.inhouse || [];
        const pages = onBoardingQuestions.length == 0 ? 0 : Math.ceil(onBoardingQuestions.length / pageSize)
        const startIndex = 0;
        const lastIndex = pageSize * 1;
        const questionToDisplay = onBoardingQuestions.slice(startIndex, lastIndex);
        setTotalPages(pages);
        setOnBoadringQuestion(onBoardingQuestions);
        setDisplatQuestions(questionToDisplay)
        setAnswers(Array(onBoardingQuestion.length).fill(""));
      }).catch(error => {
        alert("Failed to get Questions")
      })
  }, [])

  return (
    <div className="onboarding-screen">
      <header
        className="header-logi"
        style={{ display: "flex", justifyContent: "flex-end" }}
      >
        <span className="create-account-text">
          Need help?{" "}
          <span className="create-text">
            <Link to="/onboard">See FAQ</Link>
          </span>
        </span>
      </header>

      <div className="onboarding-container">
        <div className="left-column">
          <div className="header-onboarding">
            <img src={home} alt="Header Image" />
            <div>
              <h2>{isAgency ? 'Client onboarding' : 'Campaign onboarding'} </h2>

              {/* <div className="left-column-buttons">
                <button>
                  <img src={client} alt="Button 1 Image" />
                  Clients
                </button>
                <button>
                  <img src={media} alt="Button 1 Image" />
                  Media list
                </button>
                <button>
                  <img src={staff} alt="Button 1 Image" />
                  Staff
                </button>
              </div> */}
            </div>
          </div>
          <div className="survey-inputs">
            {displayQuestions.map((data, index) => {
              const itemNo = ((currentPage - 1) * pageSize) + index;
              return (<div key={itemNo + 1}>
                <label>
                  {itemNo + 1}.{data?.question}
                </label>
                <input
                  type="text"
                  style={{marginTop:"15px",marginBottom:"10px"}}
                  value={answers[itemNo]}
                  placeholder="Enter your answer"
                  onChange={(e) => handleAnswerChange(itemNo, e.target.value)}
                />
              </div>)
            })}
            {totalPages ? <Pagination
              hideTotalCount={true}
              totalPages={totalPages}
              currentPage={currentPage}
              removeTopMargin={true}
              centeredPagination={true}
              onPageChange={onPageChange} /> : null}
          </div>
          <div className="bottom-buttons">
            <button onClick={() => handleNext(false)} className="btn-finish">
              Finish
            </button>
            <button onClick={() => handleNext(true)} className="btn-skip">
              Skip
              <img src={chevron} alt="Button 1 Image" />
            </button>
          </div>
        </div>
        
        <div className="right-column" style={{ marginTop: 40 }}>
          <div className="right-column-buttons">
            {/* {currentPage === 2 && <button>
              <img src={plus} alt="Button 1 Image" />
              Campaign
            </button>}
            {currentPage === 2 &&<button
              className="checkout"
              style={{ backgroundColor: "#F0F0F0", color: "#0F5796" }}
            >
              <img src={check} alt="Button 2 Image" />
              Check out
            </button>} */}
            <FormControl>
              <button style={{ backgroundColor: "#FFBCBC", color: "#FF5B5B", width:'272px', height:'56px', backgroundColor:'rgba(255, 91, 91, 0.15)' }} onClick={() =>
                fileInputRef.current.click()
              }>
                <img src={upload} alt="Button 3 Image" />
                Upload logo
                <VisuallyHiddenInput
                  ref={fileInputRef}
                  type="file"
                  accept="image/*"
                  onChange={handleSelectImage}
                />
              </button>
              {<span style={{ color: '#0F5796', fontSize: "14px", marginTop: "5px", marginLeft: "5px" }}>{selectedImage?.name}</span>}
              <FormHelperText error>{errors?.image}</FormHelperText>
            </FormControl>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Onboarding
