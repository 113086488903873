import React, { useState } from "react"
import { NavLink} from "react-router-dom"
import { styled } from "@mui/material/styles"

import { ReactComponent as OnboardIcon } from "../../assets/icon-onboard.svg"
import { ReactComponent as ManageIcon } from "../../assets/icon-manage.svg"
import { ReactComponent as CampaignIcon } from "../../assets/icon-campaign.svg"

const StyledTabItem = styled(NavLink)({
  display: "flex",
  alignItems: "center",
  gap: "10px",
  padding: "10px 20px",
  border: "none",
  borderRadius: "10px",
  backgroundColor: "transparent",
  color: "#0F5796",
  cursor: "pointer",
  textAlign: "left",
  textDecoration: "none",
  fontSize: "1rem"
})

const StyledTab = styled("div")({
  cursor: "pointer",
  display: "flex",
  flexDirection: "column",
  gap: "20px"
})

const OnboardingTabs = ({clientId, status, onViewForm, onSendForm, formCreated}) => {

  const [selectedTab, setSelectedTab] = useState(1);

  return (
    <div className="left-side">
      <StyledTab>
        {!status && <StyledTabItem
          to={{ pathname: "/client/create-onboarding-form/"+clientId }}
          style={{backgroundColor: selectedTab === 1 ? '#F0F0F0': ''}}
        >
          <OnboardIcon />
          Create Onboarding Form
        </StyledTabItem>}
        {formCreated && <StyledTabItem
        style={{backgroundColor: selectedTab === 2 ? '#F0F0F0': ''}}
          onClick={()=>{
            setSelectedTab(2);
            onViewForm();
          }}
        >
          <ManageIcon />
          { status ? 'View Completed Form' : 'View Form'}
        </StyledTabItem>}
        {formCreated && !status && <StyledTabItem
          style={{backgroundColor: selectedTab === 3 ? '#F0F0F0': ''}}
          onClick={()=>{
            setSelectedTab(3)
            onSendForm();
          }}
        >
          <CampaignIcon />
          Send Form
        </StyledTabItem>}
      </StyledTab>
    </div>
  )
}

export default OnboardingTabs
