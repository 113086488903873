import React, { useEffect, useMemo, useState } from "react"
import Header from "../../components/Header"
import "./Dashboard.css"
import plus from "../../assets/plus.png"
import newbiz from "../../assets/new-red.png"
import proposal from "../../assets/proposal-yellow.png"
import contract from "../../assets/contract-green.png"
import business from "../../assets/business.png"
import TitleLine from "../../components/DataLine/TitleLine"
import DataLine from "../../components/DataLine/DataLine"
import Pagination from "../../components/Pagination/Pagination"
import { useNavigate } from "react-router-dom"
import { getBusinessList } from "../../redux/app/actions"
import { useDispatch, useSelector } from "react-redux"
import searchicon from "../../assets/search-icon-black.png"
import AppModal from "../../components/Modal"
import { request } from "../../utils/http"
import { toast } from "react-toastify"

const Dashboard = () => {
  const dispatch = useDispatch()
  const [currentPage, setCurrentPage] = useState(1)
  const [openModal, setOpenModal] = useState(false);
  const [uploadId, setUploadId] = useState(0);
  const [pageSize, setPageSize] = useState(6)
  const [isChecked, setIsChecked] = useState(false)
  const [search, setSearch] = useState("")
  const navigate = useNavigate()
  const businesses = useSelector(state => state.App.businesses)
  // const isLoading = useSelector(state => state.App.isLoading)

  const isBusinessesAvailable = Array.isArray(businesses)

  //console.log(businesses)

  useEffect(() => {
    dispatch(getBusinessList())
    //dispatch(getProfile())
    //dispatch(getCenterList())
  }, [])

  const [selectedStatus, setSelectedStatus] = useState("")

  const list = businesses?.filter(
    o =>
      o?.name?.toLowerCase().includes(search.toLowerCase()) ||
      o?.email?.toLowerCase().includes(search.toLowerCase())
  )
  const listPrint = search ? list : businesses

  const calculateListItems = () => {
    if (!listPrint) {
      return []
    }

    return selectedStatus
      ? listPrint.filter(item => item.status === selectedStatus)
      : listPrint
  }

  const filteredList = useMemo(() => {
    if (!listPrint) {
      return []
    }

    // Filter the list based on selectedStatus
    const filteredItems = calculateListItems()

    const firstPageIndex = (currentPage - 1) * pageSize
    const lastPageIndex = firstPageIndex + pageSize

    return filteredItems.slice(firstPageIndex, lastPageIndex)
  }, [currentPage, listPrint, pageSize, selectedStatus])

  const handleStatusFilter = status => {
    setSelectedStatus(status)
    setCurrentPage(1)
    setIsChecked(false)
  }

  const handleUpload = (file) => () => {
    if (file) {
      const postData = new FormData();
      postData.append("signed_contract", file);

      request
        .patch(`/contracts/api/v1/contract/upload-signed-contract/${uploadId}/`,
          postData, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        })
        .then(() => {
          toast.success("Signed Contract uploaded successfully.");
          setOpenModal(false);
        });
    }
    else
      toast.error("Please select a file to upload.");
  }

  const uploadDoc = (id) => () => {
    setUploadId(id);
    setOpenModal(true);
  }

  return (
    <div className="dashboard-container">
      {/* <SideMenu /> */}
      <div className="main-content">
        <Header
          title="New Business Management"
          value={search}
          onChange={e => setSearch(e.target.value)}
        />
        <div className="horizontal-rule"></div>
        <div className="content">
          {/* Left Column */}
          <div className="left-side">
            <div className="tabs">
              <button onClick={() => navigate("/add-business")}>
                <img src={plus} alt="Button 1 Image" />
                New Business
              </button>
              <button
                style={
                  selectedStatus == ""
                    ? { backgroundColor: "#F0F0F0", color: "#0F5796" }
                    : { backgroundColor: "#Fff", color: "#0F5796" }
                }
                // style={{ backgroundColor: "#ccc", color: "#0F5796" }}
                onClick={() => handleStatusFilter("")}
              >
                <img src={business} alt="Button 1 Image" />
                All
              </button>
              <button
                style={
                  selectedStatus == "New"
                    ? { backgroundColor: "#F0F0F0", color: "#FF5B5B" }
                    : { backgroundColor: "#Fff", color: "#FF5B5B" }
                }
                onClick={() => handleStatusFilter("New")}
              >
                <img src={newbiz} alt="Button 1 Image" />
                New
              </button>
              <button
                style={
                  selectedStatus == "Proposal"
                    ? { backgroundColor: "#F0F0F0", color: "#FFBB54" }
                    : { backgroundColor: "#Fff", color: "#FFBB54" }
                }
                onClick={() => handleStatusFilter("Proposal")}
              >
                <img src={proposal} alt="Button 1 Image" />
                Proposal
              </button>
              <button
                style={
                  selectedStatus == "Contract"
                    ? { backgroundColor: "#F0F0F0", color: "#00A389" }
                    : { backgroundColor: "#Fff", color: "#00A389" }
                }
                onClick={() => handleStatusFilter("Contract")}
              >
                <img src={contract} alt="Button 1 Image" />
                Contract
              </button>
            </div>
          </div>

          {/* Right Column */}
          <div className="right-columns">
            <div className="business-management-header">
              <div className="header-content">
                <div className="title">New Business Management</div>
                <div className="subtitle">List of all businesses</div>
              </div>

              <div style={{ display: "flex" }}>
                <div className="internal-searchbox" style={{ position: "relative", width: "20vw", marginRight: "5vw" }}>
                  <input
                    type="text"
                    placeholder="Search here..."
                    className="internal-searchbox-input"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    style={{
                      width: "100%",
                      height: 26,
                      padding: "10px 20px 10px 20px",
                      border: "none",
                      borderRadius: "10px",
                      backgroundColor: "#F0F0F0"
                    }}
                  />
                  <img
                    src={searchicon}
                    alt="Search Icon"
                    style={{
                      position: "absolute",
                      width: 20,
                      height: 20,
                      top: "40%",
                      right: "-8px",
                      transform: "translateY(-50%)",
                      cursor: "pointer",
                    }}
                  />
                </div>

                <div className="status-display-button">
                  {selectedStatus == "New" ? (
                    <button
                      className="all-button"
                      onClick={() => handleStatusFilter("New")}
                    >
                      <img src={newbiz} alt="Button 1 Image" />
                      New
                    </button>
                  ) : selectedStatus == "Proposal" ? (
                    <button
                      className="all-button"
                      onClick={() => handleStatusFilter("Proposal")}
                    >
                      <img src={proposal} alt="Button 1 Image" />
                      Proposal
                    </button>
                  ) : selectedStatus == "Contract" ? (
                    <button className="all-button">
                      <img src={contract} alt="Button 1 Image" />
                      Contract
                    </button>
                  ) : (
                    <button className="all-button">
                      <img src={business} alt="Button 1 Image" />
                      All
                    </button>
                  )}
                </div>

              </div>
            </div>

            <TitleLine />

            {isBusinessesAvailable
              ? filteredList.map((item, index) => (
                <DataLine
                  key={index}
                  item={item}
                  checked={isChecked}
                  uploadDoc={uploadDoc(item.id)}
                  isChecked={() =>
                    setIsChecked(isChecked === item.id ? false : item.id)
                  }
                  navigate={navigate}
                />
              ))
              : null}

            <Pagination
              //className="pagination-bar"
              currentPage={currentPage}
              totalCount={
                isBusinessesAvailable ? calculateListItems().length : 0
              }
              totalPages={Math.ceil(
                isBusinessesAvailable
                  ? calculateListItems().length / pageSize
                  : 0
              )}
              pageSize={pageSize}
              onPageChange={page => setCurrentPage(page)}
              onSizeChange={size => setPageSize(size)}
            />
          </div>
        </div>
      </div>
      <AppModal
        modalIsOpen={openModal}
        uploadFile
        onClick={handleUpload}
        closeModal={() => {
          setOpenModal(false);
        }}
      />
    </div>
  )
}

export default Dashboard
