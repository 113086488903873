import { Typography, Box, Grid } from "@mui/material"
import React, { useState, useEffect, useMemo } from "react"
import DropDownMenu from "../../../components/DropDownMenu"
import LabelButton from "../../../components/LabelButton"
import { ReactComponent as ClientIcon } from "../../../assets/icon-client.svg"
import { ReactComponent as NewPressReleaseIcon } from "../../../assets/icon-new-press-release.svg"
import { ReactComponent as FinishedPressReleaseIcon } from "../../../assets/icon-finished-press-release.svg"
import { ReactComponent as LabelIcon } from "../../../assets/icon-label.svg"
import TemplateCard from "../../../components/TemplateCard"
import FormInput from "../../../components/FormInput"
import { useNavigate } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"
import {
  getClients,
  getPressReleaseTemplates
} from "../../../redux/clients/actions"
import Pagination from "../../../components/Pagination/Pagination"

const PressRelease = ({ selectedClient }) => {
  const dispatch = useDispatch()
  const clientsData = useSelector(state => state.client.clientsData)
  const templatesData = useSelector(state => state.client.pressReleaseTemplates)
  const totalRecords = useMemo(() => templatesData?.count, [templatesData])
  const [search, setSearch] = useState("")
  const [currentPage, setCurrentPage] = useState(1)
  const [pageSize, setPageSize] = useState(4)
  const [client, setClient] = useState({
    value: selectedClient?.id,
    label: selectedClient?.name
  })
  const navigate = useNavigate()

  useEffect(() => {
    if (client?.value)
      dispatch(
        getPressReleaseTemplates({
          page: currentPage,
          limit: pageSize,
          client: client?.value,
          search
        })
      )
  }, [dispatch, client, currentPage, pageSize, search])

  useEffect(() => {
    setClient({
      value: selectedClient?.id,
      label: selectedClient?.name
    })
  }, [selectedClient])

  useEffect(() => {
    if (!clientsData?.length) {
      dispatch(getClients())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientsData?.length])

  const clientsList = useMemo(() => {
    if (!client?.value) {
      setClient({
        value: clientsData?.[0]?.id,
        label: clientsData?.[0]?.name
      })
    }
    return clientsData?.map(clientData => ({
      label: clientData?.name,
      value: clientData?.id,
      key: `client-${clientData?.id}`
    }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientsData])

  const templateList = useMemo(() => {
    return templatesData?.results?.map(template => ({
      id: template?.id,
      image: template?.image,
      title: template?.title,
      body: template?.body,
      clientName: template?.client_detail?.name,
      label: template?.tag,
      labelIcon: <LabelIcon />
    }))
  }, [templatesData])

  const handleClientChange = (e, item) => {
    setClient(item)
  }

  const handleSearchChange = e => {
    setSearch(e.target.value)
  }

  return (
    <Box>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            width: "60%"
          }}
        >
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography
                variant="h5"
                sx={{ fontWeight: "500", color: "#464255", fontSize: "1.5rem" }}
                paragraph
              >
                Press Release
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  fontWeight: "400",
                  fontSize: "0.875rem",
                  color: "#A3A3A3"
                }}
              >
                See details
              </Typography>
            </Box>
            <Box sx={{ width: "60%", minWidth: "max-content" }}>
              <DropDownMenu
                label="Client Name"
                itemList={clientsList}
                onClick={handleClientChange}
                activeItem={client}
              />
            </Box>
          </Box>
          <FormInput
            placeholder="Search here..."
            sx={{
              width: "70%",
              marginTop: "2rem",
              ".MuiInputBase-input": { backgroundColor: "#ECEAF3" }
            }}
            onChange={handleSearchChange}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            width: "30%",
            flexDirection: "column",
            gap: "0.5rem"
          }}
        >
          <LabelButton label="View Client profile" icon={<ClientIcon />} />
          <LabelButton
            label="New Press Release"
            icon={<NewPressReleaseIcon />}
            onClick={() => navigate("/client/press-releases/new")}
          />
          <LabelButton
            label="Finished Press Release"
            icon={<FinishedPressReleaseIcon />}
          />
        </Box>
      </Box>
      <Grid container spacing={3} mt={2}>
        {templateList?.map(template => (
          <Grid item xs={12} md={6} key={template?.id}>
            <TemplateCard
              title={template?.title}
              label={template?.label}
              labelIcon={template?.labelIcon}
              image={template?.image}
              subTitle={template?.clientName}
              onClick={() =>
                navigate("/client/press-releases/new", {
                  state: {
                    title: template?.title,
                    content: template?.body,
                    image: template?.image,
                    tag: template?.label
                  }
                })
              }
              body={template?.body}
              actionLabel={"Use template"}
            />
          </Grid>
        ))}
      </Grid>
      <Box>
        <Pagination
          currentPage={currentPage}
          totalCount={totalRecords || 0}
          totalPages={Math.ceil(totalRecords / pageSize || 0)}
          pageSize={pageSize}
          onPageChange={page => setCurrentPage(page)}
          onSizeChange={size => setPageSize(size)}
        />
      </Box>
    </Box>
  )
}

export default PressRelease
