import React from 'react';
import chevronLeft from "../../../../assets/chevron-left-new.png"
import chevronRight from "../../../../assets/chevron-right-new.png"

const HorizontalList = ({ list, handlePrevious, handleNext, currentSelection }) => {
    return (
        <div className='year-list-container'>
            <button type='button' className='cutsom-left-report-btn' onClick={handlePrevious}>
                <img src={chevronLeft} alt='Chevron Left' width="9px" height="17px"
                    style={{ filter: 'brightness(0) invert(1)' }} />
            </button>
            <button type='button' className='cutsom-right-report-btn' onClick={handleNext}>
                <img src={chevronRight} alt='Chevron Right' width="9px" height="17px"
                    style={{ filter: 'brightness(0) invert(1)' }} />
            </button>
            <div className='year-list' id="year-list">
                {list.map((item, index) => {
                    return (<div id={item} key={index} className={currentSelection == item ? 'year-list-item year-list-item-selected' : 'year-list-item'}>
                        {item}
                    </div>
                    )
                })}
            </div>
        </div>
    )
}

export default HorizontalList