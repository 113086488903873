import React, { useEffect, useState } from "react"
import Header from "../../components/Header"
import SideMenu from "../../components/SideMenu"
import plus from "../../assets/plus-black.png"
import expand from "../../assets/expand.png"
import close from "../../assets/close.png"
import searchicon from "../../assets/search-icon-black.png"
import trash from "../../assets/trash.png"
import ReactQuill from "react-quill"
import "react-quill/dist/quill.snow.css"
import { useLocation, useNavigate } from 'react-router-dom';
import { request } from "../../utils/http"
import Pagination from "../../components/Pagination/Pagination"
import { Stack, Typography } from "@mui/material"
import { toast } from "react-toastify"
import './Contract.css';
import AppModal from "../../components/Modal"

const Contract = () => {
  const [currentPage, setCurrentPage] = useState(1)
  const [pageSize, setPageSize] = useState(3)
  const [contracts, setContracts] = useState([]);
  const [previewItem, setPreviewItem] = useState(null);
  const [contractCount, setContractCount] = useState(0);
  const [trigger, setTrigger] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [search, setSearch] = useState('')
  const [localSearch, setLocalSearch] = useState('')
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    request.get("/contracts/api/v1/contract-template/", {
      params: {
        page: currentPage,
        search: localSearch,
        size: pageSize
      }
    }).then(({ data }) => {
      setContracts(data.results);
      setContractCount(data.count);
    })
      .catch(() => {
        setCurrentPage(1);
      })
  }, [currentPage, pageSize, search, trigger, localSearch]);

  const handlePreview = (item) => () => {
    setPreviewItem(item);
  }

  const handleDelete = () => {
    if (previewItem?.id)
      request.delete(`/contracts/api/v1/contract-template/${previewItem?.id}/`)
        .then(() => {
          setPreviewItem(null);
          setOpenModal(false);
          setTrigger(!trigger);
          toast.success("Contract template deleted successfully.");
        })
  }

  const handleLocalSerach = (e) => {
    setLocalSearch(e.target.value)
  }

  const handleOnSearchClick = () => {
    const currentPage = 1;
    setCurrentPage(1);
    request.get("/contracts/api/v1/contract-template/", {
      params: {
        page: currentPage,
        search: localSearch,
        size: pageSize
      }
    }).then(({ data }) => {
      setPreviewItem(null)
      setContracts(data.results);
      setContractCount(data.count);
    })
      .catch(() => {
        setCurrentPage(1);
      })
  }

  const handleClick = (item) => () => {
    navigate(`/create-contract/${location.pathname.split("/")[2]}`, {
      state: {
        title: item.title,
        content: item.body,
        tag: item.tag,
        image: item.image
      }
    });
  }

  return (
    <div className="dashboard-container contract-page">
      {/* <SideMenu /> */}
      <div className="main-content">
        <Header title="Contract Templates"
          value={search}
          onChange={e => setSearch(e.target.value)} />

        <div className="horizontal-rule"></div>
        <div className="content" style={{ justifyContent: "space-between" }}>
          {/* Right Column */}
          <div className="right-columnsl" style={{ maxWidth: "540px", width: '100%' }}>
            <div style={{ display: "flex", marginBottom: 20 }}>
              <div
                style={{
                  flex: 2,
                  display: "flex",
                  alignItems: "center",
                  position: "relative"
                }}
              >
                <input
                  type="text"
                  placeholder="Search here..."
                  onChange={handleLocalSerach}
                  onKeyUp={(e) => e.key === 'Enter' && handleOnSearchClick(e)}
                  style={{
                    width: "100%",
                    height: 26,
                    padding: "8px 30px 8px 8px",
                    border: "none",
                    borderRadius: "10px",
                    backgroundColor: "#F0F0F0"
                  }}
                />
                <img
                  src={searchicon}
                  onClick={handleOnSearchClick}
                  alt="Search Icon"
                  style={{
                    position: "absolute",
                    width: 20,
                    height: 20,
                    top: "50%",
                    right: "10px",
                    transform: "translateY(-50%)",
                    cursor: "pointer"
                  }}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginLeft: 20
                }}
              >
                <div className="vertical-rule"></div>
                <div
                  style={{
                    marginLeft: "20px",
                    textAlign: "center",
                    cursor: "pointer"
                  }}
                >
                  <img
                    src={plus}
                    onClick={() => navigate(`/create-contract/${location.pathname.split("/")[2]}`)}
                    alt="Notification Icon"
                    style={{ width: 18, height: 18, marginRight: 10 }}
                  />
                </div>
              </div>
            </div>

            {contracts?.length
              ? contracts.map(item => (
                <div key={item.id} className="proposal-card">
                  <div className="proposal-content" >
                    <Stack direction="row" spacing={2}>
                      <div style={{ display: "flex", width: "100%" }}>
                        {item.image ? (
                          <img
                            src={item.image}
                            alt="template-icon"
                            style={{
                              width: "60px",
                              height: "60px",
                              objectFit: "contain",
                              borderRadius: "6px"
                            }}
                          />
                        ) : (
                          <div className="proposal-image-container" style={{ height: 50 }}>
                            {item.title && (
                              <div className="thumbnail">
                                {item.title.charAt(0).toUpperCase()}
                              </div>
                            )}
                            </div>
                        )}
                        <Typography
                          sx={{
                            marginLeft: "20px",
                            marginTop: "6px",
                            color: "#464255",
                            fontWeight: "500",
                            fontSize: "1.125rem"
                          }}
                        >
                          {item.title}
                        </Typography>
                        <img
                          style={{
                            marginLeft: "auto"
                          }}
                          src={expand}
                          alt="expand"
                          className="card-image"
                          onClick={handlePreview(item)}
                        />
                      </div>
                    </Stack>
                  </div>
                  <div className="detail-subtext">
                  <Typography
                    dangerouslySetInnerHTML={{ __html: `<p>${item.body.replace(/<[^>]*>/g, '')}</p>` }}
                    sx={{
                      height: "40px",
                      maxHeight: "4rem",
                      overflow: 'hidden',
                      lineHeight:'normal',
                  fontSize:'14px',
                      "& p": { margin: "8px 0", fontSize: "14px", color: "#A3A3A3" }
                    }}
                  />
                  </div>
                  <div className="proposal-buttons" style={{justifyContent:'flex-end'}}>
                    <button
                      className="template-button"
                      onClick={handleClick(item)}
                    >
                      Use Template
                    </button>
                  </div>
                </div>
              ))
              : null}
            <Pagination
              currentPage={currentPage}
              totalCount={contractCount || 0}
              totalPages={Math.ceil(contractCount / pageSize || 0)}
              pageSize={pageSize}
              appendText='data'
              onPageChange={page => setCurrentPage(page)}
              onSizeChange={size => setPageSize(size)}
            />
          </div>

          <div className="left-proposall" style={{ flex: 1, marginLeft: 40, backgroundColor: "#fff", padding: 20 }}>
            <div className="proposal-content">
              <div style={{ display: "flex" }}>

                <div className="text-container">
                  <p className="proposal-card-title" style={{ marginBottom: 15 }}>
                    Preview
                  </p>
                  <p className="card-description" style={{ marginBottom: 25 }}>
                    template
                  </p>
                </div>
              </div>
              {previewItem && <div className="expand-icon">
                <img
                  style={{ height: 26, width: 26 }}
                  src={trash}
                  alt={"item"}
                  onClick={() => {
                    setOpenModal(true);
                  }}
                  className="card-image"
                />
                <img
                  src={expand}
                  alt={"item"}
                  onClick={handleClick(previewItem)}
                  className="card-image"
                />
                <img
                  style={{ height: 22, width: 22 }}
                  src={close}
                  alt={"item"}
                  onClick={() => setPreviewItem(null)}
                  className="card-image"
                />
              </div>}
            </div>
            <p className="proposal-card-title" style={{ fontSize: 24 }}>
              {previewItem?.title}
            </p>
            <div className="quill-editor">
              <ReactQuill value={previewItem?.body} readOnly />
              <div style={{ display: "flex", justifyContent: "flex-end", marginTop: 30, paddingBottom: '30px' }}>
                {previewItem && <button className="template-button" onClick={handleClick(previewItem)}>Use Template</button>}
              </div>
            </div>
          </div>
        </div>
      </div>
      <AppModal
        modalIsOpen={openModal}
        deleteRecord
        onClick={handleDelete}
        closeModal={() => {
          setOpenModal(false);
        }}
      />
    </div>
  )
}

export default Contract;
