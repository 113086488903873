import React from 'react';
import SideMenu from '../../components/SideMenu';
import Header from '../../components/Header';

import './Template1.css';

const Template1 = (props) => {

    return (
        <div className='template1'>
            <div className='report-container' style={{ backgroundColor: 'rgb(245, 250, 255)' }}>
                <div className='add-left-side' >
                    <h2 className='title' style={{ marginBottom: '15px' }}>{props.title}</h2>
                    {!props.hideSubHeading && <span className='subtitle'>See details</span>}
                </div>
                <div className='right-side'>
                    {props.children}
                </div>
            </div>
        </div>
    )
}

export default Template1;