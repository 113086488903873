import React, { useEffect, useMemo, useRef, useState } from 'react';
import Header from '../../components/Header';
import Template1 from '../../components/Template/Template1';
import { Box, Grid } from '@mui/material';
import './CampaignManagement.scss';
import ReactQuill from 'react-quill';
import { useParams } from 'react-router-dom';
import { request } from '../../utils/http';
import { useForm, Controller } from 'react-hook-form';
import DropDownMenu from '../../components/DropDownMenu';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import ListMenu from '../../components/ListMenu';
import { categoryList } from './CampaignConst';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

const CreateTask = () => {

    const params = useParams();

    const clientId = params?.id;

    const searchRef = useRef(null);

    const [targetItem, setTargetItem] = useState(null);

    const [categoryTarget, setCategoryTarget] = useState(null);

    const [assigntoTarget, setAssigntoTarget] = useState(null);

    const [clientDetails, setClientDetails] = useState(null);

    const [description, setDescription] = useState("");

    const cList = useMemo(() => {
        return Array(10).fill("").map((data, i) => {
            return {
                id: i + 1,
                value: "Campaign " + (i + 1)
            }
        })
    }, [])

    const [campaignList, setCampaignList] = useState(cList);

    const {
        register,
        handleSubmit,
        setValue,
        trigger,
        getValues,
        control,
        formState: { errors }
    } = useForm();

    const onSubmit = (formValue) => {
        // console.log(formValue);
    }

    const toggleDropdown = ({ currentTarget }) => {
        setTargetItem({ currentTarget });
        setTimeout(() => {
            console.dir(searchRef.current.childNodes[1])
            searchRef.current.childNodes[1].focus();
        }, 100)
    }

    const onTaskCategory = ({ currentTarget }) => {
        setCategoryTarget({ currentTarget })
    }
    const onAssignToTarget = ({ currentTarget }) => {
        setAssigntoTarget({ currentTarget })
    }


    useEffect(() => {
        request.get(`/clients/api/v1/clients/${clientId}/`)
            .then(({ data }) => {
                setClientDetails(data);
                setValue('client_name', data?.name || '');
            });
    }, [])


    return (<div className='create-task'>
        <Template1 title='Create Task' hideSubHeading="true">
            <form style={{ marginLeft: '5rem' }} onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <div className="create-task-form">
                            <label>
                                Client name
                            </label>
                            <div className='create-task-input'>
                                <input
                                    type="text"
                                    name="client_name"
                                    disabled
                                    placeholder="Client name"
                                    {...register('client_name', { required: "Client name is required." })}
                                />
                                {errors?.client_name && <span className='error'>{errors?.client_name?.message}</span>}
                            </div>
                        </div>
                        <div className="create-task-form">
                            <label>
                                Campaign name
                            </label>
                            <div className='create-task-input'>
                                <div onClick={toggleDropdown} style={{ cursor: 'text' }}>
                                    <input
                                        className='campaign-name'
                                        type="text"
                                        disabled
                                        style={{ pointerEvents: 'none' }}
                                        placeholder="Campaign name"

                                        {...register('campaign_name', { required: "Campaign is required." })}
                                    />
                                </div>
                                <Box sx={{ width: "60%", minWidth: "max-content" }}>
                                    <DropDownMenu
                                        hideThis
                                        ref={searchRef}
                                        targetItem={targetItem}
                                        itemList={
                                            campaignList
                                                .map(item => ({
                                                    label: item.value,
                                                    value: item.id
                                                }))
                                        }
                                        onClick={(_, item) => {
                                            console.log(item)
                                            setValue('campaign_name', item.label);
                                            setValue('campaign_id', item.value);
                                            trigger('campaign_name')
                                        }}
                                        activeItem={{
                                            label: getValues('campaign_name'),
                                            value: getValues('campaign_id') || ''
                                        }}
                                    />
                                </Box>
                                {errors?.campaign_name && <span className='error'>{errors?.campaign_name?.message}</span>}
                            </div>
                        </div>
                        <div className="create-task-form">
                            <label>
                                Task title
                            </label>
                            <div className='create-task-input'>
                                <input
                                    type="text"
                                    placeholder="Task title"
                                    {...register('task_title', { required: "Task title is required." })}
                                />
                                {errors?.task_title && <span className='error'>{errors?.task_title?.message}</span>}
                            </div>
                        </div>
                        <div className="create-task-form">
                            <label>
                                Task category
                            </label>
                            <div className='create-task-input'>
                                <FormControl sx={{ backgroundColor: 'white', width: '50%', marginTop: '15px' }}>
                                    <Controller
                                        name="task_category"
                                        control={control}
                                        rules={{ required: 'Task category is required.' }}
                                        defaultValue={""}
                                        render={({ field }) => (
                                            <Select
                                                {...field}
                                                displayEmpty
                                                inputProps={{ 'aria-label': 'Without label' }}
                                                placeholder="Task category"
                                                open={false}
                                                onClick={onTaskCategory}
                                            >
                                                <MenuItem value="">
                                                    Task Category
                                                </MenuItem>
                                                {categoryList.map(item => {
                                                    return (
                                                        <MenuItem value={item.value}>
                                                            {item.label}
                                                        </MenuItem>
                                                    )
                                                })}
                                            </Select>
                                        )}
                                    />
                                </FormControl>
                                <ListMenu
                                    targetItem={categoryTarget}
                                    heading="Task category"
                                    subheading="Choose task:"
                                    itemList={
                                        categoryList
                                            .map(item => ({
                                                label: item.label,
                                                value: item.value
                                            }))
                                    }
                                    onClick={(_, item) => {
                                        setValue('task_category', item.value);
                                        trigger('task_category');
                                    }}
                                    activeItem={{
                                        value: getValues('task_category') || ''
                                    }}
                                />
                                {errors?.task_category && <div className='error'>{errors?.task_category?.message}</div>}
                            </div>
                        </div>
                        <div className="create-task-form">
                            <div className='date-column'>
                                <div className='date-column-item'>
                                    <label>
                                        Start date
                                    </label>
                                    <FormControl sx={{ backgroundColor: 'white', width: '100%', marginTop: '15px' }}>
                                        <Controller
                                            name="start_date"
                                            control={control}
                                            rules={{ required: 'Start date is required.' }}
                                            defaultValue={""}
                                            render={({ field }) => (<LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DateTimePicker
                                                    label=""
                                                    {...field}
                                                />
                                            </LocalizationProvider>)} />
                                    </FormControl>
                                    {errors?.start_date && <div className='error'>{errors?.start_date?.message}</div>}
                                </div>
                                <div className='date-column-item'>
                                    <label>
                                        End date
                                    </label>
                                    <FormControl sx={{ backgroundColor: 'white', width: '100%', marginTop: '15px', }}>
                                        <Controller
                                            name="end_date"
                                            control={control}
                                            rules={{ required: 'End date is required.' }}
                                            defaultValue={""}
                                            render={({ field }) => (<LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DateTimePicker
                                                    label=""
                                                    {...field}
                                                />
                                            </LocalizationProvider>)} />
                                    </FormControl>
                                    {errors?.end_date && <div className='error'>{errors?.end_date?.message}</div>}
                                </div>
                            </div>
                        </div>
                        <div className="create-task-form">
                            <label>
                                Task description
                            </label>
                            <div className="create-task-input">
                                <div className="quill-editor">
                                    <ReactQuill value={description} onChange={(value) => setDescription(value)} />
                                </div>
                            </div>
                        </div>
                        <div className="create-task-form">
                            <label>
                                Assign to
                            </label>
                            <div className="create-task-input">
                                <FormControl sx={{ backgroundColor: 'white', width: '100%', marginTop: '15px' }}>
                                    <Controller
                                        name="assign_to"
                                        control={control}
                                        rules={{ required: 'Choose user to assign.' }}
                                        defaultValue={""}
                                        render={({ field }) => (
                                            <Select
                                                {...field}
                                                displayEmpty
                                                inputProps={{ 'aria-label': 'Without label' }}
                                                placeholder="Choose user to assign"
                                                open={false}
                                                onClick={onAssignToTarget}
                                            >
                                                <MenuItem value="">
                                                    Choose user to assign
                                                </MenuItem>
                                                {categoryList.map(item => {
                                                    return (
                                                        <MenuItem value={item.value}>
                                                            {item.label}
                                                        </MenuItem>
                                                    )
                                                })}
                                            </Select>
                                        )}
                                    />
                                </FormControl>
                                <ListMenu
                                    targetItem={assigntoTarget}
                                    heading="List of users"
                                    subheading="Select user"
                                    itemList={
                                        categoryList
                                            .map(item => ({
                                                label: item.label,
                                                value: item.value
                                            }))
                                    }
                                    onClick={(_, item) => {
                                        setValue('assign_to', item.value);
                                        trigger('assign_to');
                                    }}
                                    activeItem={{
                                        label: getValues('assign_to'),
                                        value: getValues('assign_to') || ''
                                    }}
                                />
                                {errors?.assign_to && <div className='error'>{errors?.assign_to?.message}</div>}

                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12} style={{ textAlign: 'right', paddingBottom: '50px' }}>
                        <button
                            className="template-button"
                            style={{
                                marginRight: '0',
                                background: "rgba(15, 87, 150, 0.15)",
                                fontSize: "14px", padding: '10px 26px',
                                borderRadius: '15px', color: "#fff",
                                height: 40
                            }}
                            type='submit'
                        >
                            <span style={{ color: "#0F5796" }}>Create</span>
                        </button>
                    </Grid>
                </Grid>
            </form>
        </Template1>
    </div >
    )
}

export default CreateTask