import React, { useState } from "react"
import Header from "../../components/Header"
import "./Proposal.css"
import tag from "../../assets/tag-yellow.png"
import expand from "../../assets/expand.png"
import close from "../../assets/close.png"
import trash from "../../assets/trash.png"
import ReactQuill from "react-quill"
import "react-quill/dist/quill.snow.css"
import mail from "../../assets/mail-black.png"
import phonecall from "../../assets/phone-gray.png"
import address from "../../assets/address-gray.png"
import { useLocation, useNavigate } from 'react-router-dom';
import AppModal from "../../components/Modal"
import { Stack, Typography } from "@mui/material"
import { request } from "../../utils/http"
import { toast } from "react-toastify"

const Details = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [search, setSearch] = useState('')
  const [openModal, setOpenModal] = useState(false);
  const navigate = useNavigate();
  const { state, pathname } = useLocation();
  const business = pathname.split("/")[2];

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  const handleClick = () => {
    navigate(`/create-proposal/${business}`, {
      state: {
        id: state?.proposal?.id,
        title: state?.proposal?.title,
        content: state?.proposal?.body,
        tag: state?.proposal?.tag,
        image: state?.proposal?.image,
        members: state?.proposal?.members
      }
    });
  }

  const handleDelete = () => {
    if (state?.proposal?.id)
      request.delete(`/proposals/api/v1/proposal/${state?.proposal?.id}/`)
        .then(() => {
          toast.success("Proposal deleted successfully.");
          navigate(`/proposals/${business}`);
        })
  }



  return (
    <div className="dashboard-container">
      {/* <SideMenu /> */}
      <div className="main-content">
        <Header title="Proposal Details"
          value={search}
          onChange={e => setSearch(e.target.value)} />

        <div className="horizontal-rule"></div>
        <div className="content" style={{ justifyContent: "space-between" }}>
          {/* Right Column */}
          <div className="right-columnsl" style={{ maxWidth: "540px" }}>

            <div className="proposal-card" style={{ height: 'auto', minHeight: '160px' }}>
              <div className="proposal-content">
                <div style={{ display: "flex", alignItems: "center" }}>
                <div className="proposal-image-container" style={{ height: 50 }}>
                {state?.business?.name && (
                  <div className="thumbnail">
                    {state?.business?.name.charAt(0).toUpperCase()}
                  </div>
                )}
              </div>
                  {/* <div className="proposal-image-container" style={{ height: 50, width: 50 }}>
                    {state?.}
                  </div> */}
                  <div className="text-container">
                    <p className="proposal-card-title" style={{ color: "#6A707E" }}>
                      {state?.business?.name}
                    </p>
                  </div>
                </div>
              </div>
              <div className="proposal-buttonss" style={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr", columnGap: '10px' }}>
                <div>
                  <div style={{ display: "flex", alignItems: "center", columnGap:'10px' }}>
                    <img src={mail} alt={"button"} className="proposal-button-icon" style={{ width: 16, height: 16 }} />
                    <p style={{ color: "#6A707E", fontSize: 12 }}>Business Email</p>
                  </div>
                  <h3
                    style={{
                      color: "#6A707E",
                      fontSize: 16,
                      marginTop: 5,
                      width: "140px",
                      wordBreak: 'break-word',
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis"
                    }}>
                    {state?.business?.email}
                  </h3>
                </div>
                <div>
                  <div style={{ display: "flex", alignItems: "center", columnGap:'10px' }}>
                    <img src={phonecall} alt={"button"} className="proposal-button-icon" style={{ width: 14, height: 16 }} />
                    <p style={{ color: "#6A707E", fontSize: 12 }}>Business Phone</p>
                  </div>
                  <h3
                    style={{
                      color: "#6A707E",
                      fontSize: 16,
                      marginTop: 5,
                      width: "140px",
                      wordBreak: 'break-word',
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis"
                    }}>
                    {state?.business?.phone}
                  </h3>
                </div>
                <div>
                  <div style={{ display: "flex", alignItems: "center", columnGap:'10px' }}>
                    <img src={address} alt={"button"} className="proposal-button-icon" style={{ width: 14, height: 16 }} />
                    <p style={{ color: "#6A707E", fontSize: 12 }}>Business Address</p>
                  </div>
                  <h3
                    style={{
                      color: "#6A707E",
                      fontSize: 16,
                      marginTop: 5,
                      width: "140px",
                      wordBreak: 'break-word',
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis"
                    }}>
                    {state?.business?.address}
                  </h3>
                </div>
              </div>
            </div>

            <div className="proposal-card">
              <div className="proposal-content">
                <Stack direction="row" spacing={2}>
                  <div style={{ display: "flex", width: "100%" }}>
                    {state?.proposal?.image ? (
                      <img
                        src={state?.proposal?.image}
                        alt="template-icon"
                        style={{
                          width: "60px",
                          height: "60px",
                          objectFit: "contain",
                          borderRadius: "6px"
                        }}
                      />
                    ) : (
                      <div className="proposal-image-container" style={{ height: 50 }}>
                      {state?.proposal?.title && (
                        <div className="thumbnail">
                          {state?.proposal?.title.charAt(0).toUpperCase()}
                        </div>
                      )}
                        </div>
                    )}
                    <Typography
                      sx={{
                        marginLeft: "20px",
                        marginTop: "6px",
                        color: "#464255",
                        fontWeight: "500",
                        fontSize: "1.125rem"
                      }}
                    >
                      {state?.proposal?.title}
                    </Typography>
                    <img
                      style={{
                        marginLeft: "auto"
                      }}
                      src={expand}
                      alt="expand"
                      className="card-image"
                      onClick={handleClick}
                    />
                  </div>
                </Stack>
              </div>
              <div className="detail-subtext">
              <Typography
                dangerouslySetInnerHTML={{ __html: `<p>${state?.proposal?.body?.replace(/<[^>]*>/g, '')}</p>` }}
                sx={{
                  height: "40px",
                  maxHeight: "4rem",
                  overflow: 'hidden',
                  lineHeight:'normal',
                  fontSize:'14px',
                  "& p": { margin: "8px 0", fontSize: "14px", color: "#A3A3A3" }
                }}
              />
              </div>
              <div className="proposal-buttons">
                <button className="industry-button">
                  <img
                    src={tag}
                    alt="Industry"
                    style={{ width: 24, height: 18, marginRight: 10 }}
                  />
                  {state?.proposal?.tag}
                </button>
                <button onClick={() => setShowPopup(!showPopup)} className="template-button">Send Proposal</button>
              </div>
            </div>

            {showPopup && (
              <AppModal
                modalIsOpen={showPopup}
                proposal={true}
                values={{
                  id: state?.proposal?.id,
                  title: state?.proposal?.title,
                  body: state?.proposal?.body,
                  tag: state?.proposal?.tag,
                  image: state?.proposal?.image,
                  email: state?.business?.email,
                  handleClick,
                }}
                closeModal={handleClosePopup}
                onSubmit={handleClosePopup}
              />

            )}
          </div>

          <div className="left-proposall" style={{ flex: 1, marginLeft: 40, backgroundColor: "#fff", padding: 20 }}>
            <div className="proposal-content">
              <div style={{ display: "flex" }}>

                <div className="text-container">
                  <p className="proposal-card-title" style={{ marginBottom: 15 }}>
                    Preview
                  </p>
                  <p className="card-description" style={{ marginBottom: 25 }}>
                    Proposal
                  </p>
                </div>
              </div>
              {state?.proposal && <div className="expand-icon">
                <img
                  style={{ height: 26, width: 26 }}
                  onClick={() => {
                    setOpenModal(true);
                  }}
                  src={trash}
                  alt={"item"}
                  className="card-image"
                />
                <img
                  onClick={handleClick}
                  src={expand}
                  alt={"item"}
                  className="card-image"
                />
                <img
                  style={{ height: 22, width: 22 }}
                  onClick={() => {
                    navigate(`/proposals/${business}`);
                  }}
                  src={close}
                  alt={"item"}
                  className="card-image"
                />
              </div>}
            </div>
            <button className="industry-button" style={{ marginBottom: 30 }}>
              <img
                src={tag}
                alt={"item"}
                style={{ width: 24, height: 18, marginRight: 10 }}
              />
              {state?.proposal?.tag}
            </button>
            <p className="proposal-card-title" style={{ fontSize: 24 }}>
              {state?.proposal?.title}
            </p>
            <div className="quill-editor">
              <ReactQuill value={state?.proposal?.body} readOnly />
              <div style={{ display: "flex", justifyContent: "flex-end", marginTop: 30 }}>
                {state?.proposal && <button className="template-button" style={{ marginRight: 0 }} onClick={handleClick}>View Proposal</button>}
              </div>
            </div>
          </div>
        </div>
      </div>
      <AppModal
        modalIsOpen={openModal}
        deleteRecord
        onClick={handleDelete}
        closeModal={() => {
          setOpenModal(false);
        }}
      />
    </div>
  )
}

export default Details
