import React, { useEffect, useState, useRef } from "react"
import { BrowserRouter as Router, Routes, Route } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"

import "./App.css"
import Login from "./screens/Login/Login"

import { setUser } from "./redux/auth/actions"
import StorageUtils from "./utils/storage"
import { addTokenToHttp } from "./utils/http"
import { getProfile } from "./redux/app/actions"
import Home from "./screens/Home/Home"
import Reset from "./screens/Login/Reset"
import SignupChoose from "./screens/Login/SignupChoose"
import SignUp from "./screens/Login/SignUp"
import Onboarding from "./screens/Login/Onboarding"
import Dashboard from "./screens/Dashboard/Dashboard"
import Proposal from "./screens/Proposals/Proposal"
import NewProposal from "./screens/Proposals/NewProposal"
import AddBusiness from "./screens/Dashboard/AddBusiness"
import Contract from "./screens/Contract/Contract"
import Details from "./screens/Proposals/Details"
import CDetails from "./screens/Contract/Details"
import ClientManagement from "./screens/ClientManagement"
import ConfirmPassword from "./screens/Login/ConfirmPassword"
import NewContract from "./screens/Contract/NewContract"
import EditBusiness from "./screens/Dashboard/EditBusiness"
import SideMenu from "./components/SideMenu"

function Navigation() {
  let token = useRef()
  const dispatch = useDispatch()
  const accessToken = useSelector(state => state.Auth.accessToken)
  const isLogggedIn = Boolean(accessToken)
  const [effectCompleted, setEffectCompleted] = useState(false)

  useEffect(() => {
    ; (async () => {
      token.current = await StorageUtils.getAccessToken()
      dispatch(setUser({}, token?.current))
      addTokenToHttp(token?.current)
      if (accessToken) {
        dispatch(getProfile())
      }
      setEffectCompleted(true)
    })()
  }, [accessToken])

  //console.log(accessToken)

  return (
    <Router>
      {isLogggedIn && effectCompleted ? (
        <SideMenu>
          <Routes>
            <Route path="/onboard" element={<Onboarding />} />
            <Route path="/" element={<Dashboard />} />
            <Route path="/proposals/:id" element={<Proposal />} />
            <Route path="/contracts/:id" element={<Contract />} />
            <Route path="/create-proposal/:id" element={<NewProposal />} />
            <Route path="/create-contract/:id" element={<NewContract />} />
            <Route path="/add-business" element={<AddBusiness />} />
            <Route path="/edit-business/:id" element={<EditBusiness />} />
            <Route path="/proposal-details/:id" element={<Details />} />
            <Route path="/contract-details/:id" element={<CDetails />} />
            <Route path="/client/*" element={<ClientManagement />} />
            <Route path="/*" element={<Onboarding />} />
          </Routes>
        </SideMenu>
      ) : (
        effectCompleted && (
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/*" element={<Home />} />
            <Route path="/login" element={<Login />} />
            <Route path="/forgot-password" element={<Reset />} />
            <Route
              path="/users/auth/reset-password"
              element={<ConfirmPassword />}
            />
            <Route path="/signup-choose" element={<SignupChoose />} />
            <Route path="/signup" element={<SignUp />} />
          </Routes>
        )
      )}
    </Router>
  )
}
export default Navigation
