import React from "react"
import "./TitleData.css"

import dot from "../../assets/side-dot.png"
import darkdot from "../../assets/dark-dot.png"
import contract from "../../assets/contract-green.png"
import newbiz from "../../assets/new-red.png"
import proposal from "../../assets/proposal-yellow.png"
import contracticon from "../../assets/contract-icon.png"
import edit from "../../assets/edit.png"
import search from "../../assets/proposal-search.png"
import send from "../../assets/send.png"
import mail from "../../assets/email.png"
import phonecall from "../../assets/phone.png"
import address from "../../assets/address.png"
import moment from "moment"
import { Link } from "react-router-dom"
import { toast } from "react-toastify"
import { request } from "../../utils/http"

function DataLine({
  name,
  sn,
  image,
  email,
  image_url,
  navigate,
  joined,
  description,
  role,
  status,
  published,
  phone,
  location,
  checked,
  isChecked,
  item,
  deleteItem,
  uploadDoc,
  editItem,
  link,
  message,
  state
}) {
  const renderButtonsBasedOnStatus = () => {
    const proposalPresent = async () => {
      try {
        const response = await request.get(
          `https://ezpr-32569-staging.botics.co/proposals/api/v1/proposal/bu/${item.id}/?size=10&is_finished=true`
        )

        return response.data
      } catch (error) {
        console.error("Error fetching proposal:", error)
      }
    }

    const contractPresent = async () => {
      try {
        const response = await request.get(
          `https://ezpr-32569-staging.botics.co/contracts/api/v1/contract/bu/${item.id}/?size=10&is_finished=true`
        )

        return response.data
      } catch (error) {
        console.error("Error fetching proposal:", error)
      }
    }

    const handleSeeProposal = async () => {
      const present = await proposalPresent()

      if (present.count > 0) {
        const val = present.results[0]
        navigate(`/create-proposal/${item.id}`, {
          state: {
            id: val.id,
            title: val.title,
            content: val.body,
            tag: val.tag,
            image: val.image,
            members: val.members
          }
        })
      } else {
        toast.error("Proposal Not Present")
      }
    }

    const handleSeeContract = async () => {
      const present = await contractPresent()

      if (present.count > 0) {
        const val = present.results[0]
        navigate(`/create-contract/${item.id}`, {
          state: {
            id: val.id,
            title: val.title,
            content: val.body,
            tag: val.tag,
            image: val.image,
            members: val.members
          }
        })
      } else {
        toast.error("Contract Not Present")
      }
    }

    const handleSendProposal = async () => {
      const present = await proposalPresent()

      if (present.count > 0) {
        const val = present.results[0]

        navigate(`/proposal-details/${item.id}`, {
          state: {
            business: {
              name: item.name,
              email: item.email,
              phone: item.phone,
              address: `${item.street}, ${item.state}`
            },
            proposal: {
              id: val.id,
              title: val.title,
              body: val.body,
              tag: val.tag,
              image: val.image,
              members: val.members
            }
          }
        })
      } else {
        toast.error("Proposal Not Present")
      }
    }

    const createProposalButton = () => {
      return (
        <div
          className="proposal-button-content"
          onClick={() => navigate(`/proposals/${item.id}`)}
          style={{ cursor: "pointer" }}
        >
          <div className="proposal-button-icon-background">
            <img
              src={contracticon}
              alt={"button"}
              className="proposal-button-icon"
            />
          </div>
          <div className="proposal-button-info">
            <h3 className="button-title">Create Proposal</h3>
            <p className="button-description">Create new proposal</p>
          </div>
        </div>
      )
    }

    const createContractButton = () => {
      return (
        <div
          className="proposal-button-content"
          onClick={() => navigate(`/contracts/${item.id}`)}
          style={{ cursor: "pointer" }}
        >
          <div className="proposal-button-icon-background">
            <img
              src={contracticon}
              alt={"button"}
              className="proposal-button-icon"
            />
          </div>
          <div className="proposal-button-info">
            <h3 className="button-title">Create contract</h3>
            <p className="button-description">Create new proposal</p>
          </div>
        </div>
      )
    }

    const seeProposalButton = () => {
      return (
        <div className="proposal-button-content" style={{ cursor: "pointer" }}>
          <div className="proposal-button-icon-background">
            <img src={search} alt={"button"} className="proposal-button-icon" />
          </div>
          <div
            className="proposal-button-info"
            onClick={() => handleSeeProposal()}
          >
            <h3 className="button-title">See proposal</h3>
            <p className="button-description">See proposal</p>
          </div>
        </div>
      )
    }

    const seeContractButton = () => {
      return (
        <div className="proposal-button-content" style={{ cursor: "pointer" }}>
          <div className="proposal-button-icon-background">
            <img src={search} alt={"button"} className="proposal-button-icon" />
          </div>
          <div
            className="proposal-button-info"
            onClick={() => handleSeeContract()}
          >
            <h3 className="button-title">See contract</h3>
            <p className="button-description">See contract</p>
          </div>
        </div>
      )
    }

    const sendProposalButton = () => {
      return (
        <div
          className="proposal-button-content"
          onClick={() => handleSendProposal()}
          style={{ cursor: "pointer" }}
        >
          {" "}
          <div className="proposal-button-icon-background">
            <img src={send} alt={"button"} className="proposal-button-icon" />
          </div>
          <div className="proposal-button-info">
            <h3 className="button-title">Send proposal</h3>
            <p className="button-description">Send proposal</p>
          </div>
        </div>
      )
    }


    const sendContractButton = () => {
      return (
        <div className="proposal-button-content" style={{ cursor: "pointer" }}>
          <div className="proposal-button-icon-background">
            <img src={send} alt={"button"} className="proposal-button-icon" />
          </div>
          <div className="proposal-button-info">
            <h3 className="button-title">Send Contract</h3>
            <p className="button-description">Send Contract</p>
          </div>
        </div>
      )
    }

    const contractSignedButton = () => {
      return (
        <div className="proposal-button-content" style={{ cursor: "pointer" }}>
          <div className="proposal-button-icon-background">
            <img src={send} alt={"button"} className="proposal-button-icon" />
          </div>
          <div className="proposal-button-info" onClick={uploadDoc}>
            <h3 className="button-title">Contract Signed</h3>
            <p className="button-description">Contract Signed</p>
          </div>
        </div>
      )
    }

    switch (item.status) {
      case "New":
        return (
          <>
            {createProposalButton()}
            {seeProposalButton()}
            {sendProposalButton()}
          </>
        )
      case "Proposal":
        return (
          <>
            {createContractButton()}
            {seeProposalButton()}
            {seeContractButton()}
            {sendContractButton()}
          </>
        )
      case "Contract":
        return (
          <>
            {seeProposalButton()}
            {seeContractButton()}
            {contractSignedButton()}
          </>
        )
      default:
        return null
    }
  }

  return (
    <div
    //style={{color: "#707178", textDecorationLine: "none"}}
    //state={state}
    //to={link}
    >
      <div className="horizontal-rule"></div>
      <div
        style={{
          backgroundColor: "#fff",
          borderLeft: checked === item.id ? "4px solid #0F5796" : null,
          boxShadow:
            checked === item.id ? "0 10px 20px rgba(0, 0, 0, 0.5)" : null
        }}
        className="titleLineBody"
      >
        <div className="checkBox">
          {" "}
          <input
            type="checkbox"
            checked={checked === item.id ? true : false}
            onChange={isChecked}
          />{" "}
        </div>

        <div style={{ width: "26%" }} className="titleBoxData">
          {/** <input type="checkbox" 
          checked={checked === item.id ? true : false}
            onChange={isChecked} />  */}
          <p style={{ marginBottom: 20, marginLeft: 15, width: 180 }}>
            {item.name}
          </p>
        </div>

        <div style={{ width: "30%" }} className="titleBoxData">
          {moment(item.created_at).format("lll")}
        </div>
        <div style={{ width: "30%" }} className="titleBoxData">
          {item.status == "Contract" ? (
            <button
              className="all-button"
              style={{
                cursor: "default",
                height: 30,
                marginBottom: 15,
                color: "green",
                backgroundColor: "#ddfada",
                cursor: "default"
              }}
            >
              <img src={contract} alt="Button 1 Image" />
              Contract
            </button>
          ) : item.status == "New" ? (
            <button
              className="all-button"
              style={{
                cursor: "default",
                backgroundColor: "#Ffccc5",
                color: "#FF5B5B",
                height: 30,
                marginBottom: 15
              }}
            >
              <img src={newbiz} alt="Button 1 Image" />
              New
            </button>
          ) : item.status == "Proposal" ? (
            <button
              className="all-button"
              style={{
                cursor: "default",
                backgroundColor: "#ffe8c6",
                color: "#FFBB54",
                height: 30,
                marginBottom: 15
              }}
            >
              <img src={proposal} alt="Button 1 Image" />
              Proposal
            </button>
          ) : (
            <button
              className="all-button"
              style={{
                cursor: "default",
                backgroundColor: "#Ffccc5",
                color: "#FF5B5B",
                height: 30,
                marginBottom: 15
              }}
            >
              <img src={newbiz} alt="Button 1 Image" />
              New
            </button>
          )}
        </div>

        <div
          style={{}}
          className="titleBoxData"
          onClick={() =>
            navigate(`/edit-business/${item.id}`, { state: { item } })
          }
        >
          {/* <Link>
            <img
              onClick={deleteItem}
              src={dot}
              alt=""
              className="titleIconEdit"
            />
          </Link> */}
          {checked == item.id ? (
            <Link>
              <img
                onClick={deleteItem}
                src={darkdot}
                alt=""
                className="titleIconEdit"
              />
            </Link>
          ) : (
            <Link>
              <img
                onClick={deleteItem}
                src={dot}
                alt=""
                className="titleIconEdit"
              />
            </Link>
          )}
        </div>
      </div>

      {checked === item.id ? (
        <div className="proposal-detail">
          <div className="proposal-detail-header">
            <div className="proposal-buttons-container">
              {renderButtonsBasedOnStatus()}
            </div>
            <button
              className="all-button"
              style={{
                height: 40,
                marginBottom: 15,
                width: 100,
                color: "#0F5796",
                backgroundColor: "#fff"
              }}
              onClick={() =>
                navigate(`/edit-business/${item.id}`, { state: { item } })
              }
            >
              <img src={edit} alt="Button 1 Image" />
              Edit
            </button>
          </div>
          <div className="horizontal-rule"></div>
          <div className="proposal-detail-section">
            <div style={{ display: "flex", alignItems: "center" }}>
              <div className="proposal-image-container" style={{ height: 50 }}>
                {item.name && (
                  <div className="thumbnail">
                    {item.name.charAt(0).toUpperCase()}
                  </div>
                )}
              </div>
              <p
                className="proposal-card-title"
                title= {item.name}
                style={{
                  color: "white",
                  marginBottom: 15,
                  width: "140px",
                  wordBreak: "break-word",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {item.name}
              </p>
            </div>
            <div className="proposal-buttons-container">
              <div className="proposal-parent-container">
                <div className="proposal-buttons-container-inside">
                  <img
                    src={mail}
                    alt={"button"}
                    className="proposal-button-icon"
                    style={{ width: 14, height: 14 }}
                  />
                  <p style={{ color: "white", fontSize: 12 }}>Business Email</p>
                </div>
                <h3
                  title={item.email}
                  style={{
                    color: "white",
                    fontSize: 16,
                    marginTop: 5,
                    width: "140px",
                    wordBreak: "break-word",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis"
                  }}
                >
                  {item.email}
                </h3>
              </div>
              <div className="proposal-parent-container">
                <div className="proposal-buttons-container-inside">
                  <img
                    src={phonecall}
                    alt={"button"}
                    className="proposal-button-icon"
                    style={{ width: 14, height: 14 }}
                  />
                  <p style={{ color: "white", fontSize: 12 }}>Business Phone</p>
                </div>
                <h3 style={{ color: "white", fontSize: 16, marginTop: 5 }}>
                  +{item.phone}
                </h3>
              </div>
              <div className="proposal-parent-container">
                <div className="proposal-buttons-container-inside">
                  <img
                    src={address}
                    alt={"button"}
                    className="proposal-button-icon"
                    style={{ width: 14, height: 14 }}
                  />
                  <p
                    style={{
                      color: "white",
                      fontSize: 12
                    }}
                  >
                    Business Address
                  </p>
                </div>
                <h3

                  title={`${item.street}, ${item.state}`}
                  style={{
                    color: "white",
                    fontSize: 16,
                    marginTop: 5,
                    width: "140px",
                    wordBreak: "break-word",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis"
                  }}
                >
                  {`${item.street}, ${item.state}`}
                </h3>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  )
}
export default DataLine
