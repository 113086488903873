import React from "react"
import { useNavigate } from "react-router-dom"
import { Typography, Button, Box } from "@mui/material"
import { styled } from "@mui/material/styles"
import { ReactComponent as ListIcon } from "../../assets/icon-list.svg"
import { ReactComponent as NewsIcon } from "../../assets/icon-newspaper.svg"
import { ReactComponent as FaqIcon } from "../../assets/icon-faq.svg"
import { ReactComponent as UploadIcon } from "../../assets/icon-upload.svg"

const StyledButton = styled(Button)({
  background: "#0F579626",
  color: "#0F5796",
  borderRadius: "14px",
  // fontWeight:"600",
  padding: "12px 26px"
})

const ExternalDocument = () => {
  const navigate = useNavigate()

  const handleOnClick = page => () => {
    navigate(page)
  }

  return (
    <Box sx={{ overFlow: "auto" }}>
      <Box
        sx={{
          display: "flex",
          gap: "1rem",
          alignItems: "center",
          justifyContent: "space-between"
        }}
      >
        <div>
          <Typography variant="h5" noWrap>
            External Documents
          </Typography>
        </div>
        <div>
          <Box sx={{ display: "flex", gap: "1rem", alignItems: "center" }}>
            <StyledButton
              startIcon={<ListIcon />}
              sx={{ whiteSpace: "nowrap" }}
              onClick={handleOnClick("/client/fact-sheets")}
            >
              Fact sheets
            </StyledButton>
            <StyledButton
              startIcon={<NewsIcon />}
              sx={{ whiteSpace: "nowrap" }}
              onClick={handleOnClick("/client/press-releases")}
            >
              Press releases
            </StyledButton>
            <StyledButton
              startIcon={<FaqIcon />}
              onClick={handleOnClick("/client/faqs")}
            >
              FAQ
            </StyledButton>
            <StyledButton
              startIcon={<UploadIcon />}
              onClick={handleOnClick("/client/upload")}
            >
              Upload
            </StyledButton>
          </Box>
        </div>
      </Box>
    </Box>
  )
}

export default ExternalDocument
