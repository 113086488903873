export const categoryList = [
    {
        value:'1',
        label:'Agenda'
    },
    {
        value:'2',
        label:'Report'
    },
    {
        value:'3',
        label:'Media list'
    },
    {
        value:'4',
        label:'Fact sheet'
    },
    {
        value:'5',
        label:'Press release'
    },
    {
        value:'6',
        label:'FAQs'
    },
]