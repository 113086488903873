import * as actions from "./constants"

const initialState = {
  metrics: {},
  isLoading: false,
  profile: {},
  errors: {},
  feedback: [],
  events: [],
  businesses: [],
  posts: [],
  notification: [],
  userslist: []
}

export const AppReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.APP_GET_USERS_SUCCESS:
      return {
        ...state,
        userslist: action.payload,
        errors: { userslist: null }
      }
    case actions.APP_GET_PROFILE_SUCCESS:
      return { ...state, profile: action.payload, errors: { Profile: null } }
    case actions.APP_GET_PROFILE_ERROR:
      return { ...state, errors: { Profile: action.error } }
    case actions.APP_UPDATE_PROFILE_SUCCESS:
      return { ...state, errors: { Profile: null } }
    case actions.APP_GET_FEEDBACK_SUCCESS:
      return { ...state, feedback: action.payload, errors: { feedback: null } }
    case actions.APP_GET_EVENT_SUCCESS:
      return { ...state, events: action.payload, errors: { events: null } }
    case actions.APP_GET_BUSINESS_SUCCESS:
      return {
        ...state,
        businesses: action.payload,
        isLoading: false,
        errors: { businesses: null }
      }
    case actions.APP_CREATE_BUSINESS_REQUEST:
      return { ...state, isLoading: true, errors: { businesses: null } }
    case actions.APP_CREATE_BUSINESS_ERROR:
      return { ...state, isLoading: false, errors: { businesses: null } }
    case actions.APP_GET_POST_SUCCESS:
      return { ...state, posts: action.payload, errors: { posts: null } }
    case actions.APP_GET_NOTIFICATION_SUCCESS:
      return {
        ...state,
        notification: action.payload,
        errors: { notification: null }
      }
    default:
      return state
  }
}
