import React, { useEffect, useMemo, useRef, useState } from 'react';
import Calendar from '@toast-ui/react-calendar';
import '@toast-ui/calendar/dist/toastui-calendar.min.css';
import './CustomTuiCalendar.css';
import ReportView from './UI/ReportView';
import HorizontalList from './UI/HorizontalList';
import CalendarSelection from './UI/CalendarSelection';
import dayjs from 'dayjs';
import AppModal from '../../../components/Modal';
const YearlyCalendar = ({ events, onGetEvents, onExportToGmailSingleRecord, clientId  }) => {
    const calendarRef = useRef(null);

    const [currentMonth, setCurrentMonth] = useState(null);
    const [currentYear, setCurrentYear] = useState(null);

    const [selectedDateInfo, setSelectedDateInfo] = useState(null);

    const [editReportShow, setEditReportShow] = useState(false);

    const [open, setOpen] = useState(false);
    const [anchor, setAnchor] = useState(null);

    const [openDatepicker, setOpenDatepicker] = useState(false);
    const [value, setValue] = useState(dayjs(new Date()));

    const [selectedEvent, setSelectedEvent] = useState(null);

    const monthNames = [
        'JANUARY',
        'FEBRUARY',
        'MARCH',
        'APRIL',
        'MAY',
        'JUNE',
        'JULY',
        'AUGUST',
        'SEPTEMBER',
        'OCTOBER',
        'NOVEMBER',
        'DECEMBER'
    ]

    const start = 1900;
    const end = 2099;

    const generateYearRange = (start, end) => {
        const years = [];
        for (let year = start; year <= end; year++) {
            years.push(year);
        }
        return years;
    }

    const yearsList = useMemo(() => generateYearRange(start, end), []);

    const onDateSelect = (value) => {
        setValue(value);
        calendarRef.current.getInstance().setDate(value.$d)
        updateYear();
        updateMonth();
        getEvents();
    }

    const displayDate = () => {
        const date = calendarRef?.current?.getInstance()?.getDate()?.d?.d;
        const selectedDate = date && new Date(date);
        setValue(dayjs(selectedDate));
        return selectedDate ? selectedDate.toLocaleString('default', { month: 'short' }) + " " + selectedDate.getFullYear() : null;
    }

    const updateMonth = () => {
        const date = calendarRef?.current?.calendarInstance?.getDate()?.d?.d;
        const month = new Date(date).toLocaleString('default', { month: 'long' }).toUpperCase();
        document.getElementById(month).scrollIntoView({
            behavior: 'smooth',
            block: 'nearest', // or 'start' to align to the top
            inline: 'nearest'
        });
        setCurrentMonth(month);
        setSelectedDateInfo(displayDate());
    }

    const updateYear = (smooth = true) => {
        const date = calendarRef?.current?.calendarInstance?.getDate()?.d?.d;
        const year = new Date(date).getFullYear();
        document.getElementById(year).scrollIntoView({
            behavior: smooth ? 'smooth' : 'instant',
            block: 'nearest', // or 'start' to align to the top
            inline: 'nearest'
        });
        setCurrentYear(year);
        setSelectedDateInfo(displayDate());
    }

    const handleNextMonth = () => {
        calendarRef.current.calendarInstance.next();
        updateMonth();
        getEvents();
    };

    const handlePreviousMonth = () => {
        calendarRef.current.calendarInstance.prev();
        updateMonth();
        getEvents();
    };

    const onExportToGmail = (id) =>{
        setEditReportShow(false);
        onExportToGmailSingleRecord(id);
    }

    const handleNextYear = () => {
        const currentDate = calendarRef?.current?.calendarInstance?.getDate()?.d?.d;
        const year = currentDate.getFullYear();
        if (year == end) {
            return;
        }
        const updatedYear = new Date(
            currentDate.getFullYear() + 1,
            currentDate.getMonth(),
            currentDate.getDate()
        )
        calendarRef.current.calendarInstance.setDate(updatedYear);
        updateYear();
        getEvents();
    };

    const handlePreviousYear = () => {
        const currentDate = calendarRef?.current?.calendarInstance?.getDate()?.d?.d;
        const year = currentDate.getFullYear();
        if (year == start) {
            return;
        }
        const updatedYear = new Date(
            currentDate.getFullYear() - 1,
            currentDate.getMonth(),
            currentDate.getDate()
        )
        calendarRef.current.calendarInstance.setDate(updatedYear);
        updateYear();
        getEvents();
    };


    const template = {
        monthDayName: function (model) {
            let dayName;
            if (model.label === 'Sun') {
                dayName = 'Sunday';
            } else if (model.label === 'Mon') {
                dayName = 'Monday'
            } else if (model.label === 'Tue') {
                dayName = 'Tuesday'
            } else if (model.label === 'Wed') {
                dayName = 'Wednesday'
            } else if (model.label === 'Thu') {
                dayName = 'Thursday'
            } else if (model.label === 'Fri') {
                dayName = 'Friday'
            } else if (model.label === 'Sat') {
                dayName = 'Saturday'
            }
            return dayName.toUpperCase() || ''
        },
        time: function (schedule) {
            // Example template: Display title and custom attribute
            // <div style="font-size:9px;background-color: transparent !important;margin-bottom:5px;overflow:hidden;height:30px;">
            // Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ultricies mauris aenean habitant habitant. Ultricies aliquam magna massa purus sed egestas.
            // </div>
            return `<div style="display:flex;justify-content:space-between;color:#0F5796">
                            <div style="white-space:normal;">${schedule?.title}</div>
                            <div style="margin-right:2px;"></div>
                        </div>
                    `
        },
    }

    const getEvents = () => {
        const calendarInstance = calendarRef.current.getInstance();
        const currentDate = calendarInstance.getDate();
        onGetEvents(currentDate)
    }


    useEffect(() => {
        updateYear(false);
        updateMonth();
        getEvents();
        calendarRef.current.calendarInstance.on('clickEvent', (e) => {
            setAnchor(e.nativeEvent.target)
            setOpen(true);
            setSelectedEvent(e.event);
        });
        return () => {
            calendarRef?.current?.calendarInstance?.off('clickEvent');
        };
    }, [])

    const theme = {
        common: {
            holiday: {
                color: '#0F5796'
            },
            dayName: {
                color: '#0F5796'
            },
            saturday: {
                color: '#0F5796'
            }
        },
        month: {
            weekend: {
                backgroundColor: 'white'
            },
            dayName: {
                backgroundColor: 'white'
            },
            holidayExceptThisMonth: {
                color: 'rgba(51, 51, 51, 0.4)'
            }
        }
    };


    return (<div style={{ height: '1000px' }} className='yearly-report monthly-report container'>
        <CalendarSelection
            setOpenDatepicker={setOpenDatepicker}
            onDateSelect={onDateSelect} value={value}
            selectedDateInfo={selectedDateInfo}
            openDatepicker={openDatepicker} />
        <HorizontalList list={yearsList} currentSelection={currentYear} handleNext={handleNextYear} handlePrevious={handlePreviousYear} />
        <HorizontalList list={monthNames} currentSelection={currentMonth} handleNext={handleNextMonth} handlePrevious={handlePreviousMonth} />
        {open && <ReportView open={open} setOpen={setOpen} anchor={anchor} selectedEvent={selectedEvent} setEditReportShow={setEditReportShow} />}
        <Calendar
            ref={calendarRef}
            events={events}
            height="400px"
            view='month'
            theme={theme}
            template={template}
            isReadOnly={true}
            useDetailPopup={false}
            month={{
                startDayOfWeek: 1
            }}
        />
        <AppModal
            modalIsOpen={editReportShow}
            editReportEvent={editReportShow}
            data={{
                value: selectedEvent,
                clientId: clientId,
                updateEvent: getEvents,
                exportToGmail: onExportToGmail
            }}
            closeModal={() => {
                setEditReportShow(false);
            }}
        />
    </div>)
};

export default YearlyCalendar;
