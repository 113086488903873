import React, { useState } from "react"
import { useNavigate, useSearchParams } from "react-router-dom";
import { confirmNewPassword } from "../../redux/auth/sagas";
import { toast } from "react-toastify"
import eye from "../../assets/eye.png"

const ConfirmPassword = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams()

  const token = searchParams.get('token');
  if(!token){
    navigate("/login")
  }
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [passwordBlur,setPasswordBlur] = useState({
    password:false,
    confirmPassword:false
  });
  const [passwordMatch, setPasswordMatch] = useState(true);



  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword); 
  };
  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword((prevShowPassword) => !prevShowPassword);
  };

  const handleOnPaswordBlur = ()=> {
    if(passwordBlur.confirmPassword){
      password === confirmPassword ? 
      setPasswordMatch(true) : 
      setPasswordMatch(false)  
    }
    setPasswordBlur(p => ({...p,password:true}))
  }

  const handleOnConfirmPasswordBlur = ()=> {
    if(passwordBlur.password){
      password === confirmPassword ? 
      setPasswordMatch(true) : 
      setPasswordMatch(false)  
    }
    setPasswordBlur(p => ({...p,confirmPassword:true}))
  }


  const handleReset = async e => {
    e.preventDefault()

    if(password != confirmPassword){
      return;
    }

    // Handle the login logic here, e.g., submit the login credentials to the server
    const values = {
      password: password,
      token: token
    }
    try{
      await confirmNewPassword(values);
      toast.success("Password reset successfully!")
      navigate("/login");
    }catch(err){
      const error = err?.response?.data?.password?.[0];
      alert(error || "Failed to reset Password");
    }
  }

  return (
    <div className="login-screen">
      <header className="header-login">
      <h1 style={{cursor:"pointer"}} onClick={()=> navigate("/")}>Logo</h1>
      </header>

      <div className="reset-container">
        <form
          onSubmit={handleReset}
          className="login-form"
          style={{ width: 500 }}
        >
          <h2>Password reset</h2>
          <div className="form-group">
          <input
            type={showPassword ? 'text' : 'password'}
            id="password"
            minLength={8}
            value={password}
            onBlur={handleOnPaswordBlur}
            onChange={e => setPassword(e.target.value)}
            placeholder="Enter your password"
            required
          />
          <img
            src={eye}
            className={showPassword ? 'eye-icon eye-icon-grey' : "eye-icon" }
            alt="eye"
            onClick={togglePasswordVisibility}
          />
          </div>
          <div className="form-group">
            <input
              type={showConfirmPassword ? 'text' : 'password'}
              id="confirmPassword"
              minLength={8}
              value={confirmPassword}
              onBlur={handleOnConfirmPasswordBlur}
              onChange={e => setConfirmPassword(e.target.value)}
              placeholder="Confirm password"
              required
            />
            <img
              src={eye}
              className={showConfirmPassword ? 'eye-icon eye-icon-grey' : "eye-icon"}
              alt="eye"
              onClick={toggleConfirmPasswordVisibility}
            />
            {!passwordMatch && <div style={{ color: "red", fontSize: "12px", marginTop: "1px" }}>Password does not match</div>}
          </div>
          <button className="login-button" type="submit">
            Reset
          </button>
        </form>
      </div>
    </div>
  )
}

export default ConfirmPassword
