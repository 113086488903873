import { all, takeLatest } from "redux-saga/effects"
import axios from "axios"
import * as actions from "./constants"
import { getPressReleaseTemplates as getPressReleaseTemplatesAction } from "./actions"
import { BASE_URL, request } from "../../utils/http"
import { sagasRunner } from "../../utils/redux"

function getClients({ search = "" }) {
  return request.get(`/clients/api/v1/clients/?search=${search || ""}`)
}

function handleGetClients(action) {
  return sagasRunner({
    successType: actions.GET_CLIENTS_SUCCESS,
    errorType: actions.GET_CLIENTS_ERROR,
    errorMessage: "Unable to get clients lest.",
    callFunc: getClients,
    callData: action.params || {}
  })
}

function getPressReleaseTemplates({
  limit = 4,
  page = 1,
  client = "",
  search = ""
}) {
  return request.get(
    `/press-releases/api/v1/press-template/client/${client}/?size=${limit}&page=${page}&search=${search}`
  )
}

function handleGetPressReleaseTemplates(action) {
  return sagasRunner({
    successType: actions.GET_PRESS_RELEASE_TEMPLATES_SUCCESS,
    errorType: actions.GET_PRESS_RELEASE_TEMPLATES_ERROR,
    errorMessage: "Unable to get press release templates.",
    callFunc: getPressReleaseTemplates,
    callData: action.params || {}
  })
}

function postPressReleaseTemplates(params) {
  return request.post("/press-releases/api/v1/press-template/", params, {
    headers: { "Content-Type": "multipart/form-data" }
  })
}

function handlePostPressReleaseTemplates(action) {
  return sagasRunner({
    successType: actions.POST_PRESS_RELEASE_TEMPLATES_SUCCESS,
    errorType: actions.POST_PRESS_RELEASE_TEMPLATES_ERROR,
    errorMessage:
      "An error occurred while creating the press release template.",
    callFunc: postPressReleaseTemplates,
    callData: action.params || {}
  })
}

export default all([
  takeLatest(actions.GET_CLIENTS, handleGetClients),
  takeLatest(
    actions.GET_PRESS_RELEASE_TEMPLATES,
    handleGetPressReleaseTemplates
  ),
  takeLatest(
    actions.POST_PRESS_RELEASE_TEMPLATES,
    handlePostPressReleaseTemplates
  )
])
