import { Chip } from "@mui/material"
import React from "react"

const LabelButton = ({
  icon,
  label,
  onClick,
  bgColor = "rgba(15, 87, 150, 0.15)",
  color = "#0F5796",
  clickable = true
}) => {
  return (
    <Chip
      icon={icon}
      label={label}
      onClick={onClick}
      clickable={clickable}
      variant="filled"
      sx={{
        color,
        fontWeight: "500",
        fontSize: "1rem",
        background: bgColor,
        borderRadius: "10px",
        padding: "0.75rem 1rem",
        height: "3rem",
        justifyContent: "flex-start",
        gap: "0.75rem",

        ".MuiChip-label": {
          fontWeight: "500"
        }
      }}
    />
  )
}

export default LabelButton
