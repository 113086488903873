import React from 'react';
import { Unstable_Popup as BasePopup } from '@mui/base/Unstable_Popup';
import { ClickAwayListener } from '@mui/material';
import close from "../../../../assets/bi_x-circle.png"
import edit from "../../../../assets/edit_unfilled.png"
import calendar from "../../../../assets/calendar_icon.png"
import { styled } from '@mui/system';
import moment from 'moment';
import { ReportStatus } from '../../ReportsConst';

const ReportView = ({ open, setOpen, anchor, selectedEvent, setEditReportShow }) => {

    const PopupBody = styled('div')(
        ({ theme }) => `
        width: 350px;
        height: max-content;
        padding: 12px 16px;
        margin: 8px;
        margin-top: 0px;
        margin-bottom:0px;
        background-color:white;
        border-radius: 8px;
        box-shadow: ${theme.palette.mode === 'dark'
                ? `0px 4px 8px rgb(0 0 0 / 0.7)`
                : `0px 4px 8px rgb(0 0 0 / 0.1)`
            };
        font-family: 'IBM Plex Sans', sans-serif;
        font-size: 0.875rem;
        z-index: 1;
      `,
    );

    const onClickAway = () => {
        setOpen(false);
    }

    const startDateFormatted = selectedEvent ? moment(selectedEvent.start.toDate()).format("dddd, D MMMM YYYY | HH:mm") : '';
    const endDateFormatted = selectedEvent ? moment(selectedEvent.end.toDate()).format("HH:mm") : '';

    return (
        <div className='report-view'>
            <ClickAwayListener onClickAway={onClickAway}>
                <BasePopup id="schedule" style={{zIndex:'2'}} open={open} anchor={anchor} placement='bottom-start' modifiers={{ name: 'arrow', enable: true, options: { element: anchor } }} >
                    {/* <div className="arrow arrow-up"></div>
    <div className="arrow arrow-down"></div> */}
                    <PopupBody>
                        <div style={{ textAlign: 'right', cursor: 'pointer' }}>
                            <img src={close} width="16px" height="16px" onClick={() => setOpen(false)} />
                        </div>
                        <div style={{ display: 'flex', columnGap: '15px' }}>
                            <img src={calendar} width="38px" height="38px" />
                            <div>
                                <div style={{ color: 'rgba(15, 87, 150, 1)', fontSize: "16px", fontWeight: 'bold' }}>{selectedEvent?.title}</div>
                                <div style={{ fontSize: '14px', color: 'rgba(101, 101, 101, 1)', marginTop: '5px' }}>{startDateFormatted} - {endDateFormatted}</div>
                            </div>
                        </div>
                        <div style={{ marginTop: "25px", fontSize: '12px' }}>
                            {selectedEvent?.body?.description?.replace(/<[^>]*>/g, '')}
                        </div>
                        <div style={{ marginTop: "25px", display: "flex", justifyContent: 'space-between' }}>
                            <div>
                                <span style={{ display: 'inline-block', width: '12px', height: '12px', backgroundColor: selectedEvent?.body?.status === ReportStatus.Uncovered ? '#EC5453' : '#28C66F', borderRadius: '50%' }}></span>
                                <span style={{ fontSize: '16px', marginLeft: '10px' }}>{selectedEvent?.body?.status === ReportStatus.Covered ? 'Covered' : 'Uncovered'}</span>
                            </div>
                            <div style={{ cursor: 'pointer' }} onClick={() => {
                                setEditReportShow(true);
                                setOpen(false);
                            }}>
                                <img src={edit} width="18px" height="18px" />
                                <span style={{ fontSize: '16px', color: '#0F5796', marginLeft: '10px' }}>Edit</span>
                            </div>
                        </div>
                    </PopupBody>
                </BasePopup>
            </ClickAwayListener>
        </div>
    )
}

export default ReportView