import React, { useEffect, useState } from "react";
import OnboardingTabs from "../OnboardingTabs";
import { Grid } from "@mui/material";
import Box from '@mui/material/Box';
import '../ClientManagement.scss';
import { request } from "../../../utils/http";
import {toast} from 'react-toastify';
import AppModal from "../../../components/Modal";
import { useNavigate } from "react-router-dom";
const OnboardingClient = ({ selectedClient }) => {

    const navigate = useNavigate();

    const [campaignName, setCampaignName] = useState('');
    const [onboradingFormStatus, setOnboardingFormStatus] = useState(false);
    const [formCreated, setFormCreated] = useState(false);
    const [sendFormOpen, setSendFormOpen] = useState(false)
    const [viewFormOpen, setViewFormOpen] = useState(false)
    const [onBoardingFormDetails, setOnboardingFormDetails] = useState("");

    const handleSave = () => {
        request
        .post("/clients/api/v1/campaign/", {
            client: selectedClient.id,
            name:campaignName
        })
        .then((_) => {
            toast.success("Client onboarding details updated successfully.")
        })
        .catch((err) => {
            toast.error("Failed to updated campaign.")
        })  
    }

    useEffect(()=>{
        request
        .get("/clients/api/v1/campaign/", {
          params: {
            client_id: selectedClient.id,
          }
        })
        .then(({ data }) => {
            setCampaignName(data.campaign || '');
        })
        .catch((err) => {
            console.log(err)
        })  

        request
        .get(`/clients/api/v1/clients/on-boarding/questioner/${selectedClient.id}/`)
        .then(({ data }) => {
            const status = Array.isArray(data) && !!data?.find(d => d?.is_finished);
            setOnboardingFormStatus(status);
            setFormCreated(!!data?.[0])
            updatedQuillDetails(data)
        })
        .catch((err) => {
            console.log(err)
        })  
    },[selectedClient.id]);

    const updatedQuillDetails = (data) =>{
        if(data){
            const quillData = data.map( (record,index) =>{
                const question = (record.questions || '');
                const answer = (record.answer || '');
                const no = index +1;
                return `
                    <div>   
                        <div><strong>${no}. ${question}</strong></div>
                        <div><span style='color:#6A707E;'>${answer}</span></div>
                    </div>
                `;
            }).join('</br>');
            const formattedQuillData = "</br>"+quillData+"</br></br>";
            setOnboardingFormDetails(formattedQuillData);
        }
    }
    const onViewForm = () =>{
        //Implement logic for view form here
        setViewFormOpen(true);
    }

    const onSendForm = () =>{
        //Implement logic for send form here
        setSendFormOpen(true);
    }

    const onViewFormInNewPage = () =>{
        setViewFormOpen(false);
        navigate("/client/view-onboarding-form/"+selectedClient.id)
    }

    return (
        <div className="content" style={{ borderTop: '0.5px solid #AAAAAA' }}>
            <OnboardingTabs 
            onViewForm={onViewForm}
            onSendForm={onSendForm}
            clientId={selectedClient.id} 
            formCreated={formCreated}
            status={onboradingFormStatus}/>
            <div className="right-columns">
                <div className="business-management-header">
                    <Grid container spacing={2}>
                        <Grid item xs={4}>
                            <div className="header-content" style={{ marginBottom: 16 }}>
                                <div style={{ fontWeight: '500', fontSize:'20px' }}>Onboarding the Client</div>
                                <div
                                    style={{
                                        color: "#A3A3A3",
                                        fontSize: "14px",
                                        marginTop: 16
                                    }}
                                >
                                    See details
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={8}>
                            <div className="onboarding-client-form">
                                <label>
                                    Client name
                                </label>
                                <input
                                    type="text"
                                    value={selectedClient.name}
                                    disabled
                                    placeholder="Client name"
                                />
                            </div>
                            <div className="onboarding-client-form">
                                <label>
                                    Campaign name (Optional)
                                </label>
                                <input
                                    type="text"
                                    value={campaignName}
                                    onChange={(e) => setCampaignName(e.target.value)}
                                    placeholder="Campaign name"
                                />
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <button style={{marginRight:'-40px'}} onClick={() => handleSave()} className="btn-finish">
                                    Save
                                </button>
                            </Box>
                        </Grid>
                    </Grid>
                </div>
            </div>
            <AppModal
                modalIsOpen={sendFormOpen || viewFormOpen}
                sendOnboardingForm={sendFormOpen}
                viewOnboardingForm={viewFormOpen}
                data={{
                    onboardingFormComplete:onboradingFormStatus,
                    onBoardingFormDetails:onBoardingFormDetails,
                    onViewForm:onViewFormInNewPage,
                }}
                values={{
                    email:selectedClient.email,
                    id:selectedClient.id
                }}
                closeModal={() => {
                    setSendFormOpen(false);
                    setViewFormOpen(false);
                }}
            />
        </div>
    )
}

export default OnboardingClient;