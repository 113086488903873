import React, { useEffect, useState } from 'react'
import "./ClientStylesheet.css"
import plus from "../../assets/plus-blue.png"
import Pagination from "../../components/Pagination/Pagination"
import expand from "../../assets/verticaldots.png"
import { useLocation, useNavigate } from 'react-router-dom'
import ReactQuill from "react-quill"
import { request } from '../../utils/http'
import { Grid, TextField, Typography } from '@mui/material'
import Select from 'react-select'

import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { toast } from "react-toastify"

const CreateNewAgenda = () => {
    const [agendas, setAgendas] = useState([]);
    const [options, setOptions] = useState([]);
    const [campaignName, setCampaignName] = useState('');
    const [title, setTitle] = useState('');
    const [content, setContent] = useState('');
    // const [notes, setNotes] = useState('')
    const [date, setDate] = useState(dayjs('2022-04-07'));

    const location = useLocation()
    const selectedClient =  location?.state?.selectedClient
    

    const [clientsCount, setClientsCount] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)

    const navigate = useNavigate();

    console.log("SELECTED CLIENT - ", selectedClient)
  
  
    useEffect(()=>{
      const fetchData = async () => {
        try {
  
          const data = await request.get(
            `https://ezpr-32569-staging.botics.co/agendas/api/v1/agenda/client/${selectedClient?.id}/`,
            {
              params: {
                page: currentPage,
                size: 2
              }
            }
          );
          
          setClientsCount(data.data.count)
          setAgendas(data.data.results);
          console.log("agenda - ", data.data.results)
       
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };
  
    
      fetchData();
    },[currentPage, selectedClient])


    useEffect(()=>{

        const datafetch = async()=>{
        
            const response = await request.get('https://ezpr-32569-staging.botics.co/agendas/api/v1/agenda/campaign/')

            const formattedOptions = response.data.map(option => ({
                label: option,
                value: option,
            }));

            setOptions(formattedOptions);

        }
        
        datafetch()

    },[])

    


    const handleSubmit = async () =>{

        await request.post('https://ezpr-32569-staging.botics.co/agendas/api/v1/agenda/', {
            campaign_name : campaignName,
            meeting_title: title,
            meeting_date_and_time : date.format(),
            primary_notes: content,
            client: selectedClient.id
        })
        .then(()=>toast.success(`Created successfully.`))
        .catch((err)=> toast.error("Failed"))

        setCampaignName('')
        setTitle('')
        setDate(dayjs('2022-04-07'))
        setContent('')

    }

    const handleClick = (item) => {
        navigate("/client/meeting-agenda", {
            state: {item, selectedClient}
        })
    }

    // const handleNotes = value => {
    //     setNotes(value)
    // }

    // const handleNoteSave = () => {
    //     // if (state.id)
    //     //   request.post("/proposals/api/v1/proposal-notes/", {
    //     //     proposal: state.id,
    //     //     body: notes
    //     //   })
    //     //     .then(() => {
    //     //       toast.success("Note is now saved.");
    //     //     });
    //     // else
    //     //   toast.error("The proposal is not saved/finished yet");
    //   }



  return (
    <div className='cover-div'>

        <div className='agenda-section-one' style={{marginRight:"1vw"}}>Create New Agenda</div>

        <div className='agenda-section-two'>
            <div style={{height:"100%", position:"relative",top:"-1.5rem"}}>
                <div style={{marginBottom:"1rem"}}>Client Name</div>
                <input placeholder='Client name' value={selectedClient?.name} disabled style={{width:"35vw", height:"10px", padding:"15px 20px"}}/>

                <div style={{marginBottom:"1rem", marginTop:"1rem"}}>Campaign Name</div>
                <Select
                    value={options.find(option => option.value === campaignName)}
                    isSearchable={true}
                    options={options}
                    onChange={selectedOption => {
                        setCampaignName(selectedOption.value)
                    }}
                />

                <div style={{marginBottom:"1rem", marginTop:"1rem"}}>Meeting Title</div>
                <input placeholder='Add Title' value={title} onChange={(e) => setTitle(e.target.value)} style={{width:"35vw", height:"10px", padding:"15px 20px"}}/>

                <div style={{marginBottom:"1rem", marginTop:"1rem"}}>Meeting Date and time</div>
               
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateTimePicker
                    renderInput={(props) => <TextField {...props} />}
             
                    value={date}
                    onChange={(newValue) => {
                        setDate(newValue);
                    }}
                />
                </LocalizationProvider>

                <div style={{marginBottom:"1rem", marginTop:"1rem"}}>Notes</div>
                <div className="quill-editor quill-editor-notes" style={{ maxHeight: "20vh" }}>
                    <ReactQuill value={content} onChange={(val) => {
                        const cleanedValue = val.replace(/<\/?p>/g, '');
                        setContent(cleanedValue)
                        }} />
                </div>

                
                

                <div className='create-agenda-button' style={{display: 'flex', justifyContent: 'flex-end'}}>
                    <button style={{fontWeight:"600", marginRight:"0", marginTop:"2"}} className="template-button" onClick={() => handleSubmit()}>
                        Save
                    </button>
                </div>

            </div>

            
        </div>

        <div className='notes-section-three' style={{fontSize:"20px", marginLeft:"2vw"}}>
            <div style={{marginBottom:"2rem"}}>
                Meeting Notes
            </div>
            
            <div style={{ backgroundColor: "#fff", borderRadius: 10 }}>
                <div style={{ padding:"1.5rem", display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                    <h3 style={{ color: "#0F5796", fontSize: 24, marginTop: 5, marginBottom: 5 }}>Notes</h3>
                    <img
                        src={plus}
                        alt="Notification Icon"
                        style={{ width: 18, height: 18, marginRight: 10, cursor:"pointer" }}
                    />
                </div>
                <p style={{ margin:0, fontSize: 14, color: "#A3A3A3", padding:"0rem 1.5rem" }}>Maiores dicta atque dolore</p>


                <Grid spacing={2} >
                    {agendas.map((item, index) => (
                        <Grid key={item.id} item xs={12} sm={6} md={6} lg={6}>
                        <div className={`proposal-card ${index % 2 === 0 ? 'odd-border' : ''}`}>
                            <div className="proposal-content">
                            <div style={{ display: "flex", flexDirection:"space-between", alignItems: "center", width: "100%" }}>
                                <Typography
                                sx={{
                                    fontSize: "20px",
                                }}
                                >
                                {item.campaign_name}
                                </Typography>
                                <img
                                style={{
                                    marginLeft: "auto",
                                    cursor:"pointer"
                                }}
                                src={expand}
                                alt="expand"
                        
                                onClick={() => handleClick(item)}
                                />
                            </div>
                            </div>
                            <div style={{fontSize:"12px",color: "#A3A3A3"}}>
                                {item.meeting_date_and_time}
                            </div>
                
                            <div style={{marginTop:"1.2rem", fontSize:"20px"}}>
                                {item.meeting_title}
                            </div>
                            <div className='primaryNotes' style={{fontSize:"15px", marginTop:"5px",width:"200px",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis"}}>
                            {item.primary_notes}
                            </div>
                            <button className="template-button" style={{marginTop:"1rem",width:"30%"}} onClick={() => handleClick(item)}>
                                View
                            </button>
                        </div>
                        </Grid>
                    ))}
                </Grid>

            </div>    

            <Pagination
                currentPage={currentPage}
                totalCount={clientsCount}
                totalPages={Math.ceil(clientsCount / 2 || 0)}
                pageSize={2}
                hideTotalCount={true}
                onPageChange={page => setCurrentPage(page)}
            />

        </div>
        <div style={{width:"10vw"}}></div>
    
    </div>
  )
}

export default CreateNewAgenda