import * as actions from "./constants"

export const getClients = params => ({
  type: actions.GET_CLIENTS,
  params
})

export const getPressReleaseTemplates = params => ({
  type: actions.GET_PRESS_RELEASE_TEMPLATES,
  params
})

export const postPressReleaseTemplates = params => ({
  type: actions.POST_PRESS_RELEASE_TEMPLATES,
  params
})

export const postPressReleaseTemplatesSuccess = params => ({
  type: actions.POST_PRESS_RELEASE_TEMPLATES_SUCCESS,
  payload: params
})
