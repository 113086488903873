import React, { useEffect, useMemo, useState } from "react"
import Header from "../../components/Header"
import "./Proposal.css"
import plus from "../../assets/plus-blue.png"
import download from "../../assets/download.png"
import expand from "../../assets/expand.png"
import searchicon from "../../assets/search-icon.png"
import ReactQuill from "react-quill"
import "react-quill/dist/quill.snow.css"
import Pagination from "../../components/Pagination/Pagination"
import { useLocation, useNavigate } from "react-router-dom"
import { request } from "../../utils/http"
import { toast } from "react-toastify"
import { Box, Checkbox, DialogTitle, FormControl, FormHelperText, Grid, Paper, Popover, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material"
import DropDownMenu from "../../components/DropDownMenu"

const NewProposal = () => {
  const navigate = useNavigate();
  const { pathname, state } = useLocation();
  const [currentPage, setCurrentPage] = useState(1)
  const [pageSize, setPageSize] = useState(3);
  const [title, setTitle] = useState("");
  const [content, setContent] = useState('');
  const [targetItem, setTargetItem] = useState(null);
  const [selectedItem, setSelectedItem] = useState('');
  const [proposals, setProposals] = useState([]);
  const [proposalCount, setProposalCount] = useState(0);
  const [search, setSearch] = useState('')
  const [notes, setNotes] = useState('')
  const [errors, setErrors] = useState({})
  const [users, setUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [tab, setTab] = useState("finished");
  const [selectedImage, setSelectedImage] = useState(null);
  const [trigger, setTrigger] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openDialog, setOpenDialog] = useState(false)
  const business = pathname.split("/")[2];

  const [isLoading, setIsLoading] = useState(false);
  const hasRequiredFields = useMemo(() => {
    return title && content && selectedItem
  }, [title, content, selectedItem])

  useEffect(() => {
    if (state) {
      request.get(`/proposals/api/v1/proposal/bu/${business}/`, {
        params: {
          is_finished: tab === "finished",
          page: currentPage,
          search: search,
          size: pageSize
        }
      })
        .then(({ data }) => {
          setProposals(data.results);
          setProposalCount(data.count);
        })
        .catch(() => {
          setCurrentPage(1);
        })
    }
    else {
      request.get("/proposals/api/v1/proposal-template/", {
        params: {
          page: currentPage,
          search: search,
          size: pageSize
        }
      })
        .then(({ data }) => {
          setProposals(data.results);
          setProposalCount(data.count);
        })
        .catch(() => {
          setCurrentPage(1);
        })
    }
  }, [state, currentPage, pageSize, search, tab, business]);

  useEffect(() => {
    if (state) {
      request.get("/api/v1/profile/")
        .then(({ data }) => {
          setUsers(data?.results);
        });
      if (state.id)
        request.get("/proposals/api/v1/proposal-notes/", {
          params: {
            proposal: state.id
          }
        })
          .then(({ data }) => {
            setNotes(data.body);
          });

      setNotes("");
      setSelectedUsers(state.members?.length ? state.members : []);
      setTitle(state.title);
      setContent(state.content);
      setSelectedItem(state.tag);
      setSelectedImage(state.image || "");
    }
    else {
      setTitle("");
      setContent("");
      setSelectedItem("");
      setSelectedImage("");
    }
  }, [state]);

  const handleSave = () => {
    const postData = new FormData();
    postData.append("title", title);
    postData.append("body", content);
    if (selectedImage && typeof selectedImage === "string")
      postData.append("image_url", "media" + selectedImage.split("media")[2]?.split("?")[0]);
    else if (selectedImage)
      postData.append("image", selectedImage);
    postData.append("tag", selectedItem);
    postData.append("is_finished", false);
    postData.append("business", business);
    selectedUsers.forEach(user => {
      postData.append("members", user);
    });

    if (state.id) {
      request.patch(`/proposals/api/v1/proposal/${state.id}/`, postData, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      })
        .then(() => {
          setTrigger(!trigger);
          toast.success("Proposal saved successfully.")
        });
    }
    else {
      request.post("/proposals/api/v1/proposal/", postData, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      })
        .then(() => {
          setTrigger(!trigger);
          toast.success("Proposal saved successfully.")
        });
    }
  }

  const handleUser = (id) => () => {
    if (selectedUsers.includes(id))
      setSelectedUsers(selectedUsers.filter(x => x !== id));
    else
      setSelectedUsers([...selectedUsers, id])
  }

  const handleTitleChange = e => {
    setTitle(e.target.value)
  }

  const handleNotes = value => {
    setNotes(value)
  }

  const handleNoteSave = () => {
    if (state.id)
      request.post("/proposals/api/v1/proposal-notes/", {
        proposal: state.id,
        body: notes
      })
        .then(() => {
          toast.success("Note is now saved.");
        });
    else
      toast.error("The proposal is not saved/finished yet");
  }

  const handleFinish = () => {
    setIsLoading(true);

    const handleFinish = (id, image) => {
      setTrigger(!trigger);
      request.get(`/businesses/api/v1/business/${business}/`)
        .then(({ data }) => {
          toast.success("Proposal finished successfully.");
          setIsLoading(false);
          navigate(`/proposal-details/${business}`, {
            state: {
              business: {
                name: data.name,
                email: data.email,
                phone: data.phone,
                address: `${data.street}, ${data.state}`
              },
              proposal: {
                id: id,
                title: title,
                body: content,
                tag: selectedItem,
                image: image,
                members: selectedUsers
              }
            }
          });
        });
    }

    if (state) {
      const postData = new FormData();
      postData.append("title", title);
      postData.append("body", content);
      if (selectedImage && typeof selectedImage === "string")
        postData.append("image_url", "media" + selectedImage.split("media")[2]?.split("?")[0]);
      else if (selectedImage)
        postData.append("image", selectedImage);
      postData.append("tag", selectedItem);
      postData.append("is_finished", true);
      postData.append("business", business);
      selectedUsers.forEach(user => {
        postData.append("members", user);
      });

      if (state.id) {
        request.patch(`/proposals/api/v1/proposal/${state.id}/`, postData, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        })
          .then(({ data: { id, image } }) => {
            handleFinish(id, image);
          });
      }
      else {
        request.post("/proposals/api/v1/proposal/", postData, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        })
          .then(({ data: { id, image } }) => {
            handleFinish(id, image);
          });
      }
    }
    else {
      const postData = new FormData()
      if (selectedImage)
        postData.append("image", selectedImage)
      postData.append("title", title)
      postData.append("body", content)
      postData.append("tag", selectedItem)

      request.post("/proposals/api/v1/proposal-template/", postData, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      })
        .then(() => {
          setIsLoading(false);
          setTrigger(!trigger);
          toast.success("Proposal template finished successfully.")
          navigate(`/proposals/${business}`);
        });
    }
  }

  const toggleDropdown = ({ currentTarget }) => {
    setTargetItem({ currentTarget });
  }

  const handleChange = (value) => {
    setContent(value);
  };

  const handleSelectImage = e => {
    setErrors(prev => ({ ...prev, image: "" }))
    if (e.target.files[0].size > 1024 * 1024 * 5) {
      setErrors(prev => ({
        ...prev,
        image: "Image size should be less than 5MB."
      }))
      setSelectedImage("")
      return
    }
    setSelectedImage(e.target.files[0])
  }

  const handleClick = (item) => () => {
    navigate(`/create-proposal/${business}`, {
      state: {
        id: state && item.id,
        title: item.title,
        content: item.body,
        tag: item.tag,
        image: item.image,
        members: state && item.members
      }
    });
  }

  return (
    <div className="dashboard-container">
      {/* <SideMenu /> */}
      <div className="main-content">
        <Header title="Proposal Templates" />

        <div className="horizontal-rule"></div>
        <div className="create-content">
          <div className="create-left-proposal" >
            <div className="proposal-content" >
              <div style={{ display: "flex" }}>

                <div className="text-container">
                  <p className="proposal-card-title" style={{ marginBottom: 15, fontSize: 24 }}>
                    Create New
                  </p>
                  <p className="card-description" style={{ marginBottom: 25 }}>
                    Template
                  </p>
                </div>
              </div>
              <div>
                {state && <button
                  className="template-button"
                  style={{
                    backgroundColor: "#0F5796",
                    color: "#fff",
                    padding: "0 30px",
                    height: 40,
                    marginBottom: 40
                  }}
                  onClick={(event) => {
                    setAnchorEl(event.currentTarget);
                    setOpenDialog(true)
                  }}
                >
                  Assign
                </button>}
                {state && <button
                  className="template-button"
                  style={{
                    backgroundColor: hasRequiredFields && !isLoading ? "#0F5796" : "#CDCDCD",
                    color: "#fff",
                    padding: "0 30px",
                    height: 40,
                    marginBottom: 40
                  }}
                  disabled={!hasRequiredFields || isLoading}
                  onClick={handleSave}>
                  Save
                </button>}
                <button
                  className="template-button"
                  style={{
                    backgroundColor: hasRequiredFields && !isLoading ? "#0F5796" : "#CDCDCD",
                    color: "#fff",
                    padding: "0 30px",
                    height: 40,
                    marginBottom: 40
                  }}
                  disabled={!hasRequiredFields || isLoading}
                  onClick={handleFinish}>
                  Finish
                </button>
              </div>
            </div>
            <Popover
              open={openDialog}
              anchorEl={anchorEl}
              onClose={() => setOpenDialog(false)}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
            >
              <DialogTitle className="agents-title">
                Staff members({users.length})
                <div>Maiores dicta atque dolorem temporibus</div>
              </DialogTitle>
              {users.map(user =>
                <Grid key={user.id} container className="agent-grid">
                  <Grid item xs={2}>
                    <img
                      className="agent-photo"
                      src={user.profile_image}
                      alt="agent"
                    >
                    </img>
                  </Grid>
                  <Grid item xs={8} className="agent-details">
                    <div>
                      {user.user.name}
                    </div>
                    <div>
                      {user.bio}
                    </div>
                  </Grid>
                  <Grid item xs={2}>
                    <Checkbox checked={selectedUsers.includes(user.user.id)} onClick={handleUser(user.user.id)} />
                  </Grid>
                </Grid>
              )}
            </Popover>
            <div style={{ backgroundColor: "#fff", padding: 40, }}>
              <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", }}>
                <p className="proposal-card-title" style={{ fontSize: 16 }}>
                  Template name:
                </p>
                <div className="search-dropdown">
                  <div className="input-container">
                    <input
                      value={title}
                      type="text"
                      placeholder="Text input"
                      onChange={handleTitleChange}
                    />
                    <button style={{ borderRadius: "4px" }} className="plus-button" onClick={toggleDropdown}>
                      <img src={plus} style={{ width: 14, height: 14, marginRight: 5 }} alt="Button 1" />
                      Add industry
                    </button>
                    <Box sx={{ width: "60%", minWidth: "max-content" }}>
                      <DropDownMenu
                        hideThis
                        targetItem={targetItem}
                        itemList={
                          ["Industry 1", "Industry 2", "Industry 3"]
                            .map(item => ({
                              label: item,
                              value: item
                            }))}
                        onClick={(_, item) => setSelectedItem(item.value)}
                        activeItem={{
                          label: selectedItem,
                          value: selectedItem
                        }}
                      />
                    </Box>
                  </div>
                </div>
              </div>
              <Box sx={{
                padding: "14px 0",
                display: "flex",
                gap: "1rem",
                justifyContent: "space-between"
              }}>
                <p className="proposal-card-title" style={{ fontSize: 16, marginTop: 16 }}>
                  Template Image:
                </p>
                <Box sx={{ display: "flex", width: "500px", justifyContent: "left", marginRight: "20px" }}>
                  <FormControl>
                    <img
                      onClick={() => {
                        document.getElementById("file-selector").click();
                      }}
                      style={{
                        cursor: "pointer",
                        height: 48,
                        width: 48
                      }}
                      src={download}
                      alt={"item"}
                    />
                    <input
                      id="file-selector"
                      style={{ display: "none" }}
                      type="file"
                      onChange={handleSelectImage}
                    />
                    <FormHelperText error>{errors?.image}</FormHelperText>
                  </FormControl>
                  {selectedImage && (
                    <img
                      alt="not found"
                      style={{
                        border: "1px solid #ccc",
                        height: 47,
                        width: 47,
                        borderRadius: "8px",
                        objectFit: "contain",
                        marginLeft: "20px"
                      }}
                      src={typeof selectedImage === "string" ? selectedImage : URL.createObjectURL(selectedImage)}
                    />
                  )}
                </Box>
              </Box>
              <div className="quill-editor">
                <ReactQuill value={content} onChange={handleChange} />
              </div>
            </div>
          </div>
          {/* Right Column */}
          <div className="create-right-column">
            <div style={{ display: "flex", marginBottom: 20 }}>
              <div
                style={{
                  flex: 2,

                  display: "flex",
                  alignItems: "center",
                  position: "relative"
                }}
              >
                <input
                  type="text"
                  value={search}
                  onChange={e => setSearch(e.target.value)}
                  placeholder="Search..."
                  style={{
                    width: "100%",
                    height: 26,
                    padding: "8px 30px 8px 8px",
                    border: "none",
                    borderRadius: "10px",
                    backgroundColor: "#F0F0F0"
                  }}
                />
                <img
                  src={searchicon}
                  alt="Search Icon"
                  style={{
                    position: "absolute",
                    width: 20,
                    height: 20,
                    top: "50%",
                    right: "10px",
                    transform: "translateY(-50%)",
                    cursor: "pointer"
                  }}
                />
              </div>
            </div>
            {state && <div>
              <div style={{ backgroundColor: "#fff", padding: 20, borderRadius: 10 }}>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                  <h3 style={{ color: "#0F5796", fontSize: 24, marginTop: 5, marginBottom: 5 }}>Notes</h3>
                </div>
                <p style={{ marginTop: 5, fontSize: 14, color: "#A3A3A3" }}>Maiores dicta atque dolore</p>
              </div>
              <div className="quill-editor quill-editor-notes" style={{ marginBottom: 32, maxHeight: "20vh" }}>
                <ReactQuill value={notes} onChange={handleNotes} style={{ maxHeight: "20vh" }} />
              </div>
              <div style={{ marginBottom: 20, marginTop: 20, display: "flex", justifyContent: "end" }}>
                <button className="template-button" style={{ fontWeight: "bold" }} onClick={handleNoteSave}>Save</button>
              </div>
            </div>}
            {state && <div style={{ backgroundColor: "#fff", padding: 20, borderRadius: 10 }}>
              <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                <h3 style={{ color: "#0F5796", fontSize: 24, marginTop: 5, marginBottom: 5 }}>Proposal</h3>
                <img src={plus} alt={"button"} className="proposal-button-icon"
                  onClick={() => {
                    navigate(`/create-proposal/${business}`);
                  }}
                  style={{ cursor: "pointer", width: 24, height: 24 }} />
              </div>
              <p style={{
                marginTop: 16, fontSize: 16, color: "#A3A3A3"
              }}>Add new template</p>
            </div>}
            {state && <div style={{ margin: 30 }}>Job Proposal History</div>}
            {state && <Paper
              sx={{
                background: "#F0F0F0",
                border: "hidden",
                boxShadow: "none",
                borderRadius: "16px",
                display: "flex",
                marginTop: "6px",
                justifyContent: "center",
                alignItems: "center",
                padding: "8px 0px"
              }}>
              <ToggleButtonGroup
                className="toggle-button"
                onChange={(_, val) => {
                  if (val !== null)
                    setTab(val);
                }}
                color="primary"
                exclusive
                value={tab}
              >
                <ToggleButton
                  className={tab === "finished" ? "active" : ""}
                  value="finished">
                  Finished
                </ToggleButton>
                <ToggleButton
                  className={tab === "saved" ? "active" : ""}
                  value="saved"
                >
                  Saved
                </ToggleButton>
              </ToggleButtonGroup>
            </Paper>}
            {proposals?.length
              ? proposals.map(item => (
                <div key={item.id} className="proposal-card" style={{ height: 150 }}>
                  <div className="proposal-content" style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                    {item.image ? (
                      <img
                        src={item.image}
                        alt="template-icon"
                        style={{
                          width: "60px",
                          height: "60px",
                          objectFit: "contain",
                          borderRadius: "6px"
                        }}
                      />
                    ) : (
                      <div className="proposal-image-container" style={{ height: 50 }}>
                      {item.title && (
                        <div className="thumbnail">
                          {item.title.charAt(0).toUpperCase()}
                        </div>
                      )}
                        </div>
                    )}
                    <Typography
                      sx={{
                        marginLeft: "20px",
                        marginTop: "6px",
                        color: "#464255",
                        fontWeight: "500",
                        fontSize: "1.125rem"
                      }}
                    >
                      {item.title}
                    </Typography>
                    <img
                      style={{
                        marginLeft: "auto"
                      }}
                      onClick={handleClick(item)}
                      src={expand}
                      alt="expand"
                      className="card-image"
                    />
                  </div>
                  <div className="detail-subtext">
                    <Typography
                      dangerouslySetInnerHTML={{ __html: `<p>${item.body.replace(/<[^>]*>/g, '')}</p>` }}
                      sx={{
                        height: "40px",
                        maxHeight: "4rem",
                        fontSize: '14px',
                        lineHeight: 'normal',
                        overflow: 'hidden',
                        "& p": { margin: "8px 0", fontSize: "14px", color: "#A3A3A3" }
                      }}
                    />
                  </div>
                  <div className="proposal-buttons">
                    <button className="industry-button"
                      style={{ backgroundColor: "#fff" }}>
                      {item.tag}
                    </button>
                    <button className="template-button" onClick={handleClick(item)} style={{ backgroundColor: "#fff" }}>
                      {state ? "View" : "Use Template"}
                    </button>
                  </div>
                </div>
              ))
              : null}
            <Pagination
              currentPage={currentPage}
              totalCount={proposalCount || 0}
              totalPages={Math.ceil(proposalCount / pageSize || 0)}
              pageSize={pageSize}
              onPageChange={page => setCurrentPage(page)}
              onSizeChange={size => setPageSize(size)}
              hideTotalCount
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default NewProposal
