import { all, takeLatest } from "redux-saga/effects"
import axios from "axios"
import * as actions from "./constants"
import { BASE_URL, request } from "../../utils/http"
import { sagasRunner } from "../../utils/redux"
import { toast } from "react-toastify"

function getProfile() {
  return request.get("/api/v1/user-profile/")
}

function getUsers() {
  return request.get("/accounts/api/v1/users/")
}

function updateProfile({ profile, token }) {
  const profileKeys = Object.keys(profile)
  const data = new FormData()
  profileKeys.forEach(k => {
    data.append(k, profile[k])
  })
  return axios({
    method: "patch",
    url: `${BASE_URL}/api/v1/accounts/profile/`,
    headers: {
      Authorization: `Token ${token}`,
      "Content-Type": "multipart/form-data"
    },
    data
  })
}

function handleGetProfile({ id }) {
  return sagasRunner({
    successType: actions.APP_GET_PROFILE_SUCCESS,
    errorType: actions.APP_GET_PROFILE_ERROR,
    loadingType: actions.APP_CHANGE_LOADING_STATE,
    errorMessage: "Unable to get profile data.",
    callFunc: getProfile,
    alertError: true,
    callData: { id },
    isProfile: true
  })
}

function handleGetUsers({ id }) {
  return sagasRunner({
    successType: actions.APP_GET_USERS_SUCCESS,
    errorType: actions.APP_GET_USERS_ERROR,
    loadingType: actions.APP_CHANGE_LOADING_STATE,
    errorMessage: "Unable to get users.",
    callFunc: getUsers,
    alertError: true,
    callData: { id },
    isProfile: true
  })
}

function updateUser({ data }) {
  return request.put(`/accounts/api/v1/users/${data.id}/`, data)
}

function handleUpdateUser({ data }) {
  return sagasRunner({
    successType: actions.APP_UPDATE_USER_SUCCESS,
    errorType: actions.APP_UPDATE_USER_ERROR,
    loadingType: actions.APP_CHANGE_LOADING_STATE,
    errorMessage: "Something went wrong. Please try again.",
    updateType: actions.APP_GET_USERS_REQUEST,
    alertError: true,
    callData: { data },
    callFunc: updateUser
  })
}

function deleteUser(data) {
  return request.delete(`/accounts/api/v1/users/${data}/`)
}

function handleDeleteUser({ data }) {
  return sagasRunner({
    successType: actions.APP_DELETE_USER_SUCCESS,
    errorType: actions.APP_DELETE_USER_ERROR,
    loadingType: actions.APP_CHANGE_LOADING_STATE,
    errorMessage: "Something went wrong. Please try again.",
    updateType: actions.APP_GET_USERS_REQUEST,
    alertError: true,
    callData: data,
    callFunc: deleteUser
  })
}

function handleUpdateProfile({ profile, token }) {
  return sagasRunner({
    successType: actions.APP_UPDATE_PROFILE_SUCCESS,
    errorType: actions.APP_UPDATE_PROFILE_ERROR,
    loadingType: actions.APP_CHANGE_LOADING_STATE,
    errorMessage: "Something went wrong. Please try again.",
    updateType: actions.APP_GET_PROFILE_REQUEST,
    alertError: true,
    sendToken: true,
    callFunc: updateProfile,
    callData: { profile, token },
    onSuccess: () => alert("Successfully updated")
  })
}

function getFeedback() {
  return request.get("/feedback/api/v1/")
}

function handleGetFeedback() {
  return sagasRunner({
    successType: actions.APP_GET_FEEDBACK_SUCCESS,
    errorType: actions.APP_GET_FEEDBACK_ERROR,
    loadingType: actions.APP_CHANGE_LOADING_STATE,
    errorMessage: "Something went wrong. Please try again.",
    alertError: true,
    callFunc: getFeedback
  })
}

function updateFeedback(data) {
  return request.put(`/feedback/api/v1/${data.id}/`, data)
}

function handleUpdateFeedback({ data }) {
  return sagasRunner({
    successType: actions.APP_UPDATE_FEEDBACK_SUCCESS,
    errorType: actions.APP_UPDATE_FEEDBACK_ERROR,
    loadingType: actions.APP_CHANGE_LOADING_STATE,
    errorMessage: "Something went wrong. Please try again.",
    updateType: actions.APP_GET_FEEDBACK_REQUEST,
    alertError: true,
    callData: data,
    callFunc: updateFeedback,
    onSuccess: () => alert("Feedback reply sent!")
  })
}

function getEvent() {
  return request.get("/event/api/v1/")
}

function handleGetEvent() {
  return sagasRunner({
    successType: actions.APP_GET_EVENT_SUCCESS,
    errorType: actions.APP_GET_EVENT_ERROR,
    loadingType: actions.APP_CHANGE_LOADING_STATE,
    errorMessage: "Something went wrong. Please try again.",
    alertError: true,
    callFunc: getEvent
  })
}

function createEvent({ values, token }) {
  const profileKeys = Object.keys(values)
  const data = new FormData()
  profileKeys.forEach(k => {
    data.append(k, values[k])
  })
  return axios({
    method: "post",
    url: `${BASE_URL}/event/api/v1/`,
    headers: {
      Authorization: `Token ${token}`,
      "Content-Type": "multipart/form-data"
    },
    data
  })
}

function handleCreateEvent({ values, token }) {
  return sagasRunner({
    successType: actions.APP_CREATE_EVENT_SUCCESS,
    errorType: actions.APP_CREATE_EVENT_ERROR,
    loadingType: actions.APP_CHANGE_LOADING_STATE,
    errorMessage: "Something went wrong. Please try again.",
    updateType: actions.APP_GET_EVENT_REQUEST,
    alertError: true,
    sendToken: true,
    callFunc: createEvent,
    callData: { values, token }
    //onSuccess: () => Alert.alert("Successfully created")
  })
}

function updateEvent({ values, token }) {
  const profileKeys = Object.keys(values)
  const data = new FormData()
  profileKeys.forEach(k => {
    data.append(k, values[k])
  })
  return axios({
    method: "put",
    url: `${BASE_URL}/event/api/v1/${values.id}/`,
    headers: {
      Authorization: `Token ${token}`,
      "Content-Type": "multipart/form-data"
    },
    data
  })
}

function handleUpdateEvent({ values, token }) {
  return sagasRunner({
    successType: actions.APP_UPDATE_EVENT_SUCCESS,
    errorType: actions.APP_UPDATE_EVENT_ERROR,
    loadingType: actions.APP_CHANGE_LOADING_STATE,
    errorMessage: "Something went wrong. Please try again.",
    updateType: actions.APP_GET_EVENT_REQUEST,
    alertError: true,
    sendToken: true,
    callFunc: updateEvent,
    callData: { values, token }
    //onSuccess: () => Alert.alert("Successfully created")
  })
}

function getBusiness() {
  return request.get("/businesses/api/v1/business/")
}

function handleGetBusiness() {
  return sagasRunner({
    successType: actions.APP_GET_BUSINESS_SUCCESS,
    errorType: actions.APP_GET_BUSINESS_ERROR,
    loadingType: actions.APP_CHANGE_LOADING_STATE,
    errorMessage: "Something went wrong. Please try again.",
    alertError: true,
    callFunc: getBusiness
  })
}

function createBusiness({ values }) {
  return request.post(`/businesses/api/v1/business/`, values)
}

function handleCreateBusiness({ values, navigate }) {
  return sagasRunner({
    successType: actions.APP_CREATE_BUSINESS_SUCCESS,
    errorType: actions.APP_CREATE_BUSINESS_ERROR,
    loadingType: actions.APP_CHANGE_LOADING_STATE,
    errorMessage: "Something went wrong. Please try again.",
    updateType: actions.APP_GET_BUSINESS_REQUEST,
    alertError: true,
    callData: { values },
    callFunc: createBusiness,
    onSuccess: () => navigate("/")
  })
}

function updateBusiness({ values }) {
  return request.patch(`/businesses/api/v1/business/${values.id}/`, values)
}

function handleUpdateBusiness({ values, navigate }) {
  return sagasRunner({
    successType: actions.APP_UPDATE_BUSINESS_SUCCESS,
    errorType: actions.APP_UPDATE_BUSINESS_ERROR,
    loadingType: actions.APP_CHANGE_LOADING_STATE,
    errorMessage: "Something went wrong. Please try again.",
    updateType: actions.APP_UPDATE_BUSINESS_REQUEST,
    callData: { values },
    callFunc: updateBusiness,
    onSuccess: () => toast.success("Saved")
  })
}

function updateCenter(data) {
  return request.put(`/center/api/v1/${data.id}/`, data)
}

function handleUpdateCenter({ data }) {
  return sagasRunner({
    successType: actions.APP_UPDATE_CENTER_SUCCESS,
    errorType: actions.APP_UPDATE_CENTER_ERROR,
    loadingType: actions.APP_CHANGE_LOADING_STATE,
    errorMessage: "Something went wrong. Please try again.",
    updateType: actions.APP_GET_BUSINESS_REQUEST,
    alertError: true,
    callData: data,
    callFunc: updateCenter
  })
}

function getNotification() {
  return request.get("/notification/api/v1/")
}

function handleGetNotification() {
  return sagasRunner({
    successType: actions.APP_GET_NOTIFICATION_SUCCESS,
    errorType: actions.APP_GET_NOTIFICATION_ERROR,
    loadingType: actions.APP_CHANGE_LOADING_STATE,
    errorMessage: "Something went wrong. Please try again.",
    alertError: true,
    callFunc: getNotification
  })
}

function createNotification(values) {
  return request.post(`/notification/api/v1/`, values)
}

function handleCreateNotification({ values }) {
  return sagasRunner({
    successType: actions.APP_CREATE_NOTIFICATION_SUCCESS,
    errorType: actions.APP_CREATE_NOTIFICATION_ERROR,
    loadingType: actions.APP_CHANGE_LOADING_STATE,
    errorMessage: "Something went wrong. Please try again.",
    updateType: actions.APP_GET_NOTIFICATION_REQUEST,
    alertError: true,
    callData: values,
    callFunc: createNotification
  })
}

function deleteEvent(data) {
  return request.delete(`/event/api/v1/${data}/`)
}

function handleDeleteEvent({ data }) {
  return sagasRunner({
    successType: actions.APP_DELETE_EVENT_SUCCESS,
    errorType: actions.APP_DELETE_EVENT_ERROR,
    loadingType: actions.APP_CHANGE_LOADING_STATE,
    errorMessage: "Something went wrong. Please try again.",
    updateType: actions.APP_GET_EVENT_REQUEST,
    alertError: true,
    callData: data,
    callFunc: deleteEvent
  })
}

function deleteCenter(data) {
  return request.delete(`/center/api/v1/${data}/`)
}

function handleDeleteCenter({ data }) {
  return sagasRunner({
    successType: actions.APP_DELETE_CENTER_SUCCESS,
    errorType: actions.APP_DELETE_CENTER_ERROR,
    loadingType: actions.APP_CHANGE_LOADING_STATE,
    errorMessage: "Something went wrong. Please try again.",
    updateType: actions.APP_GET_BUSINESS_REQUEST,
    alertError: true,
    callData: data,
    callFunc: deleteCenter
  })
}

function deleteNotification(data) {
  return request.delete(`/notification/api/v1/${data}/`)
}

function handleDeleteNotification({ data }) {
  return sagasRunner({
    successType: actions.APP_DELETE_NOTIFICATION_SUCCESS,
    errorType: actions.APP_DELETE_NOTIFICATION_ERROR,
    loadingType: actions.APP_CHANGE_LOADING_STATE,
    errorMessage: "Something went wrong. Please try again.",
    updateType: actions.APP_GET_NOTIFICATION_REQUEST,
    alertError: true,
    callData: data,
    callFunc: deleteNotification
  })
}

function getPost() {
  return request.get("/communities/api/v1/")
}

function handleGetPost() {
  return sagasRunner({
    successType: actions.APP_GET_POST_SUCCESS,
    errorType: actions.APP_GET_POST_ERROR,
    loadingType: actions.APP_CHANGE_LOADING_STATE,
    errorMessage: "Something went wrong. Please try again.",
    alertError: true,
    callFunc: getPost
  })
}

function createPost({ values, token }) {
  const profileKeys = Object.keys(values)
  const data = new FormData()
  profileKeys.forEach(k => {
    data.append(k, values[k])
  })
  return axios({
    method: "post",
    url: `${BASE_URL}/communities/api/v1/`,
    headers: {
      Authorization: `Token ${token}`,
      "Content-Type": "multipart/form-data"
    },
    data
  })
}

function handleCreatePost({ values, token }) {
  return sagasRunner({
    successType: actions.APP_CREATE_POST_SUCCESS,
    errorType: actions.APP_CREATE_POST_ERROR,
    loadingType: actions.APP_CHANGE_LOADING_STATE,
    errorMessage: "Something went wrong. Please try again.",
    updateType: actions.APP_GET_POST_REQUEST,
    alertError: true,
    callData: { values, token },
    callFunc: createPost
  })
}

function updatePost({ data }) {
  return request.put(`/communities/api/v1/${data.id}/`, data)
}

function handleUpdatePost({ data, token }) {
  return sagasRunner({
    successType: actions.APP_UPDATE_POST_SUCCESS,
    errorType: actions.APP_UPDATE_POST_ERROR,
    loadingType: actions.APP_CHANGE_LOADING_STATE,
    errorMessage: "Something went wrong. Please try again.",
    updateType: actions.APP_GET_POST_REQUEST,
    alertError: true,
    callData: { data, token },
    callFunc: updatePost
  })
}

function deletePost(data) {
  return request.delete(`/communities/api/v1/${data}/`)
}

function handleDeletePost({ data }) {
  return sagasRunner({
    successType: actions.APP_DELETE_POST_SUCCESS,
    errorType: actions.APP_DELETE_POST_ERROR,
    loadingType: actions.APP_CHANGE_LOADING_STATE,
    errorMessage: "Something went wrong. Please try again.",
    updateType: actions.APP_GET_POST_REQUEST,
    alertError: true,
    callData: data,
    callFunc: deletePost
  })
}

export function getTermsAndCondition() {
  return request.get(`/modules/terms-and-conditions/`)
}

export function getPrivacyPolicy() {
  return request.get(`/modules/privacy-policy/`)
}

export function checkEmailVerificationStatus(body) {
  return request.post(`/users/auth/email-verification-status/`, body)
}
export function landOnOnboardingForm(body) {
  return request.post(`/users/auth/on-boarding-qus-status/`, body)
}

export function getOnBoardingQuestionOnLadingPage(body) {
  return request.get(`/users/auth/onboarding-questions/`, body)
}

export function postOnBoardingQuestionOnLadingPage(body) {
  return request.post(`/users/auth/onboarding-questions/`, body)
}

export default all([
  takeLatest(actions.APP_GET_PROFILE_REQUEST, handleGetProfile),
  takeLatest(actions.APP_GET_USERS_REQUEST, handleGetUsers),
  takeLatest(actions.APP_UPDATE_USER_REQUEST, handleUpdateUser),
  takeLatest(actions.APP_DELETE_USER_REQUEST, handleDeleteUser),
  takeLatest(actions.APP_UPDATE_PROFILE_REQUEST, handleUpdateProfile),
  takeLatest(actions.APP_GET_FEEDBACK_REQUEST, handleGetFeedback),
  takeLatest(actions.APP_UPDATE_FEEDBACK_REQUEST, handleUpdateFeedback),
  takeLatest(actions.APP_GET_EVENT_REQUEST, handleGetEvent),
  takeLatest(actions.APP_DELETE_EVENT_REQUEST, handleDeleteEvent),
  takeLatest(actions.APP_CREATE_EVENT_REQUEST, handleCreateEvent),
  takeLatest(actions.APP_UPDATE_EVENT_REQUEST, handleUpdateEvent),
  takeLatest(actions.APP_GET_BUSINESS_REQUEST, handleGetBusiness),
  takeLatest(actions.APP_CREATE_BUSINESS_REQUEST, handleCreateBusiness),
  takeLatest(actions.APP_UPDATE_BUSINESS_REQUEST, handleUpdateBusiness),
  takeLatest(actions.APP_UPDATE_CENTER_REQUEST, handleUpdateCenter),
  takeLatest(actions.APP_DELETE_CENTER_REQUEST, handleDeleteCenter),
  takeLatest(actions.APP_GET_NOTIFICATION_REQUEST, handleGetNotification),
  takeLatest(actions.APP_CREATE_NOTIFICATION_REQUEST, handleCreateNotification),
  takeLatest(actions.APP_DELETE_NOTIFICATION_REQUEST, handleDeleteNotification),
  takeLatest(actions.APP_GET_POST_REQUEST, handleGetPost),
  takeLatest(actions.APP_CREATE_POST_REQUEST, handleCreatePost),
  takeLatest(actions.APP_UPDATE_POST_REQUEST, handleUpdatePost),
  takeLatest(actions.APP_DELETE_POST_REQUEST, handleDeletePost)
])
