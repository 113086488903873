import styled from "@emotion/styled";
import { Button, Grid } from "@mui/material";
import { ReactComponent as CampaignIcon } from "../../../assets/icon-campaign.svg"
import { ReactComponent as CloseIcon } from "../../../assets/icon-close.svg"
import ReactQuill from "react-quill";
import { useEffect, useState } from "react";
import { request } from "../../../utils/http";
import { useNavigate, useParams } from "react-router-dom";
import AppModal from "../../../components/Modal";

const StyledButton = styled(Button)({
    background: "#0F579626",
    color: "#0F5796",
    borderRadius: "14px",
    marginTop:'20px',
    textTransform: "none",
    padding: "12px 20px",
    justifyContent:'flex-start',
    maxWidth:'220px'
})


const OnboardingViewForm = () => {

    const params = useParams();

    const navigate = useNavigate();

    const id = params.id;

    const [details, setDetails] = useState(null); 

    const [sendForm, setSendForm] = useState(false);

    const [onBoardingFormDetails, setOnboardingFormDetails] = useState("");

    const [sendFormOpen, setSendFormOpen] = useState(false);

    const updatedQuillDetails = (data) =>{
        if(data){
            const quillData = data.map( (record,index) =>{
                const question = (record.questions || '');
                const answer = (record.answer || '');
                const no = index +1;
                return `
                    <div>   
                        <div><strong>${no}. ${question}</strong></div>
                        <div><span style='color:#6A707E;'>${answer}</span></div>
                    </div>
                `;
            }).join('</br>');
            const formattedQuillData = "</br>"+quillData+"</br></br>";
            setOnboardingFormDetails(formattedQuillData);
        }
    }

    useEffect(()=>{

        request.get(`/clients/api/v1/clients/${id}/`)
        .then(({ data }) => {
            setDetails(data);
        });

        request
        .get(`/clients/api/v1/clients/on-boarding/questioner/${id}/`)
        .then(({ data }) => {
            const status = Array.isArray(data) && !!data?.find(d => d?.is_finished);;
            updatedQuillDetails(data)
            setSendForm(status)
        })
        .catch((err) => {
            console.log(err)
        })  
    },[]);


    return (
        <div className="business-management-header onboarding-completed-form">
            <Grid container>
                <Grid xs={3}>
                    <div className="header-content" style={{ marginBottom: 16 }}>
                        <div style={{ fontWeight: '500', fontSize: '20px' }}>{sendForm ? 'Completed' : ''} Form</div>
                        <div
                            style={{
                                color: "#A3A3A3",
                                fontSize: "14px",
                                marginTop: 16
                            }}
                        >
                            View information
                        </div>
                        {!sendForm && <StyledButton
                            onClick={()=> setSendFormOpen(true)}
                            startIcon={<CampaignIcon />}
                            sx={{ whiteSpace: "nowrap"}}
                        >
                            Send Form
                        </StyledButton>}

                    </div>
                </Grid>
                <Grid xs={9}>
                    <div style={{display:'flex',justifyContent:'flex-end'}}>
                        <CloseIcon style={{cursor:'pointer'}} onClick={()=> navigate("/client/onboard-client")} />
                    </div>
                <div className="quill-editor" style={{marginLeft:'20px', backgroundColor:'white'}}>
                    <ReactQuill readOnly value={onBoardingFormDetails}/>
                  </div>
                </Grid>
            </Grid>
            <AppModal
                modalIsOpen={sendFormOpen}
                sendOnboardingForm
                values={{
                    email: details?.email,
                    id: id
                }}
                closeModal={() => {
                    setSendFormOpen(false);
                }}
            />
        </div>
    )
}

export default OnboardingViewForm;