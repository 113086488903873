import * as actions from "./constants"

export const getProfile = id => ({
  type: actions.APP_GET_PROFILE_REQUEST,
  id
})

export const updateProfile = (profile, token) => ({
  type: actions.APP_UPDATE_PROFILE_REQUEST,
  profile,
  token
})

export const getUserList = () => ({
  type: actions.APP_GET_USERS_REQUEST
})

export const updateUser = data => ({
  type: actions.APP_UPDATE_USER_REQUEST,
  data
})

export const deleteUser = data => ({
  type: actions.APP_DELETE_USER_REQUEST,
  data
})

export const getFeedbackList = () => ({
  type: actions.APP_GET_FEEDBACK_REQUEST
})

export const updateFeedback = data => ({
  type: actions.APP_UPDATE_FEEDBACK_REQUEST,
  data
})

export const getEventList = () => ({
  type: actions.APP_GET_EVENT_REQUEST
})

export const createEvent = (values, token) => ({
  type: actions.APP_CREATE_EVENT_REQUEST,
  values,
  token
})

export const updateEvent = (values, token) => ({
  type: actions.APP_UPDATE_EVENT_REQUEST,
  values,
  token
})

export const deleteEvent = data => ({
  type: actions.APP_DELETE_EVENT_REQUEST,
  data
})

export const getBusinessList = () => ({
  type: actions.APP_GET_BUSINESS_REQUEST
})

export const createBusiness = (values, navigate) => ({
  type: actions.APP_CREATE_BUSINESS_REQUEST,
  values,
  navigate
})

export const updateBusiness = (values, navigate) => ({
  type: actions.APP_UPDATE_BUSINESS_REQUEST,
  values,
  navigate
})

export const updateCenter = data => ({
  type: actions.APP_UPDATE_CENTER_REQUEST,
  data
})

export const deleteCenter = data => ({
  type: actions.APP_DELETE_CENTER_REQUEST,
  data
})

export const getNotificationList = () => ({
  type: actions.APP_GET_NOTIFICATION_REQUEST
})

export const deleteNotification = data => ({
  type: actions.APP_DELETE_NOTIFICATION_REQUEST,
  data
})

export const createNotification = values => ({
  type: actions.APP_CREATE_NOTIFICATION_REQUEST,
  values
})

export const getPostList = () => ({
  type: actions.APP_GET_POST_REQUEST
})

export const createPost = (values, token) => ({
  type: actions.APP_CREATE_POST_REQUEST,
  values,
  token
})

export const updatePost = (data, token) => ({
  type: actions.APP_UPDATE_POST_REQUEST,
  data,
  token
})

export const deletePost = data => ({
  type: actions.APP_DELETE_POST_REQUEST,
  data
})
