import React, { useEffect, useRef, useState } from 'react';
import chevronLeft from "../../../assets/chevron-left-new.png"
import chevronRight from "../../../assets/chevron-right-new.png"
import Calendar from '@toast-ui/react-calendar';
import dayjs from 'dayjs';
import '@toast-ui/calendar/dist/toastui-calendar.min.css';
import './CustomTuiCalendar.css';
import ReportView from './UI/ReportView';
import CalendarSelection from './UI/CalendarSelection';
import moment from 'moment';
import AppModal from '../../../components/Modal';
import { ReportStatus } from '../ReportsConst';

const WeeklyCalendar = ({ date, events, onGetEvents, onExportToGmailSingleRecord, clientId }) => {

    const calendarRef = useRef(null);

    const [selectedDateInfo, setSelectedDateInfo] = useState(null);

    const [open, setOpen] = useState(false);
    const [anchor, setAnchor] = useState(null);

    const [editReportShow, setEditReportShow] = useState(false);

    const [openDatepicker, setOpenDatepicker] = useState(false);
    const [value, setValue] = useState(dayjs(new Date()));

    const [selectedEvent, setSelectedEvent] = useState(null);

    const addRightButton = () => {
        const button = document.createElement('button');
        button.addEventListener('click', handleNextWeek);
        button.setAttribute('type', 'button')
        button.innerHTML = `<img src=${chevronRight} width="9px" height="17px" 
                            style="filter:brightness(0) invert(1);"  alt="Chevron Right"/>`;
        button.classList.add('cutsom-right-report-btn');
        document.querySelector(".toastui-calendar-layout").appendChild(button)
    }

    const addLeftButton = () => {
        const button = document.createElement('button');
        button.addEventListener('click', handlePreviousWeek);
        button.setAttribute('type', 'button')
        button.innerHTML = `<img src=${chevronLeft} width="9px" height="17px" 
                        style="filter:brightness(0) invert(1);"  alt="Chevron Left"/>`;
        button.classList.add('cutsom-left-report-btn');
        document.querySelector(".toastui-calendar-layout").appendChild(button)
    }

    const displayDate = () => {
        const date = calendarRef?.current?.getInstance()?.getDate()?.d?.d;
        const selectedDate = date && new Date(date);
        setValue(dayjs(selectedDate));
        return selectedDate ? selectedDate.toLocaleString('default', { month: 'short' }) + " " + selectedDate.getFullYear() : null;
    }

    const onExportToGmail = (id) =>{
        setEditReportShow(false);
        onExportToGmailSingleRecord(id);
    }


    const handleNextWeek = () => {
        calendarRef.current.calendarInstance.next();
        setSelectedDateInfo(displayDate());
        getEvents();
    };

    const handlePreviousWeek = () => {
        calendarRef.current.calendarInstance.prev();
        setSelectedDateInfo(displayDate());
        getEvents();
    };

    const onDateSelect = (value) => {
        setValue(value);
        calendarRef.current.getInstance().setDate(value.$d)
        setSelectedDateInfo(displayDate())
        getEvents();
    }

    const template = {
        weekDayName: function (model) {
            let dayName;
            if (model.dayName === 'Sun') {
                dayName = 'Sunday';
            } else if (model.dayName === 'Mon') {
                dayName = 'Monday'
            } else if (model.dayName === 'Tue') {
                dayName = 'Tuesday'
            } else if (model.dayName === 'Wed') {
                dayName = 'Wednesday'
            } else if (model.dayName === 'Thu') {
                dayName = 'Thursday'
            } else if (model.dayName === 'Fri') {
                dayName = 'Friday'
            } else if (model.dayName === 'Sat') {
                dayName = 'Saturday'
            }

            const template = `<div style="display:flex; align-items:center;flex-direction:column;">
                         <div style="font-size:13px;color:black;line-heigh:3;">
                            ${dayName}
                        </div>
                        <div style="line-height:1;color:black;font-size:24px;font-weight:bold;">
                            ${model.date}
                        </div>
                    </div>`
            return template;
        },
        timegridDisplayPrimaryTime: function (time) {
            // Format the time in 24-hour format
            const dateTime = time?.time?.d?.d;
            const date = new Date(dateTime);
            return `<span style="color:black;">${date.getHours() < 10 ? '0' : ''}${date.getHours()}:${date.getMinutes() < 10 ? '0' : ''}${date.getMinutes()}</span>`;
        },

        popupDetailTitle: function (schedule) {
            console.log(schedule)
            // Customize the title section of the detail pop-up
            return `<span style="color: blue;">${schedule.title}haha</span>`;
        },
        popupDetailBody: function (schedule) {
            // Customize the body section of the detail pop-up
            return `<p><strong>Location:</strong> hahah</p>
                    <p>body</p>
            `;
        },
        time: function (schedule) {
            const covered = schedule?.body?.status === ReportStatus.Covered ? 'Covered' : 'Uncovered';
            const coveredColor = ReportStatus.Covered === schedule?.body?.status ? '#28C66F' : '#EC5453';
            const startTime = moment(schedule.start.toDate()).format("HH:mm");
            const endTime = moment(schedule.end.toDate()).format("HH:mm");
            const description = schedule?.body?.description.replace(/<[^>]*>/g, '')
            // Example template: Display title and custom attribute
            return `<div style='margin-left:5px;background-color: transparent !important;padding-top:5px;height:100%;display:flex;flex-flow:column;'>
                        <strong style="font-size:12px;color:#0F5796;background-color: transparent !important;white-space:normal;">${schedule?.title}</strong><br/>
                        <span style='font-size:10px;background-color: transparent !important;display: inline-block;color:#0F5796;'>${startTime} - ${endTime}</span>
                        <div style="font-size:12px;margin-top:15px;margin-bottom:5px;background-color: transparent !important;font-weight:normal;white-space:normal;">${schedule.title}</div>
                        <div style="display:flex;flex-direction:column;height:calc(100% - 68px);">
                            <div style="font-size:9px;background-color: transparent !important;margin-bottom:5px;overflow:hidden;height:30px;white-space:normal;flex:1;margin-right:2px;max-height:70px;">
                                ${description}
                            </div>            
                            <div style='background-color: transparent !important;padding-bottom:5px; padding-top:5px;'>
                                <span style="display:inline-block;width:8px;height:8px;border-radius:50%;background-color:${coveredColor} !important;"></span>
                                <span style="font-size:12px;background-color: transparent !important;color:black;font-weight:normal;">${covered}</span>
                           </div>
                        </div>
                    </div>`;
        },
    }


    const theme = {
        week: {
            nowIndicatorPast: {
                border: '1px dashed #0F5796',
            },
        },
    };

    const getEvents = () => {
        const startdate = calendarRef.current.getInstance().getDateRangeStart();
        onGetEvents(startdate)
    }


    useEffect(() => {
        addRightButton();
        addLeftButton();
        date && calendarRef.current.calendarInstance.setDate(date);
        setSelectedDateInfo(displayDate());
        getEvents();
        calendarRef.current.calendarInstance.on('clickEvent', (e) => {
            const el = e.nativeEvent.target.closest('.toastui-calendar-event-time');
            setAnchor(el)
            setOpen(true);
            setSelectedEvent(e.event);
        });
        return () => {
            calendarRef?.current?.calendarInstance?.off('clickEvent');
        };
    }, [])

    return (<div style={{ height: '1700px' }} className='weekly-report'>
        <CalendarSelection
            setOpenDatepicker={setOpenDatepicker}
            onDateSelect={onDateSelect} value={value}
            selectedDateInfo={selectedDateInfo}
            openDatepicker={openDatepicker} />
        {open && <ReportView open={open} setOpen={setOpen} anchor={anchor} selectedEvent={selectedEvent} setEditReportShow={setEditReportShow} />}
        <Calendar
            ref={calendarRef}
            events={events}
            height="400px"
            view='week'
            theme={theme}
            template={template}
            isReadOnly={true}
            useDetailPopup={false}
            week={{
                startDayOfWeek: 1,
                hourStart: 0,
                hourEnd: 24,
                taskView: false,
                eventView: ['time']
            }}
        />
        <AppModal
            modalIsOpen={editReportShow}
            editReportEvent={editReportShow}
            data={{
                value: selectedEvent,
                clientId:clientId,
                updateEvent:getEvents,
                exportToGmail: onExportToGmail
            }}
            closeModal={() => {
                setEditReportShow(false);
            }}
        />
    </div>)
};

export default WeeklyCalendar;
