import React, { useEffect, useMemo, useState } from "react"

import "./NewPressRelease.css"
import plus from "../../../assets/plus-black.png"
import searchicon from "../../../assets/search-icon.png"
import ReactQuill from "react-quill"
import "react-quill/dist/quill.snow.css"
import { ReactComponent as LabelIcon } from "../../../assets/icon-label.svg"

import Pagination from "../../../components/Pagination/Pagination"
import { useDispatch, useSelector } from "react-redux"
import TemplateCard from "../../../components/TemplateCard"
import {
  Box,
  Button,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  Checkbox,
  Stack,
  Typography,
  Popover,
  ToggleButtonGroup,
  ToggleButton
} from "@mui/material"
import {
  getPressReleaseTemplates,
  postPressReleaseTemplates,
  postPressReleaseTemplatesSuccess
} from "../../../redux/clients/actions"
import { useLocation, useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import { styled } from "@mui/material/styles"
import CloudUploadIcon from "@mui/icons-material/CloudUpload"
import CircularProgress from "@mui/material/CircularProgress"
import { request } from "../../../utils/http"

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1
})

const NewPressRelease = ({ selectedClient }) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { state } = useLocation();
  const [content, setContent] = useState("")
  const [errors, setErrors] = useState({})
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null)
  const [title, setTitle] = useState("")
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  const [openDialog, setOpenDialog] = useState(false)
  const [trigger, setTrigger] = useState(false)
  const [tab, setTab] = useState("finished");
  const [selectedItem, setSelectedItem] = useState("")
  const [search, setSearch] = useState("")
  const [releases, setReleases] = useState([]);
  const [releaseCount, setReleaseCount] = useState(0);
  const [users, setUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const templatesData = useSelector(state => state.client.pressReleaseTemplates)
  const totalRecords = useMemo(() => {
    if (state)
      return releaseCount
    else
      return templatesData?.count
  }, [templatesData, releaseCount, state])
  const [currentPage, setCurrentPage] = useState(1)
  const [pageSize, setPageSize] = useState(2)
  const [client, setClient] = useState({
    value: selectedClient?.id,
    label: selectedClient?.name
  })
  const postSuccess = useSelector(
    state => state.client.isSuccess?.pressReleaseTemplates
  )
  const isLoading = useSelector(state => state.client.isLoading)

  useEffect(() => {
    if (state) {
      request.get(
        `/press-releases/api/v1/press-release/client/${client?.value}/?size=${pageSize}&page=${currentPage}&is_finished=${tab === "finished"}&search=${search}`
      )
        .then(({ data }) => {
          setReleases(data.results);
          setReleaseCount(data.count);
        })
        .catch(() => {
          setCurrentPage(1);
        })
    }
    else {
      dispatch(
        getPressReleaseTemplates({
          page: currentPage,
          limit: pageSize,
          client: client?.value,
          search
        })
      )
    }
  }, [dispatch, client, currentPage, pageSize, search, state, trigger, tab])

  useEffect(() => {
    if (state) {
      request.get("/api/v1/profile/")
        .then(({ data }) => {
          setUsers(data?.results);
        });
      setSelectedUsers(state.members?.length ? state.members : []);
      setTitle(state.title);
      setContent(state.content);
      setSelectedItem(state.tag);
      setSelectedImage(state.image || "");
    }
  }, [state]);

  useEffect(() => {
    if (postSuccess && !state) {
      dispatch(getPressReleaseTemplates({ page: currentPage, limit: pageSize }))
      dispatch(postPressReleaseTemplatesSuccess(""))
      toast.success("Press release template created successfully.")
      navigate("/client/press-release")
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postSuccess, dispatch, state])

  useEffect(() => {
    setClient({
      value: selectedClient?.id,
      label: selectedClient?.name
    })
  }, [selectedClient])

  const templateList = useMemo(() => {
    return templatesData?.results?.slice(0, 2).map(template => ({
      id: template?.id,
      image: template?.image,
      title: template?.title,
      body: template?.body,
      clientName: template?.client_detail?.name,
      label: template?.tag,
      labelIcon: <LabelIcon />
    }))
  }, [templatesData])

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen)
  }

  const handleSelect = item => {
    setSelectedItem(item)
    setIsDropdownOpen(false)
  }

  const handleTitleChange = e => {
    setTitle(e.target.value)
  }

  const handleChange = value => {
    setContent(value)
  }

  const handleSearch = e => {
    setSearch(e.target.value)
  }

  const handleSelectImage = e => {
    setErrors(prev => ({ ...prev, image: "" }))
    if (e.target.files[0].size > 1024 * 1024 * 5) {
      setErrors(prev => ({
        ...prev,
        image: "Image size should be less than 5MB."
      }))
      setSelectedImage("")
      return
    }
    setSelectedImage(e.target.files[0])
  }

  const hasRequiredFields = useMemo(() => {
    return title && content && selectedItem && client
  }, [title, content, selectedItem, client])

  const handleSave = () => {
    const postData = new FormData();
    postData.append("title", title);
    postData.append("body", content);
    if (selectedImage && typeof selectedImage === "string")
      postData.append("image_url", "media" + selectedImage.split("media")[2]?.split("?")[0]);
    else if (selectedImage)
      postData.append("image", selectedImage);
    postData.append("tag", selectedItem);
    postData.append("is_finished", false);
    postData.append("client", client?.value);
    selectedUsers.forEach(user => {
      postData.append("members", user);
    });

    if (state.id) {
      request.patch(`/press-releases/api/v1/press-release/${state.id}/`, postData, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      })
        .then(() => {
          setTrigger(!trigger);
          toast.success("Press release saved successfully.")
        });
    }
    else {
      request.post("/press-releases/api/v1/press-release/", postData, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      })
        .then(() => {
          setTrigger(!trigger);
          toast.success("Press release saved successfully.")
        });
    }
  }

  const handleFinish = () => {
    if (state) {
      const postData = new FormData();
      postData.append("title", title);
      postData.append("body", content);
      if (selectedImage && typeof selectedImage === "string")
        postData.append("image_url", "media" + selectedImage.split("media")[2]?.split("?")[0]);
      else if (selectedImage)
        postData.append("image", selectedImage);
      postData.append("tag", selectedItem);
      postData.append("is_finished", true);
      postData.append("client", client?.value);
      selectedUsers.forEach(user => {
        postData.append("members", user);
      });

      if (state.id) {
        request.patch(`/press-releases/api/v1/press-release/${state.id}/`, postData, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        })
          .then(() => {
            setTrigger(!trigger);
            toast.success("Press release finished successfully.")
            navigate("/client/press-releases")
          });
      }
      else {
        request.post("/press-releases/api/v1/press-release/", postData, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        })
          .then(() => {
            setTrigger(!trigger);
            toast.success("Press release finished successfully.")
            navigate("/client/press-releases")
          });
      }
    }
    else {
      const postData = new FormData()
      if (selectedImage)
        postData.append("image", selectedImage)
      postData.append("title", title)
      postData.append("body", content)
      postData.append("client", client?.value)
      postData.append("tag", selectedItem)

      dispatch(postPressReleaseTemplates(postData))
    }
  }

  const handleUser = (id) => () => {
    if (selectedUsers.includes(id))
      setSelectedUsers(selectedUsers.filter(x => x !== id));
    else
      setSelectedUsers([...selectedUsers, id])
  }

  return (
    <div className="new-press-release-create-content">
      <div className="create-left-new-press-release">
        <div className="new-press-release-content">
          <div style={{ display: "flex" }}>
            <div className="text-container">
              <p
                className="new-press-release-card-title"
                style={{ marginBottom: 15, fontSize: 24 }}
              >
                {state ? "Press Release Template" : "Create New"}
              </p>
              <p className="card-description" style={{ marginBottom: 25 }}>
                {state ? "Details" : "Press Release"}
              </p>
            </div>
          </div>
          <div>
            {state && <Button
              onClick={(event) => {
                setAnchorEl(event.currentTarget);
                setOpenDialog(true)
              }}
              variant="contained"
              className="template-button"
              style={{
                backgroundColor: "#0F5796",
                color: "#fff",
                height: 40,
                marginBottom: 40,
                marginRight: 20
              }}
            >
              Assign
            </Button>}
            {state && <Button
              className="template-button"
              style={{
                backgroundColor:
                  hasRequiredFields && !isLoading ? "#0F5796" : "#CDCDCD",
                color: "#fff",
                height: 40,
                marginRight: 20,
                marginBottom: 40
              }}
              onClick={handleSave}
              disabled={!hasRequiredFields || isLoading}
              variant="contained"
            >
              Save
            </Button>}
            <Button
              variant="contained"
              className="template-button"
              style={{
                backgroundColor:
                  hasRequiredFields && !isLoading ? "#0F5796" : "#CDCDCD",
                color: "#fff",
                height: 40,
                marginRight: 20,
                marginBottom: 40
              }}
              disabled={!hasRequiredFields || isLoading}
              onClick={handleFinish}
            >
              Finish
              {isLoading && (
                <CircularProgress size={20} sx={{ marginLeft: "1rem" }} />
              )}
            </Button>
          </div>
        </div>
        <Popover
          open={openDialog}
          anchorEl={anchorEl}
          onClose={() => setOpenDialog(false)}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          <DialogTitle className="agents-title">
            Staff members({users.length})
            <div>Maiores dicta atque dolorem temporibus</div>
          </DialogTitle>
          {users.map(user =>
            <Grid key={user.id} container className="agent-grid">
              <Grid item xs={2}>
                <img
                  className="agent-photo"
                  src={user.profile_image}
                  alt="agent"
                >
                </img>
              </Grid>
              <Grid item xs={8} className="agent-details">
                <div>
                  {user.user.name}
                </div>
                <div>
                  {user.bio}
                </div>
              </Grid>
              <Grid item xs={2}>
                <Checkbox checked={selectedUsers.includes(user.user.id)} onClick={handleUser(user.user.id)} />
              </Grid>
            </Grid>
          )}
        </Popover>
        <div style={{ backgroundColor: "#fff", padding: 40 }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center"
            }}
          >
            <p
              className="new-press-release-card-title"
              style={{ fontSize: 16 }}
            >
              {"Template name:"}
            </p>
            <div className="search-dropdown">
              <div className="input-container">
                <input
                  type="text"
                  placeholder="Text input"
                  value={title}
                  onChange={handleTitleChange}
                />

                <button className="plus-button" onClick={toggleDropdown}>
                  <img
                    src={plus}
                    style={{ width: 14, height: 14, marginRight: 5 }}
                    alt="add industry"
                  />
                  Add industry
                </button>

                {isDropdownOpen && (
                  <div className="dropdown">
                    <button onClick={() => handleSelect("Industry 1")}>
                      Industry 1
                    </button>
                    <button onClick={() => handleSelect("Industry 2")}>
                      Industry 2
                    </button>
                    <button onClick={() => handleSelect("Industry 3")}>
                      Industry 3
                    </button>
                  </div>
                )}
              </div>
              {/**selectedItem && <p>Selected: {selectedItem}</p>**/}
            </div>
          </div>
          <Box sx={{ padding: "20px 0", display: "flex", gap: "1rem" }}>
            <Typography>Template Image:</Typography>
            <Box sx={{ display: "flex", gap: "1rem" }}>
              <FormControl>
                <Button
                  component="label"
                  variant="contained"
                  startIcon={<CloudUploadIcon />}
                >
                  Upload Image
                  <VisuallyHiddenInput
                    type="file"
                    onChange={handleSelectImage}
                  />
                </Button>
                <FormHelperText error>{errors?.image}</FormHelperText>
              </FormControl>
              {selectedImage && (
                <img
                  alt="not found"
                  width={"60px"}
                  style={{ objectFit: "contain" }}
                  src={typeof selectedImage === "string" ? selectedImage : URL.createObjectURL(selectedImage)}
                />
              )}
            </Box>
          </Box>
          <div className="quill-editor">
            <ReactQuill
              value={content}
              onChange={handleChange}
              style={{ height: "90%", width: "50vw" }}
            />
          </div>
        </div>
      </div>

      {/* Right Column */}
      <div className="create-right-column">
        <div style={{ display: "flex", marginBottom: 20 }}>
          <div
            style={{
              flex: 2,

              display: "flex",
              alignItems: "center",
              position: "relative"
            }}
          >
            <input
              type="text"
              placeholder="Search..."
              style={{
                width: "100%",
                height: 26,
                padding: "8px 30px 8px 8px",
                border: "none",
                borderRadius: "10px",
                backgroundColor: "#F0F0F0"
              }}
              value={search}
              onChange={handleSearch}
            />
            <img
              src={searchicon}
              alt="Search Icon"
              style={{
                position: "absolute",
                width: 20,
                height: 20,
                top: "50%",
                right: "10px",
                transform: "translateY(-50%)",
                cursor: "pointer"
              }}
            />
          </div>
        </div>
        {state && <ToggleButtonGroup
          className="toggle-button"
          onChange={(_, val) => {
            if (val !== null)
              setTab(val);
          }}
          color="primary"
          size="large"
          exclusive
          value={tab}
        >
          <ToggleButton value="finished">Finished</ToggleButton>
          <ToggleButton value="saved">Saved</ToggleButton>
        </ToggleButtonGroup>}
        <Stack gap={2}>
          {state ? releases
            .map(template => (
              <TemplateCard
                key={template?.id}
                title={template?.title}
                label={template?.tag}
                labelIcon={<LabelIcon />}
                image={template?.image}
                subTitle={template?.client_detail?.name}
                onClick={() =>
                  navigate("/client/press-releases/new", {
                    state: {
                      id: template?.id,
                      title: template?.title,
                      content: template?.body,
                      image: template?.image,
                      tag: template?.tag,
                      members: template?.members
                    }
                  })
                }
                body={template?.body}
                actionLabel="View"
                sideView
              />
            )) : templateList?.map(template => (
              <TemplateCard
                key={template?.id}
                title={template?.title}
                label={template?.label}
                labelIcon={template?.labelIcon}
                image={template?.image}
                subTitle={template?.clientName}
                onClick={() =>
                  navigate("/client/press-releases/new", {
                    state: {
                      title: template?.title,
                      content: template?.body,
                      image: template?.image,
                      tag: template?.label,
                    }
                  })
                }
                body={template?.body}
                actionLabel="Use template"
                sideView
              />
            ))}
        </Stack>

        <Pagination
          currentPage={currentPage}
          totalCount={totalRecords || 0}
          totalPages={Math.ceil(totalRecords / pageSize || 0)}
          pageSize={pageSize}
          onPageChange={page => setCurrentPage(page)}
          onSizeChange={size => setPageSize(size)}
          hideTotalCount
        />
      </div>
    </div>
  )
}

export default NewPressRelease
