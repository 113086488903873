import React, { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import {
  Routes,
  Route,
  Navigate,
  useLocation
} from "react-router-dom"

import Header from "../../components/Header"
import "./ClientManagement.scss"
import Tabs from "./Tabs"
import ClientDetails from "./ClientsDetails"
import ExternalDocument from "./ExternalDocument"
import PressRelease from "./PressRelease"
import { getClients } from "../../redux/clients/actions"
import NewPressRelease from "./PressRelease/NewPressRelease"
import OnboardingForm from "./Onboard/OnboardingForm"
import OnboardingClient from "./Onboard/OnBoardingClient"
import InternalDocuments from "./InternalDocuments"
import OnboardingViewForm from "./Onboard/OnboardingViewForm"
import AgendasForMeetings from "./AgendasForMeetings"
import CreateNewAgenda from "./CreateNewAgenda"
import MeetingAgenda from "./MeetingAgenda"
import ReportList from "../Reports/ReportList"
import WeeklyReport from "../Reports/ReportType/WeeklyReport"
import MonthlyReport from "../Reports/ReportType/MonthlyReport"
import YearlyReport from "../Reports/ReportType/YearlyReport"
import FAQ from "./FAQ"
import ClientManagementEdit from "./ClientManagementEdit"
import CreateTask from "../CampaignManagement/CreateTask"
import NewFAQ from "./FAQ/NewFAQ"

const ClientManagement = () => {
  const dispatch = useDispatch()
  const [search, setSearch] = useState("")
  const [selectedClient, setSelectedClient] = useState(null)
  const location = useLocation()

  useEffect(() => {
    dispatch(getClients())
  }, [dispatch])

  const handleRowClick = row => () => {
    setSelectedClient(row)
  }

  return (
    <div className="dashboard-container">
      {/* <SideMenu /> */}
      <div className="main-content">
        <Header
          title="Client Management tab"
          value={search}
          onChange={e => setSearch(e.target.value)}
        />
        <div className="horizontal-rule"></div>
        <div className="content">
          {/* Left Column */}
          {(location.pathname === "/client" || 
            location.pathname === "/client/collateral-management/internal-document" || 
            location.pathname === "/client/collateral-management/external-document" ||
            location.pathname === "/client/onboard-client" || location.pathname === "/client/client-management-edit"
            || location.pathname === "/client/campaign-management-edit")            
          && <Tabs />}
          {/* {!matchPath(
            {
              path: "client/press-releases",
              end: false
            },
            location?.pathname
          ) &&
            !matchPath(
              {
                path: "/client/faqs/",
                end: false
              },
              location?.pathname
            ) &&
            !matchPath(
              {
                path: "/client/create-onboarding-form/",
                end: false
              },
              location?.pathname
            ) &&
            !matchPath(
              {
                path: "/client/reports",
                end: false
              },
              location?.pathname
            ) &&
            !matchPath(
              {
                path: "/client/view-onboarding-form",
                end: false
              },
              location?.pathname
            ) && <>{selectedClient ? <Tabs /> : undefined}</>}
          {/* Right Column */}
          <div className="right-columns">
            <Routes>
              <Route
                path="/"
                element={
                  <ClientDetails
                    handleRowClick={handleRowClick}
                    selectedClient={selectedClient}
                  />
                }
              />
              <Route
                path="/create-onboarding-form/:id"
                element={<OnboardingForm />}
              />
              <Route path="/create-faq/:id" element={<NewFAQ />} />
              <Route
                path="/view-onboarding-form/:id"
                element={<OnboardingViewForm />}
              />
              <Route
                path="/onboard-client"
                element={
                  <>
                    <ClientDetails
                      handleRowClick={handleRowClick}
                      selectedClient={selectedClient}
                      clientPageSize={3}
                    />
                  </>
                }
              />
              <Route
                path="/onboarding-screen/:id"
                element={<OnboardingForm />}
              />
              <Route
                path="/collateral-management"
                element={
                  <Navigate
                    to="/client/collateral-management/internal-document"
                    replace={true}
                    state={selectedClient}
                  />
                }
              />

              <Route
                path="/meeting-agenda"
                element={<MeetingAgenda />}
                replace={true}
              />

              <Route
                path="/client-management-edit"
                element={<ClientManagementEdit selectedClient={selectedClient} />}
                replace={true}
              />

              <Route
                path="/create-new-agenda"
                element={<CreateNewAgenda />}
                replace={true}
              />

              <Route
                path="/agendas-meeting"
                element={<AgendasForMeetings selectedClient={selectedClient} />}
                replace={true}
              />
              <Route
                path="/collateral-management/internal-document"
                element={<InternalDocuments selectedClient={selectedClient} />}
              />
              <Route
                path="/collateral-management/external-document"
                element={<ExternalDocument selectedClient={selectedClient} />}
              />
              <Route
                path="/press-releases"
                element={<PressRelease selectedClient={selectedClient} />}
              />
              <Route
                path="/faqs"
                element={<FAQ selectedClient={selectedClient} />}
              />
              <Route
                path="/press-releases/new"
                element={<NewPressRelease selectedClient={selectedClient} />}
              />
              <Route path="/reports/:id" element={<ReportList />} />
              <Route
                path="/reports/:id/weekly-report"
                element={<WeeklyReport />}
              />
              <Route
                path="/reports/:id/monthly-report"
                element={<MonthlyReport />}
              />
              <Route
                path="/reports/:id/yearly-report"
                element={<YearlyReport />}
              />
              <Route path="/:id/create-task" element={<CreateTask />} />
              <Route path="/client-campaign" element={<></>} />
              <Route path="/storage-management" element={<></>} />
            </Routes>
          </div>
        </div>
        <Routes>
          <Route
            path="/onboard-client"
            element={
              selectedClient !== null ? (
                <OnboardingClient selectedClient={selectedClient} />
              ) : (
                <></>
              )
            }
          />
        </Routes>
      </div>
    </div>
  )
}

export default ClientManagement
