import { Box, Button, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { styled } from "@mui/material/styles"
import plus from "../../assets/plus-blue.png"
import { ReactComponent as ClientIcon } from "../../assets/ClientManagement.svg"
import { ReactComponent as CampaignIcon } from "../../assets/CampaignManagement.svg"
import ReactQuill from 'react-quill'
import './ClientCampaignManagement.css'
import Select from "react-select"
import countryList from "react-select-country-list"

const StyledButton = styled(Button)({
    background: "#0F579626",
    color: "#0F5796",
    borderRadius: "14px",
    padding: "12px 26px"
  })

const ClientManagementEdit = ({selectedClient}) => {

  // console.log("Selected Client - ", selectedClient)

    const [options, setOptions] = useState(countryList().getData())
    const [selectedCountry, setSelectedCountry] = useState({})

    const [name, setName] = useState(selectedClient?.name)
    const [email, setEmail] = useState(selectedClient?.email)
    const [phone, setPhone] = useState(selectedClient?.phone)
    const [street, setStreet] = useState(selectedClient?.street)
    const [city, setCity] = useState(selectedClient?.city)
    const [zip, setZip] = useState(selectedClient?.zip_code)
    const [state, setState] = useState(selectedClient?.state)
    const [rundown, setRundown] = useState(selectedClient?.rundown_txt)
    const [stakeHolderName, setStakeHolderName] = useState(selectedClient?.stack_holder_name)
    const [campaignCount, setCampaignCount] = useState(selectedClient?.campaign_count)

    const handleSubmit = () =>{

    }

    useEffect(() => {
      setOptions(countryList().getData())
  
      if (selectedClient?.country) {
        const selectedOption = options.find(
          option => option.label === selectedClient?.country
        )
        setSelectedCountry(selectedOption)
      }
    }, [selectedClient, options])

  return (
    <div>
        <Box sx={{ overFlow: "auto", marginBottom:"4rem" }}>
        <Box
            sx={{
            display: "flex",
            gap: "1rem",
            alignItems: "center",
            justifyContent: "space-between"
            }}
        >
            <div>
            <Typography variant="h5" noWrap>
                Client & Campaign
            </Typography>
            <div style={{marginTop:15,color:"#A3A3A3"}}>Submenu</div>
            </div>
            <div>
            <Box sx={{ display: "flex", gap: "1rem", alignItems: "center" }}>
                <StyledButton
                startIcon={<ClientIcon />}
                sx={{ whiteSpace: "nowrap", textTransform: "none" }}
                // onClick={handleOnClick("/client/agendas-meeting")}
                >
                Client Management
                </StyledButton>
                <StyledButton
                startIcon={<CampaignIcon />}
                sx={{ whiteSpace: "nowrap", textTransform: "none" }}

                >
                Campaign Management
                </StyledButton>
            </Box>
            </div>
        </Box>
        </Box>

        <div style={{display:"flex", width:"100%"}}>

          <div style={{width:"100%",display:"flex", flexDirection:"column", justifyContent:"space-between"}}>

                  <div style={{marginBottom:"1rem"}}>Client name</div>
                  <input placeholder='Client name' value={name} onChange={(e)=>setName(e.target.value)} style={{ width: "calc(100% - 40px)", height: "10px", padding: "15px 20px" }} />
                  
                  
                  <div style={{marginBottom:"1rem", marginTop:"1rem"}}>Client website</div>
                  <input placeholder='Client website' style={{ width: "calc(100% - 40px)", height: "10px", padding: "15px 20px" }} />
                  

                  <div style={{marginBottom:"1rem", marginTop:"1rem"}}>Client email</div>
                  <input placeholder='Client email' value={email} onChange={(e)=>setEmail(e.target.value)} style={{ width: "calc(100% - 40px)", height: "10px", padding: "15px 20px" }} />
                  

                  <div style={{marginBottom:"1rem", marginTop:"1rem"}}>Client phone</div>
                  <input placeholder='Client phone' value={phone} onChange={(e)=>setPhone(e.target.value)} style={{ width: "calc(100% - 40px)", height: "10px", padding: "15px 20px" }} />
                  
                  

                  <div style={{marginBottom:"1rem", marginTop:"1rem"}}>Client address</div>
                  <div className="form-group" style={{marginBottom:"1rem"}}>
                    <input
                      type="text"
                      name="street"
                      placeholder="Street"
                      value={street} onChange={(e)=>setStreet(e.target.value)}
                      required
                      style={{marginRight:"10px"}}

                    />
                    <input
                    
                      type="text"
                      name="city"
                      placeholder="City"
                      value={city} onChange={(e)=>setCity(e.target.value)}
                      required
                
                    />
                  </div>

                  <div className="form-group" style={{marginBottom:"1rem"}}>
                    <input
                      type="text"
                      name="zip_code"
                      placeholder="Zip"
                      value={zip} onChange={(e)=>setZip(e.target.value)}
                      required
                      style={{marginRight:"10px"}}
        
                    />
                    <input
                      type="text"
                      name="state"
                      placeholder="State"
                      value={state} onChange={(e)=>setState(e.target.value)}
                      required
            
                    />
                  </div>

                  
                  <div style={{width:"100%",marginBottom:"1rem"}}>Country</div>
                  <div className="form-group" style={{marginBottom:"1rem"}}>
                    <div className="select-container"> {/* Add a container for the Select component */}
                      <Select
                        isSearchable={true}
                        options={options}
                        value={selectedCountry}
                        onChange={selectedOption => {
                          setSelectedCountry(selectedOption)
                        }}
                      />
                    </div>
                  </div>




                  <div style={{width:"100%",marginBottom:"1rem"}}>Rundown</div>
                  <div className="quill-editor-2 quill-editor-notes-2" style={{ width:"100%", maxHeight: "20vh" }}>
                        <ReactQuill 
                          value={rundown} 
                          onChange={(val) => {
                          const cleanedValue = val.replace(/<\/?p>/g, '');
                          setRundown(cleanedValue)
                          }} 
                          
                          />
                  </div>


                  <div style={{ width: "100%", marginBottom: "1rem", display: "flex" }}>
                    <div style={{ flex: 1, marginRight: "0.5rem", minWidth: 0 }}>
                      <div style={{ marginBottom: "1rem", marginTop: "1rem" }}>Stakeholder full name</div>
                      <input
                        type="text"
                        name="stakeholder-name"
                        value={stakeHolderName} onChange={(e)=>setStakeHolderName(e.target.value)}
                        placeholder="Stakeholder full name"
                        required
                        style={{ width: "100%", padding: "15px 20px", boxSizing: "border-box" }}
                      />
                    </div>

                    <div style={{ flex: 1, marginLeft: "0.5rem", minWidth: 0 }}>
                      <div style={{ marginBottom: "1rem", marginTop: "1rem" }}>Number of Campaign</div>
                      <input
                        type="text"
                        name="campaign-count"
                        value={campaignCount} onChange={(e)=>setCampaignCount(e.target.value)}
                        placeholder="Total"
                        required
                        style={{ width: "100%", padding: "15px 20px", boxSizing: "border-box" }}
                      />
                    </div>
                  </div>

                  <div className='create-agenda-button' style={{width:"100%",display: 'flex', justifyContent: 'flex-end'}}>
                      <button style={{fontWeight:"600", marginRight:"0", marginTop:"2"}} className="template-button" onClick={() => handleSubmit()}>
                          Save
                      </button>
                  </div>
              
          </div>

          <div style={{width:"100%", maxWidth:"20rem",fontSize:"20px", marginLeft:"2vw"}}>

              <div style={{ backgroundColor: "#fff", borderRadius: 10 }}>
                  <div style={{ padding:"1.5rem", display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                      <h3 style={{ color: "#0F5796", fontSize: 24, marginTop: 5, marginBottom: 5 }}>Meetings</h3>
                      <img
                          src={plus}
                          alt="Notification Icon"
                          style={{ width: 18, height: 18, marginRight: 10, cursor:"pointer" }}
                      />
                  </div>
                  <p style={{ margin:0, fontSize: 14, color: "#A3A3A3", padding:"0rem 1.5rem", paddingBottom:"10px" }}>Create a new task</p>

              </div>    

              {/* <Pagination
                  currentPage={currentPage}
                  totalCount={clientsCount}
                  totalPages={Math.ceil(clientsCount / 2 || 0)}
                  pageSize={2}
                  hideTotalCount={true}
                  onPageChange={page => setCurrentPage(page)}
              /> */}

          </div>
          <div style={{width:"5vw"}}></div>

        </div>

    </div>
  )
}

export default ClientManagementEdit