import { Button } from "@mui/material"
import React from "react"

const LinkButton = ({ label, onClick, color = "#0F5796" }) => {
  return (
    <Button
      onClick={onClick}
      variant="text"
      sx={{
        color,
        fontWeight: "500",
        fontSize: "0.875rem",
        borderRadius: "10px",
        padding: "0.75rem 1rem",
        height: "3rem",
        justifyContent: "flex-start",
        gap: "0.75rem",
        textTransform: "none"
      }}
    >
      {label}
    </Button>
  )
}

export default LinkButton
