import React, { useState } from "react"
import staff from "../assets/staff-icon.png"
import client from "../assets/client-icon.png"
import campaign from "../assets/campaign-icon.png"
import business from "../assets/new-icon.png"
import arrow from '../assets/chevron-left.png';
import { Link, matchPath, useLocation } from "react-router-dom"
import Header from "./Header"

const SideMenu = (props) => {
  const sideMenuStyle = {
    width: "250px",
    backgroundColor: "#0F5796",
    color: "#fff",
    padding: "20px",
    height: "92vh",
    borderRadius: '10px',
    margin: "5px",
    position: "fixed"
  }

  const logoStyle = {
    display: "block",
    margin: "0 auto 20px",
    color: "#fff",
    width: "100px" // Set the width of your logo
  }

  const titleStyle = {
    fontSize: "0.8rem", // Set the font size for the title
    fontWeight: "bold",
    marginTop: 50,
    marginBottom: 5,
    color: "#ccc"
  }

  const hrStyle = {
    borderTop: "1px solid #555",
    margin: "20px 0"
  }

  const menuItemStyle = {
    display: "flex",
    alignItems: "center",
    marginBottom: "15px",
    marginTop: 40,
    cursor: "pointer"
  }

  const iconStyle = {
    marginRight: "10px",
    width: "20px",
    height: "20px"
  }

  const openCloseArrorw = {
    display: 'inline-block',
    padding: '5px 10px',
    backgroundColor: 'white',
    position: 'absolute',
    right: '0',
    bottom: '0',
    cursor: 'pointer',
    borderRadius: '50%'
  }

  const [showFullview, setShowFullView] = useState(true);

  const location = useLocation()

  const routesTomatch = ["/proposals","/contracts","/create-proposal",
    "/create-contract", "/add-business", "/edit-business", "/proposal-details", "/contract-details", "/client","/reports"];

  const hideSideNav = !(routesTomatch.find(data =>{
    return matchPath(
      {
        path: data,
        end: false
      },
      location?.pathname
    )
  }) || location?.pathname === '/');

  if(hideSideNav){
    return props.children;
  }

  return (
    <div style={{ width: '100%' }}>
      <div style={showFullview ? sideMenuStyle : { ...sideMenuStyle, width: 'auto' }}>
        <div style={{ position: "relative" ,textAlign:'center'}}>
          {showFullview && <h1 style={logoStyle}>Logo</h1>}
          <span style={showFullview ? openCloseArrorw : {...openCloseArrorw,position:'static', marginTop:'20px'}} onClick={() => setShowFullView(view => !view)}>
            <img src={arrow} width={8} height={12} style={showFullview ? {} : { transform: 'scaleX(-1)' }}
            />
          </span>
        </div>
        {showFullview && <div style={titleStyle}>AGENCY MANAGMENT</div>}
        {showFullview && <hr style={hrStyle} />}
        <div
          style={{
            ...menuItemStyle,
            justifyContent: !showFullview ? 'center' : 'flex-start',
            backgroundColor: location.pathname === "/dashboard" ? "gray" : null
          }}
        >
          <Link
            style={{
              color: location.pathname === "/staff" ? "#fff" : "#fff",
              textDecorationLine: "none",
              cursor: "pointer"
            }}
            to="/"
            className="iconText"
          >
            <img src={staff} alt="Home Icon" style={iconStyle} />
            {showFullview && 'Staff management'}
          </Link>
        </div>
        <div
          style={{
            ...menuItemStyle,
            justifyContent: !showFullview ? 'center' : 'flex-start',
            backgroundColor: location.pathname === "/client" ? "#6f9ac0" : null,
            padding: location.pathname === "/client" ? 10 : null,
            borderRadius: location.pathname === "/client" ? 5 : null
          }}
        >
          <Link
            style={{
              color: location.pathname === "/client" ? "#fff" : "#fff",
              textDecorationLine: "none",
              cursor: "pointer"
            }}
            to="/client"
            className="iconText"
          >
            <img src={client} alt="Profile Icon" style={iconStyle} />
            {showFullview && 'Client management'}
          </Link>
        </div>
        <div
          style={{
            ...menuItemStyle,
            justifyContent: !showFullview ? 'center' : 'flex-start',
            backgroundColor: location.pathname === "/dashboard" ? "gray" : null
          }}
        >
          <Link
            style={{
              color: location.pathname === "/campaign" ? "#fff" : "#fff",
              textDecorationLine: "none",
              cursor: "pointer"
            }}
            to="/"
            className="iconText"
          >
            <img src={campaign} alt="Settings Icon" style={iconStyle} />
            {showFullview && 'Campaign management'}
          </Link>
        </div>
        <div
          style={{
            ...menuItemStyle,
            justifyContent: !showFullview ? 'center' : 'flex-start',
            backgroundColor: location.pathname === "/" ? "#6f9ac0" : null,
            padding: location.pathname === "/" ? 10 : null,
            borderRadius: location.pathname === "/" ? 5 : null
          }}
        >
          <Link
            style={{
              color: location.pathname === "/" ? "#fff" : "#fff",
              textDecorationLine: "none",
              cursor: "pointer"
            }}
            to="/"
            className="iconText"
          >
            <img src={business} alt="Messages Icon" style={iconStyle} />
            {showFullview && 'New business management'}
          </Link>
        </div>

        {showFullview && <hr style={hrStyle} />}
      </div>
      <div style={{
        width: showFullview ? "calc(100% - 250px)" : "calc(100% - 30px)",
        marginLeft: showFullview ? "250px" : "30px"
      }}>
        {props.children}
      </div>
    </div>
  )
}

export default SideMenu
