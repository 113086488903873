import React from "react"
import { useNavigate, NavLink, useLocation, matchPath } from "react-router-dom"
import { styled } from "@mui/material/styles"

import { ReactComponent as OnboardIcon } from "../../assets/icon-onboard.svg"
import { ReactComponent as ManageIcon } from "../../assets/icon-manage.svg"
import { ReactComponent as ExternalIcon } from "../../assets/icon-external.svg"
import { ReactComponent as InternalIcon } from "../../assets/icon-internal.svg"
import { ReactComponent as CampaignIcon } from "../../assets/icon-campaign.svg"
import { ReactComponent as StorageIcon } from "../../assets/icon-storage.svg"

const StyledTabItem = styled(NavLink)({
  display: "flex",
  alignItems: "center",
  gap: "10px",
  padding: "10px 20px",
  border: "none",
  borderRadius: "10px",
  backgroundColor: "transparent",
  color: "#0F5796",
  cursor: "pointer",
  textAlign: "left",
  textDecoration: "none",
  fontSize: "1rem",
})

const StyledTab = styled("div")({
  cursor: "pointer",
  display: "flex",
  flexDirection: "column",
  gap: "20px"
})

const Tabs = () => {
  const navigate = useNavigate()
  const location = useLocation()

  const activeStyle = ({ isActive }) =>
    isActive ? { backgroundColor: "#0F579626", color: "#0F5796" } : {}

  const subTabActiveStyle = ({ isActive }) =>
    isActive ? { backgroundColor: "#FDE5E5", color: "#0F5796" } : {}

  return (
    <div className="left-side">
      <StyledTab>
        <StyledTabItem
          to={{ pathname: "/client/onboard-client" }}
          style={activeStyle}
        >
          <OnboardIcon />
          Onboarding the client
        </StyledTabItem>
        <StyledTabItem
          to={{ pathname: "/client/collateral-management" }}
          style={activeStyle}
        >
          <ManageIcon />
          Collateral management
        </StyledTabItem>
        {matchPath(
          { path: "/client/collateral-management/*" },
          location?.pathname
        ) && (
          <>
            <StyledTabItem
              to={{
                pathname: "/client/collateral-management/internal-document"
              }}
              style={subTabActiveStyle}
            >
              <InternalIcon />
              Internal Document
            </StyledTabItem>
            <StyledTabItem
              to={{
                pathname: "/client/collateral-management/external-document"
              }}
              style={subTabActiveStyle}
            >
              <ExternalIcon />
              External Documents
            </StyledTabItem>
          </>
        )}
        <StyledTabItem
          to={{ pathname: "/client/client-management-edit" }}
          style={activeStyle}
        >
          <CampaignIcon />
          Client & Campaign Management
        </StyledTabItem>
        <StyledTabItem
          to={{ pathname: "/client/storage-management" }}
          style={activeStyle}
        >
          <StorageIcon />
          Content Storage & Management
        </StyledTabItem>
      </StyledTab>
    </div>
  )
}

export default Tabs
