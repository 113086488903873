import React, { useEffect, useState } from "react"
import MenuItem from "@mui/material/MenuItem"
import ClickAwayListener from "@mui/material/ClickAwayListener"
import Grow from "@mui/material/Grow"
import Paper from "@mui/material/Paper"
import Popper from "@mui/material/Popper"
import MenuList from "@mui/material/MenuList"
import FormInput from "../FormInput"
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"
import SearchIcon from "../../assets/search-icon.png"
import { Box, Button, Typography } from "@mui/material"
import { makeStyles } from "@mui/styles"

const useStyles = makeStyles({
  activeMenu: {
    backgroundColor: "#F0F0F0 !important",
    color: "#0F5796 !important"
  }
})

const DropDownMenu = React.forwardRef(({
  label,
  itemList,
  onClick,
  testId,
  activeItem,
  hideThis,
  targetItem,
},ref) => {
  const [anchorEl, setAnchorEl] = useState(false)
  const open = Boolean(anchorEl)
  const [options, setOptions] = useState(itemList)
  const [search, setSearch] = useState("")
  const cls = useStyles()

  const onOpen = e => {
    setAnchorEl(prev => (prev ? null : e?.currentTarget))
  }

  const handleClose = event => {
    if (anchorEl && anchorEl?.contains(event?.target)) {
      return
    }

    setAnchorEl(null)
  }

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault()
      handleClose()
    } else if (event.key === "Escape") {
      handleClose()
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open)
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      // anchorEl?.focus()
    }

    prevOpen.current = open
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])

  useEffect(() => {
    setOptions(itemList)
  }, [itemList])

  useEffect(() => {
    targetItem && onOpen(targetItem)
  }, [targetItem])

  const handleItemClick = item => e => {
    onClick(e, item)
    handleClose(e)
  }

  const handleSearch = e => {
    setSearch(e?.target?.value)
    const filteredOptions = e?.target?.value?.toLowerCase()
      ? itemList?.filter(item =>
          item?.label?.toLowerCase().includes(e?.target?.value?.toLowerCase())
        )
      : itemList
    setOptions(filteredOptions)
  }

  return (
    <Box>
      {!hideThis && (
        <div>
          <Typography
            sx={{ color: "#000", marginLeft: "1rem", fontSize: "1.125rem" }}
            paragraph
          >
            {label}
          </Typography>
          <Button
            variant="contained"
            disableElevation
            disableRipple
            onClick={onOpen}
            endIcon={<KeyboardArrowDownIcon />}
            sx={{
              border: "0.5px solid #ABAFB3",
              background: "#FFF",
              borderRadius: "0,5rem",
              color: "#464255",
              fontSize: "1.5rem",
              fontWeight: "500",
              textTransform: "none",
              width: "100%",
              height: "3rem",
              textAlign: "left",
              justifyContent: "space-between",

              "&:hover": { background: "#FFF" }
            }}
          >
            {activeItem?.label || "Select"}
          </Button>
        </div>
      )}
      <Popper
        open={open}
        anchorEl={anchorEl}
        role={undefined}
        placement="bottom-end"
        sx={{ zIndex: 10, marginTop: "0.5rem !important" }}
        transition
      >
        {({ TransitionProps }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: "bottom-end"
            }}
          >
            <Paper sx={{ background: "transparent", boxShadow: "none" }}>
              <ClickAwayListener onClickAway={handleClose}>
                <div>
                  <FormInput
                    placeholder="Search"
                    onKeyDown={handleListKeyDown}
                    ref={ref}
                    onChange={handleSearch}
                    style={{
                      boxShadow: "0px 3px 4px 0px rgba(0, 0, 0, 0.4)",
                      padding: 2
                    }}
                    startAdornment={
                      <img
                        style={{
                          marginLeft: 12
                        }}
                        src={SearchIcon}
                        alt="search"
                        height={20}
                        width={22}
                      />
                    }
                    value={search}
                  />
                  <Paper sx={{padding:'10px 0px'}}>
                    <MenuList
                      onKeyDown={handleListKeyDown}
                      data-testid={testId}
                      sx={{ marginTop: "0.25rem",
                      marginRight:'6px',
                      overflowY:'auto', maxHeight:'200px' ,
                      '::-webkit-scrollbar': {
                        width: '3px',
                      },
                      '::-webkit-scrollbar-track': {
                        backgroundColor: '#c4c4c4',
                      },
                      '::-webkit-scrollbar-thumb': {
                        backgroundColor: '#0f5796',
                        borderRadius: '8px',
                      },
                    }}
                    >
                      {!options?.length && (
                        <MenuItem
                          sx={{ color: "#666666", fontSize: "1.125rem" }}
                          selected={false}
                        >
                          No results found
                        </MenuItem>
                      )}
                      {options?.map((item, i) => {
                        const isActive = item?.value === activeItem?.value
                        return (
                          <MenuItem
                            key={`${i}-menu-item`}
                            selected={isActive}
                            classes={{
                              selected: cls.activeMenu
                            }}
                            sx={{ color: "#666666", fontSize: "1.125rem" }}
                            onClick={handleItemClick(item)}
                          >
                            {item?.label}
                          </MenuItem>
                        )
                      })}
                    </MenuList>
                  </Paper>
                </div>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Box>
  )
});

export default DropDownMenu
